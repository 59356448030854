import { ChatImage } from "../../../constants/chatImages";

export const bente = (text: string) => ({
  image: ChatImage.BENTE,
  text
});

export const marlene = (text: string) => ({
  image: ChatImage.MARLENE,
  text
});

export const sinan = (text: string) => ({
  image: ChatImage.SINAN,
  text
});

export const benton = (text: string) => ({
  image: ChatImage.BENTOR,
  text
});

export const norbert = (text: string) => ({
  image: ChatImage.BENTE_COOKIE,
  text
});
