import React, { useRef } from "react";
import styled from "styled-components";
import { TextPosition } from "../Types";
import { getTextPosition, standard as standardTextPosition } from "../utilz/textPosition";
import { TextColor } from "./utilz/getTextColor";
import { getPosition } from "./utilz/getTextPosition";

interface TextProps {
  textColor: TextColor;
}

interface Props extends TextProps {
  textPosition?: TextPosition;
  switchDirection?: boolean;
}

const StyledTextContainer = styled.div<{ position?: TextPosition }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 7px;
  top: ${ props => getPosition( 'top', props.position ) };
  bottom: ${ props => getPosition( 'bottom', props.position ) };
  left: ${ props => getPosition( 'left', props.position ) };
  right: ${ props => getPosition( 'right', props.position ) };
  width: ${ props => getPosition( 'width', props.position ) };
  height: ${ props => getPosition( 'height', props.position ) };
  pointer-events: none;
`;

const StyledText = styled.div<{ textColor: TextColor }>`
  pointer-events: none;
  color: ${ props => props.textColor };
`;

const TextContainer: React.FC<Props> = (
  {
    textColor,
    textPosition,
    children,
    switchDirection,
  }
) => {
  const position = useRef( {
    ...standardTextPosition,
    ...getTextPosition( textPosition, switchDirection )
  } ).current;

  return (
    <StyledTextContainer position={ position }>
      <StyledText textColor={ textColor }>
        { children }
      </StyledText>
    </StyledTextContainer>
  );
};

export default TextContainer;
