import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";

const { next, choices, end } = create;

export const book: (FrameProps | string)[] = [
  'Ja genau! Da gibts ein ganzes Buch im Eichbornverlag „Herzschlag ins Gesicht“ heißt das.',

  choices(Hooks.BOOK, [
    'Das muss ich mir mal angucken.',
    'Das habe ich schon! <3'
  ]),

  'Oh toll! Danke! Na, dann mach’s erst mal gut. Hat mich gefreut <3',
  end(),

  ...next(Hooks.BOOK, [
    'Das würde mich freuen!',
    'Na, dann mach’s erst mal gut.',
    'Hat mich gefreut <3',
    end()
  ])
];
