import React from "react";
import { Route, Switch } from 'react-router-dom';
import Portfolio from './pages/portfolio';
import Contact from "./pages/contact";
import Home from "./pages/home";
import About from "./pages/about";
import Imprint from "./pages/imprint";
import ItCellar from "./pages/it_cellar";
import DemoShapes from './pages/DEV_demo_shapes';
import DialogScreen from "./pages/DEV_scene/index_dialog";
import ChatScreen from "./pages/DEV_scene/index_chat";
import User from "./pages/DEV_debug/user";
import Test from "./pages/DEV_test";
import Book from "./pages/book";

const AppRouter = () => (
    <Switch>
      <Route exact path="/" component={ Home } />
      <Route path="/home" component={ Home } />
      <Route path="/portfolio" component={ Portfolio } />
      <Route path="/about" component={ About } />
      <Route path="/contact" component={ Contact } />
      <Route path="/imprint" component={ Imprint } />
      <Route path="/it_cellar" component={ ItCellar } />
      <Route path="/herzschlag_ins_gesicht" component={ Book } />

      {/* These are just testing pages */ }
      <Route path="/user" component={ User } />
      <Route path="/dialog" component={ DialogScreen } />
      <Route path="/chat" component={ ChatScreen } />
      <Route path="/demo_shapes" component={ DemoShapes } />
      <Route path="/test" component={ Test } />
    </Switch>
);

export default AppRouter;
