import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";

const { next, choices, end } = create;

export const sounded_devious: (FrameProps | string)[] = [
  'Das klang jetzt aber etwas unaufrichtig.',

  choices(Hooks.SOUNDED_DEVIOUS, [
    'Ne war total ernst!',
    'Du hast mich ertappt.'
  ]),

  'Arsch!',
  end('Bente hat beleidigt den Chat verlassen'),

  ...next(Hooks.SOUNDED_DEVIOUS, [
    'Achso, nagut. Also dann, mach’s gut!',
    end()
  ])
];
