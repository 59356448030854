import TimelineMax from 'assets/utilz/timeline';

import { displaceBackdrop, displaceBoxX, displaceBoxY, displaceUser } from "../Styles";
import { AnswerAnimationProps } from "./animateAnswer";

type Ref = HTMLElement;

export const answerIn = (
  {
    answerRef,
    flashRef,
    userRef,
    backgroundRef,
    backdropRef,
    containerRef,
  }: AnswerAnimationProps,
  onComplete: () => void
) => {
  const tl = new TimelineMax( { paused: true, onComplete } );
  return tl
    .set(containerRef, {display: 'flex'})
    .set(userRef, {x: 0})
    .set(answerRef.childNodes, {x: 0})
    .add( flashIn( flashRef ), 0 )
    .add( backgroundIn( backgroundRef ), '+.2' )
    .add( backdropIn( backdropRef ), '+.15' )
    .add( characterIn( userRef ), '+.3' )
    .add( choicesIn( answerRef ), '+.25' )
};
const characterIn = (characterRef: Ref,) => {
  return new TimelineMax()
    .fromTo( characterRef, {
      y: displaceUser,
    }, {
      duration: .45,
      ease: 'power4.out',
      y: 0,
    } )
};
const backdropIn = (characterRef: Ref,) => {
  return new TimelineMax()
    .fromTo( characterRef, {
      x: displaceBackdrop,
    }, {
      duration: .6,
      ease: 'power4.out',
      x: 0,
    } )
};
const box = {
  in: {
    x: displaceBoxX,
    y: displaceBoxY
  },
  out: {
    duration: .3,
    ease: 'power4.out',
    x: 0,
    y: 0
  }
};
const flashIn = (flashRef: Ref) => {
  return new TimelineMax()
    .fromTo( flashRef, box.in, box.out )
};
const backgroundIn = (backgroundRef: Ref) => {

  return new TimelineMax()
    .fromTo( backgroundRef, box.in, {
      ...box.out,
      duration: .25,
    } )
};
const choicesIn = (answerRef: Ref) => {

  return new TimelineMax()
    .set( answerRef, { display: 'flex' } )
    .fromTo( answerRef.childNodes, {
      opacity: 0,
      scale: 1.8,
      immediateRender: true,
    }, {
      opacity: 1,
      scale: 1,
      duration: .3,
      ease: 'back.out(2)',
      stagger: .15,
    } )
};