import React from "react";
import TestUi from "../components/TestUi";
import { useTestActions } from "./useTestActions";
import { useScene } from "../../scene";
import { Pages } from "../../constants/Pages";
import { useComponentMount } from "../../assets/useComponentMount";
import { test_dialog } from "./test_dialog";

const SceneScreen = () => {
  const actions = useTestActions();
  const { start } = useScene();

  const localScenes = [
    test_dialog
  ];

  const config = {
    origin: '/dialog' as Pages,
    disableGlobalScenes: true,
    localScenes
  };
  useComponentMount(() => start(config));

  const buttons = [
    {
      text: 'Show',
      onClick: actions.show
    },
    {
      text: 'Hide',
      onClick: actions.hide
    },
    {
      text: 'Add Scene',
      onClick: actions.addScene
    },
  ];

  return (
    <div>
      <TestUi buttons={ buttons } />
    </div>
  );
};

export default SceneScreen;
