import { State } from "./types";
import { getNextScene } from "./helper/getNextScene";
import { getDelay } from "./helper/getDelay";
import { State as SessionState } from "../../../session/store/types";

export const finish = (state: State, session: SessionState) => {
  const {
    played,
    current,
    scenes
  } = state;
  const newPlayed = played.concat( current );
  const next = getNext( state, newPlayed, session );
  const delay = getDelay( scenes, next );

  return {
    current: 'end',
    played: newPlayed,
    frameIndex: 0,
    delay
  }
};

const getNext = (
  state: State,
  played: string[],
  session: SessionState
) => {
  const newState = {
    ...state,
    played
  };

  return getNextScene( newState, session )
};
