import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";
import { exhibition } from "./exhibition";
import { family } from "../family/family";

const { next, choices } = create;

export const met_tic_tac_toe: (FrameProps | string)[] = [
  'Als ich das eine Mal die Sängerin von Tic Tac Toe im Rewe an der Kasse gesehen habe?',

  choices(Hooks.MET_TIC_TAC_TOE, [
    'Ne, mehr so über dich.',
    'Das ist ja mega!'
  ]),

  ...exhibition,

  ...next(Hooks.MET_TIC_TAC_TOE, family)
];
