import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";
import { distracted_me } from "./distracted_me";

const { choices } = create;

export const run_fast: (FrameProps | string)[] = [
  'Zeig doch mal. Ich zähle bis 3. 1... 2...',

  choices(Hooks.SILENCE, [
    '*rennt schnell weg*',
    'FÜNFZEHN, ACHTUNDNEUNZIG, HUNDERTVIERZEHN!!'
  ]),

  ...distracted_me
];
