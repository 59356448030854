import * as arrow from './arrow';
import * as chatAnswer from './chatAnswer';
import * as chatBubble from './chatBubble';
import * as speechBubble from './speechBubble';
import * as apostrophe from './apostrophe';

export default {
  arrow,
  chatAnswer,
  chatBubble,
  speechBubble,
  apostrophe
};
