export const coordinates = [
  [ 170, 95 ],
  [ 974, 0 ],
  [ 1040, 350 ],
  [ 230, 281 ],
  [ 215, 301 ],
  [ 144, 248 ],
  [ 127, 269 ],
  [ 0, 172 ],
  [ 96, 191 ],
  [ 92, 146 ],
  [ 176, 184 ]
];

export const displacementMap = [
  [ -1, -1 ],
  [ .5, -.5 ],
  [ .5, .5 ],
  [ 0, 1 ],
  [ .3, 1 ],
  [ 0, 1 ],
  [ 0, 1 ],
  [ -1, -.5 ],
  [ -1, -1 ],
  [ -1, -1 ],
  [ -1, -1 ],
];

export const viewBox = "0 0 1040 350";
