import React from 'react';
import styled from 'styled-components';
import flash from '../../assets/img/events/marlene-flash.png';
import Button from "../../components/Button";


const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const FlashImage = styled.img`
  position: absolute;
  bottom: 0;
  width: 100%;
  object-fit: cover;
`;

const Test = () => {


  return (
    <Container>
      <FlashImage src={flash} />
      <Button value='Test' onClick={() => {}} />
    </Container>
  );
};

export default Test
