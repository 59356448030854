import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";
import { live_graphic_novel } from "./live_graphic_novel/live_graphic_novel";
import { did_you_fight } from "./did_you_fight";

const { next, choices } = create;

export const pets: (FrameProps | string)[] = [
  'Früher hatte sie Ratten und einen Hund, der hieß Smilla und war der beste Hund aller Zeiten.',

  choices(Hooks.SINAN_PETS, [
    'Und was macht sie sonst so?',
    'Kann ich jetzt wieder mit Bente reden?'
  ]),

  'Nagut, aber benimm dich!',
  'Sinan hat den Chat verlassen.',
  ...did_you_fight,


  ...next(Hooks.SINAN_PETS, live_graphic_novel)
];
