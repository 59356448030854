import { getFrameIndex } from "../store/actions/helper/getFrameIndex";
import { useCallback } from "react";
import { useTarget } from "./useTarget";
import { useNavigation } from "../../assets/useNavigation";
import { useLocation } from "react-router";
import { useSceneContext } from "../../context";
import { SequenceState } from "../types";

export const useOnProgress = (): () => void => {
  const {
    // ready,
    status,
    setFrameIndex,
    incrementFrame,
    finishScene,
    currentScene,
  } = useSceneContext();
  const ready = status === SequenceState.AWAIT_START;
  const getTarget = useTarget();
  const navigate = useNavigation();
  const location = useLocation();

  const incrementToTarget = useCallback( (target: string) => {
    const index = getFrameIndex( currentScene, target );
    if ( index !== -1 ) {
      setFrameIndex( index );
    } else {
      console.error( 'This id does not exist ', target );
      incrementFrame();
    }
  }, [currentScene, setFrameIndex, incrementFrame] );

  const finish_after_navigation = useCallback((target: string) => {
    const is_current_page = target === location.pathname;

    return ready && is_current_page
  }, [ready, location.pathname]);

  const navigateToPage = useCallback((target: string) =>
    finish_after_navigation(target)
      ? finishScene()
      : navigate(target)
  , [finishScene, finish_after_navigation, navigate]);

  const handleTarget = useCallback((target: string) => {
    const is_page_navigation = target.startsWith('/');

    is_page_navigation
      ? navigateToPage(target)
      : incrementToTarget(target)

  }, [incrementToTarget, navigateToPage]);

  return useCallback( (target?: string) => {
      const newTarget = getTarget(target);

      if ( newTarget.break ) {
        finishScene()
      } else {
        if ( newTarget.isValid ) {
          handleTarget( newTarget.id );
        } else {
          incrementFrame();
        }
      }
    }
    , [finishScene, getTarget, handleTarget, incrementFrame] );

};

