import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";
import { etsy } from "./etsy";
import { overhead } from "./overhead";

const { next, choices } = create;

export const live_graphic_novel: (FrameProps | string)[] = [
  'Sie macht seit 2014 Live Graphic Novels am Theater. Unter anderem am Berliner Ensemble.',

  choices(Hooks.SINAN_LIVE_GRAPHIC_NOVEL, [
    'Was ist denn das?',
    'Was noch?'
  ]),

  ...etsy,

  ...next(Hooks.SINAN_LIVE_GRAPHIC_NOVEL, overhead)
];
