import { Hooks } from "scene/stories/it_cellar/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";

const { next, choices, end, backToBente } = create;

export const much_work: (FrameProps | string)[] = [
  'Total. Ist auch schon die zweite Version.',
  'Insgesamt habe ich ein Jahr daran gesessen, bis ich zufrieden war.',

  choices(Hooks.WAS_WORTH_IT, [
    'Hat sich gelohnt!',
    'Hast du nichts besseres zu tun?'
  ]),

  'Doch',
  end('Hau rein'),

  ...next(Hooks.WAS_WORTH_IT, [
    'Danke',
    'Ich muss dann mal wieder weiter machen',
    backToBente('Ich geb dich mal zurück an Bente')
  ])
];
