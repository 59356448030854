import { quoteList } from "../../../constants/quotes";

export const getQuotes = () => {
  let left: typeof quoteList = [];
  let right: typeof quoteList = [];

  quoteList.forEach( (quote, i) => {
    if ( i % 2 ) {
      right = right.concat( quote )
    } else {
      left = left.concat( quote )
    }
  } );
  return { left, right }
};
