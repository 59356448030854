import { session } from "./store/session";
import { State } from "./store/types";
import {initialState} from "./store/initialState";

export const createStateFromStorage = () => {
  const localState = session.readState();

  if ( !localState || !Object.keys(localState).length ) {
    return updateState(initialState);
  }


  return newState(localState)
};

const newState = (localState: State) => {
  const merged = mergeStates(localState);
  return updateState(merged)
};

const mergeStates = (localState: State) => ({
  ...initialState,
  ...localState,
});

const updateState = (state: State) => ({
  ...state,
  times_visited: state.times_visited + 1,
  is_read: true
});
