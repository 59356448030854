import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";
import { ChatImage } from "../../../../../../../../../../constants/chatImages";

const { next, choicesIrrelevant, sinan, choices } = create;

const running = (text: string) => ([
  sinan('2...'),
  sinan('EINS!! BLLUUUAAARRGGHHH ANGRIIIIIFF!!'),
  {
    image: ChatImage.SINAN,
    target: 'break',
    text
  },
]);

export const hiding: (FrameProps | string)[] = [
  sinan('Hmmm... da versteckt sich doch jemand. MARLENE?!'),
  'Marlene hat den inkognito- Modus verlassen.',
  'Oh maaaan du hast mich gefunden?!',
  sinan('Was lümmelst du hier rum?'),
  'Mir war so langweilig.',
  sinan('Ach echt, meine Maus? Komm, wir spielen was!'),

  choicesIrrelevant('', [
    'Hallo?',
    'Entschuldigung?'
  ]),

  'Oder lieber toben!',

  sinan('Okay, ich zähl bis 3, lauf lieber weg. 1...'),
  'WAAAAAaaaaahhh',

  choices(Hooks.I_WANT_TO_RUN_TOO, [
    'ICH WILL MIT MACHEN!',
    'Haaaalllooo??'
  ]),

  ...running('Sinan hat den Chat beendet.'),

  ...next(Hooks.I_WANT_TO_RUN_TOO, [
    ...running(
      'Sinan hat den Chat beendet. Du bist mitgerannt und tobst jetzt auch.'
    ),
  ])
];
