import { Coordinates, PolygonDom, PolygonState } from "../../Types";
import { toRender } from "./toRender";

const convertToPoints = (coordinates: Coordinates) => {
  return coordinates.join( ', ' )
};

export const convertShapes = (
  children: PolygonState[],
  state: PolygonState
): PolygonDom[] => {
  const list = toRender( children, state );

  return list.map( child => ({
    ...child,
    points: convertToPoints( child.coordinates )
  }) );
};
