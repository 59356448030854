import { useSession } from "../../../session";
import { useComponentMount } from "../../../assets/useComponentMount";


const AgreeDsgvo = () => {
  const {agreeToDsgvo} = useSession();
  useComponentMount(agreeToDsgvo);

  return null
}

export default AgreeDsgvo
