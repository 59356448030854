import TimelineMax from 'assets/utilz/timeline';

import {displaceCharacter, displaceSpeechBubble} from "../Styles";

type Ref = HTMLElement;

type RefProps = {
  characterRef: Ref,
  speechBubbleRef: Ref
};

export const animateDialog = (props: RefProps, delay: number) => ({
  in: (onComplete?: () => void) => dialogIn( props, onComplete ).play().delay(delay),
  out: (onComplete?: () => void) => dialogOut( props, onComplete ).play(),
});

const dialogIn = (
  props: RefProps,
  onComplete?: () => void
) => {
  const tl = new TimelineMax( { paused: true, onComplete } );
  return tl
    .add( characterIn( props.characterRef ), 0 )
    .add( speechBubbleIn( props.speechBubbleRef ), '.15' )
};

const dialogOut = (
  props: RefProps,
  onComplete?: () => void
) => {
  const tl = new TimelineMax( { paused: true, onComplete } );
  return tl
    .add( characterOut( props.characterRef ), 0 )
    .add( speechBubbleOut( props.speechBubbleRef ), '-.1' )
};

const characterIn = (characterRef: Ref,) => {
  return new TimelineMax()
    .fromTo( characterRef, {
      duration: .3,
      y: displaceCharacter,
    }, {
      ease: 'back.out(2.1)',
      y: 0,
    } )
};

const speechBubbleIn = (speechBubbleRef: Ref) => {
  return new TimelineMax()
    .fromTo( speechBubbleRef, {
      rotate: -8,
      y: displaceSpeechBubble
    }, {
      duration: .4,
      rotate: 0,
      ease: 'back.out(2.1)',
      y: 0
    } )
};

const characterOut = (characterRef: Ref,) => {
  return new TimelineMax()
    .fromTo( characterRef, {
      y: 0,
      rotate: 0
    }, {
      duration: .5,
      ease: 'power3.in',
      y: displaceCharacter
    } )
};

const speechBubbleOut = (speechBubbleRef: Ref) => {
  return new TimelineMax()
    .fromTo( speechBubbleRef, {
      y: 0,
      rotate: 0,
    }, {
      duration: .7,
      ease: 'back.in(1.7)',
      rotate: -8,
      y: displaceSpeechBubble
    } )
};
