import { ChoiceProps } from "../../types";

type Return = {
  type: 'ANSWER',
  choices: ChoiceProps[]
}

export const createChoices = (
  target: string,
  choices: [string, string],
  attach_to_second?: boolean
): Return => {
  const mappedChoices = choices.map((text, i) => {
    const attach = i === 0
      ? !attach_to_second
      : attach_to_second;

    const current_target = attach ? target : undefined;
    return {
      target: current_target,
      text
    }
  });

  return {
    "type": "ANSWER",
    "choices": mappedChoices
  }
};
