import React, { useRef } from "react";
import {
  ChatBg,
  ChatPage,
  ChatScreen,
  ChatWrapper,
  Container,
  Loading,
  MobileContainer
} from "./Styles";
import MobileDevice from "./MobileDevice";
import loadingImage from "../../assets/img/apps/app-fledermaeuse-animated.gif";
import { SetDomNodes } from "../../assets/useDomNodes";
import { useComponentMount } from "../../assets/useComponentMount";

interface Props {
  onProgress(): void;
  setReady: SetDomNodes;
}

const MobileDom: React.FC<Props> = (
  {
    onProgress,
    setReady,
    children
  }
) => {
  const mobileRef = useRef( null );
  const screenRef = useRef( null );
  const screenContainerRef = useRef( null );
  const screenBgRef = useRef( null );
  const loadingRef = useRef( null );
  const pathRef = useRef( null );

  const refs = {
    mobileRef,
    screenRef,
    screenBgRef,
    loadingRef,
    pathRef,
    screenContainerRef
  };

  useComponentMount(() => setReady( refs ));

  return (
    <ChatPage id="Chat Page">
      <Container ref={ mobileRef } onClick={ onProgress }>
        <MobileContainer id="Mobile Frame">
          <MobileDevice />
        </MobileContainer>
        <ChatWrapper id="Screen Container" ref={ screenContainerRef }>
          <ChatScreen id="Chat Screen" ref={ screenRef }>
            {children}
          </ChatScreen>
          <div ref={pathRef}/>
          <Loading id="Loading" src={ loadingImage } ref={ loadingRef } />
          <ChatBg id="Chat BG" ref={ screenBgRef } />
        </ChatWrapper>
      </Container>
    </ChatPage>
  );
};

export default MobileDom;
