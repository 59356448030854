import React, { useEffect, useRef } from 'react';
import { AnswerBackDrop, AnswerContainer, AnswerOptions } from "../Styles";
import { AnswerScreen } from "./AnswerScreen";
import { SetDomNodes } from "../../../assets/useDomNodes";
import Choices from "./Choices";

interface Props {
  setDomNodes: SetDomNodes;
}

const DomAnswer: React.FC<Props> = ({ setDomNodes }) => {

  const answerRef = useRef( null );
  const flashRef = useRef( null );
  const userRef = useRef( null );
  const backgroundRef = useRef( null );
  const backdropRef = useRef( null );
  const containerRef = useRef( null );

  useEffect( () => {
    const domNodes = {
      answerRef,
      flashRef,
      userRef,
      backgroundRef,
      backdropRef,
      containerRef
    };
    setDomNodes( domNodes );
  }, [ setDomNodes ] );

  return (
    <AnswerContainer ref={ containerRef } id="Answer Container">
      <AnswerBackDrop ref={ backdropRef } id="Answer Backdrop" />
      <AnswerScreen
        flashRef={ flashRef }
        userRef={ userRef }
        backgroundRef={ backgroundRef }
      />
      <AnswerOptions id="Answer Options" ref={ answerRef }>
        <Choices />
      </AnswerOptions>
    </AnswerContainer>
  );
};

export default DomAnswer;
