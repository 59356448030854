import React, { useEffect, useState } from 'react';
import CoverDom from "./CoverDom";
import { animation } from "./animation/animateIn";
import { useAnimateOnReady } from "../../../assets/useAnimateOnReady";

let has_played = false;

const Cover = () => {
  const [ ready, setReady ] = useState( false );
  const setRefs = useAnimateOnReady( animation, ready && !has_played );

  const onLoad = () => {
    setReady( true );
  };

  useEffect(() => {
    if (ready && !has_played) {
      has_played = true
    }
  });

  return (
    <CoverDom
      ready={ ready }
      onLoad={ onLoad }
      setRefs={ setRefs }
    />
  );
};

export default Cover
