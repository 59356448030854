import { createSceneForValidation } from "./createSceneForValidation";
import { validateScene } from "./validateScene";
import { PossibleSceneType, SessionState } from "./types";
import { SceneProps } from "../../scene/types";

export const validateSceneList = (
  scenes: PossibleSceneType[],
  session: SessionState
): SceneProps | undefined => {
  const readyScenes = createValidationList(scenes);
  for (let i = 0; i < readyScenes.length; i++) {
    const is_valid = validateScene(readyScenes[i], session);
    if (is_valid) {
      return readyScenes[i].scene
    }
  }
};

const createValidationList = (scenes: PossibleSceneType[]) =>
  scenes.map(scene => (
    createSceneForValidation(scene)
  ));
