import { Frame } from "../../types";
import { createNewTarget } from "./createNewTarget";
import { isBreak } from "./isBreak";

type CreatedTarget = {
  isValid: boolean,
  id: string,
  break: boolean
}

export const createTarget = (
  currentFrame: Frame,
  nextFrame: Frame,
  target?: string,
): CreatedTarget => {
  const newTarget = createNewTarget( currentFrame, target ) || '';
  const is_break = isBreak( nextFrame, newTarget );

  return {
    isValid: !!newTarget.length,
    id: newTarget,
    break: is_break
  }
};
