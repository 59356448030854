import { useMemo } from "react";
import { SequenceState } from "../store/actions/types";
import { usePrevious } from "../../assets/usePrevious";
import { getNextScene } from "../store/actions/helper/getNextScene";
import { useSession } from "../../session";
import { useScene } from "../store";

export const useSceneEventsValidation = () => {
  const session = useSession();
  const sequence = useScene();
  const {
    status,
    current,
    event,
    is_animated_in,
    is_animated_out,
    isShown,
    finish,
  } = sequence;
  const has_event = !!event.length;
  const was_finished = usePrevious( finish ) || finish;

  const next = useMemo(
    () => getNextScene( { ...sequence, show: isShown }, session ),
    [isShown, sequence, session]
  );

  const ready = useMemo( () =>
    status === SequenceState.AWAIT_START || status === SequenceState.AWAIT_READY
    , [ status ] );

  const needs_update = useMemo( () =>
    ready && next !== current
    , [ ready, next, current ] );

  const update_current_scene = useMemo( () => {
    const hide =
      is_animated_out
      && !next.length
      && current.length;
    const newCurrent =
      next.length
      && is_animated_out
    && !has_event;

    return Boolean( needs_update && (hide || newCurrent) )
  }, [current.length, has_event, is_animated_out, needs_update, next.length] );

  const hide_current_scene = useMemo( () =>
    needs_update && !!current.length && isShown
    , [ current.length, needs_update, isShown ] );

  const next_scene = useMemo( () =>
      Boolean( finish && is_animated_out )
    , [ finish, is_animated_out ] );

  const start_next = useMemo( () =>
    finish !== was_finished && !!current.length && current !== 'end'
    , [ current, finish, was_finished ] );

  const ended_final_scene = useMemo( () =>
    current === 'end' && status !== SequenceState.AWAIT_READY
    , [ current, status ] );

  const set_to_ready = useMemo( () =>
    is_animated_out && status === SequenceState.AWAIT_START
    , [ is_animated_out, status ] );

  // console.log(is_animated_in)
  const set_to_start = useMemo( () =>
    is_animated_in && isShown && status === SequenceState.AWAIT_READY
    , [ is_animated_in, isShown, status ] );

  return {
    update_current_scene,
    hide_current_scene,
    next_scene,
    start_next,
    set_to_ready,
    set_to_start,
    ended_final_scene,
  }
};
