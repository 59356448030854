import { Hooks } from "scene/stories/it_cellar/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";
import { what_do_you_want_to_know } from "./what_do_you_want_to_know";

const { next, choices, backToBente } = create;

export const details: (FrameProps | string)[] = [
  'Alles klar, was genau willst du wissen',

  choices(Hooks.WHAT_DO_YOU_WANT_TO_KNOW, [
    'Hast du die ganz allein gemacht?',
    'GIB MIR DETAILS'
  ], true),

  'Naja, ich hab den technischen Teil gemacht, also die Programmierung.',
  'Alle Bilder und die Dialoge kommen von Bente.',
  'Also bis auf den hier :)',
  'Noch was was du wissen willst',

  choices(Hooks.WHAT_DO_YOU_WANT_TO_KNOW, [
    'Nö, ich würd dann gerne zurück zu Bente',
    'Ich will mehr Details'
  ], true),

  backToBente("Ok, dann mach's mal gut"),

  ...next(Hooks.WHAT_DO_YOU_WANT_TO_KNOW, what_do_you_want_to_know)
];
