import React from "react";
import Contact from "../../contact";
import { Pages } from "../../constants/Pages";
import { useSceneStart } from "../../scene/store/useSceneStart";

const config = {
  origin: Pages.CONTACT,
  disableGlobalScenes: true
};

const ContactPage = () => {
  useSceneStart(config);

  return <Contact />
};

export default ContactPage;
