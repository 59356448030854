import Imprint from "./Imprint";
import { useSceneStart } from "../../scene/store/useSceneStart";
import React from "react";
import { Pages } from "../../constants/Pages";

const config = { origin: Pages.IMPRINT };


const ImprintPage = () => {
  useSceneStart( config );

  return <Imprint />
};

export default ImprintPage;
