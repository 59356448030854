import create from "scene/stories/create";
import { FrameProps } from "scene/types";
import { ChatImage } from "../../../../../../../constants/chatImages";
import { Hooks } from "../../../../Hooks";

const { next, choices, defaultImage, end } = create;

const did_you_fight_scene: (FrameProps | string)[] = [
  'Bente hat den Chat betreten.',
  'Was ist passiert?! Habt ihr euch geprügelt?',

  choices(Hooks.DID_YOU_FIGHT, [
    'Ne, dein Mann war voll nett.',
    'Ja, hab dem richtig gegeben.'
  ]),

  'Ja, das klingt nach ihm. Voll fies von dir, jetzt muss ich den verarzten na toll, wie nervig ey!',
  end(),

  ...next(Hooks.DID_YOU_FIGHT, [
    'Das kann ich gar nicht glauben. Moment, ich muss den fragen!',
    end()
  ])
];

export const did_you_fight = defaultImage(ChatImage.BENTE, did_you_fight_scene)