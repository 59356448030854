import React from "react";
import styled from "styled-components";
import { ShapeProps, SVG as SVGInterface, Partial } from "../Types";

interface IntermediateProps extends Omit<ShapeProps, 'onMouseOver'> {
}

interface Props extends Omit<IntermediateProps, 'form'>,
  Partial<SVGInterface> {
  id?: string;
  shape?: any;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  show?: boolean;
}

interface DomProps {
  show: boolean;
  switchDirection: boolean;
}

const StyledSVG = styled.svg<DomProps>`
  overflow: visible;
  transform: scaleX(${ props => props.switchDirection ? -1 : 1 });
  opacity: ${ props => props.show ? 1 : 0 };
`;

const SVG: React.FC<Props> = (
  {
    id,
    width = '100%',
    height = '100%',
    preserveAspectRatio = "none",
    viewBox = "0 0 1040 350",
    onMouseOver,
    onMouseLeave,
    switchDirection = false,
    show = true,
    children,
  }
) => (
  <StyledSVG
    show={ show }
    id={ id }
    onMouseOver={ onMouseOver }
    onMouseLeave={ onMouseLeave }
    switchDirection={ switchDirection }
    width={ width }
    height={ height }
    viewBox={ viewBox }
    preserveAspectRatio={ preserveAspectRatio }
  >
    { children }
  </StyledSVG>
);

export default SVG
