import React from 'react';
import styled from 'styled-components';
import Headline from "../../components/headline";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
`;

const Book = () => (
  <Container>
    <Headline text='Über mich und viele Andere'/>
  </Container>
);

export default Book
