import { Hooks } from "../../../Hooks";
import create from "../../../../create";
import { so_what_now } from "./so_what_now";
import { ChatImage } from "../../../../../../constants/chatImages";

const { next, choices, follow, singleChoice } = create;

export const i_must_destroy = [
  'Ich... muss vernichten...',
  choices( Hooks.I_MUST_DESTROY, [
    'O...kay',
    'Wir haben Kekse!'
  ], true ),

  ...follow(['Unterjochen. Beherrschen'] ),

  singleChoice('Ne warte mal. Ich habs nicht so gemeint!'),

  ...follow(so_what_now),

  ...next(Hooks.I_MUST_DESTROY, [
    'Hab ich da Kekse gehört?',
      {
        image: ChatImage.BENTE_COOKIE,
        text: 'Die Kekspolizei hat den Chat betreten.'
      },
      {
        image: ChatImage.BENTE_COOKIE,
        text: 'HÄNDE HOCH ODER ICH SCHLIESSE!'
      },
    'Zu spät, Norbert hat den Chat geschlossen.'
  ],
    true)
];
