import { Hooks } from "../Hooks";
import create from "../../create";
import { sinan } from "./sinan/sinan";
import { born_in_bad_kreuznach } from "./born_in_bad_kreuznach";
import { ChatImage } from "../../../../constants/chatImages";

const { next, choices, defaultImage } = create;

export const chat_bot_x = [
  '*beep beep boop* Hier spricht Ihr BenteBot. Machen Sie Ihren Wunsch bekannt.',

  choices(Hooks.CHAT_BOT, [
    'Erzähl mir etwas über die Künstlerin.',
    'Wer hat dich programmiert?'
  ]),

  ...sinan,

  ...next(Hooks.CHAT_BOT, born_in_bad_kreuznach)
];

export const chat_bot = defaultImage(ChatImage.BENTE, chat_bot_x);
