import { Hooks } from "../../../Hooks";
import create from "../../../../create";
import { do_you_have_colds } from "./do_you_have_colds";

const { next, choices } = create;

export const feedback_please = [
  'Wir freuen uns über Ihres Feedback. Haben Sie noch eine folgende Frage?',
  choices(Hooks.FEEDBACK_PLEASE, [
    'Kann Sinan mir mehr über die Seite erzählen?',
    'Hast du Gefühle?'
  ]),

  ...do_you_have_colds,

  ...next(Hooks.FEEDBACK_PLEASE, [
    {
      text:'Selbstverständlich. Wir verbinden Sie mit dem IT-Keller.',
      target: '/it_cellar'
    }
  ])
];