import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";
import { UNFINISHED_who_is_sinan } from "./UNFINISHED_who_is_sinan";
import { UNFINISHED_wife } from "./UNFINISHED_wife";
import { ChatImage } from "../../../../../../../constants/chatImages";

const { next, choices, defaultImage } = create;

const sinan_takes_over_scene: (FrameProps | string)[] = [
  'Sinan hat den Chat betreten.',
  'Selber doof.',

  choices(Hooks.SINAN_TAKES_OVER, [
    'Oh, äh, sorry.',
    'Wer bist du denn?'
  ]),

  ...UNFINISHED_who_is_sinan,

  ...next(Hooks.SINAN_TAKES_OVER, UNFINISHED_wife)
];

export const sinan_takes_over = defaultImage(ChatImage.SINAN, sinan_takes_over_scene);
