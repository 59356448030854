import TimelineMax from 'assets/utilz/timeline';


export const animateRight = (quotes: any) => ({
  in: () => animateRightIn( quotes.ref ).play().delay(4.3),
});

const animateRightIn = (quotes: HTMLDivElement) => new TimelineMax( { paused: true } )
  .to( quotes.childNodes, {
    duration: 1,
    x: 0,
    stagger: .6,
    ease: 'power4.out'
  } );