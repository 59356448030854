import { Hooks } from "../../../Hooks";
import create from "../../../../create";
import { not_this_tone } from "./not_this_tone";
import { cold_dog } from "./cold_dog";

const { choices } = create;

export const order_please = [
  'Bestellung wird aufgenommen:',
  choices(Hooks.COLD_DOG, [
    'Ein Bananensplit mit extra Sahne, bitte.',
    'Drei mal Schoko, hopp hopp!'
  ]),
  ...not_this_tone,

  ...cold_dog

];