import { Coordinates } from "../../../Types";
import { randomizePoint } from "./randomizePoint";

export function randomizeCoordinates(
  coordinates: Coordinates,
  displacementMap: Coordinates,
  x = 1,
  y = 1,
) {
  return coordinates.map( (coord, i) => {
    const newX = randomizePoint( coord[0], displacementMap[i][0], x );
    const newY = randomizePoint( coord[1], displacementMap[i][1], y );
    return [ newX, newY ]
  } )
}
