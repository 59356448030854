import React, { useRef } from "react";
import Circle from "./Circle";
import styled from "styled-components";

interface Props {
  color?: string;
  show?: boolean;
}

const Inner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;


export const Dots: React.FC<Props> = ({color, show = true}) => {
  const circle1Ref = useRef( null );
  const circle2Ref = useRef( null );
  const circle3Ref = useRef( null );

  if (!show) return null;

  return (
    <Inner>
      <Circle color={color} circleRef={ circle1Ref } />
      <Circle color={color} circleRef={ circle2Ref } delay={ .3 } />
      <Circle color={color} circleRef={ circle3Ref } delay={ .5 } />
    </Inner>
  )
};

export default Dots;
