import { createChoices } from "../../../../create/createChoices";
import { Hooks } from "../../../Hooks";
import { next } from "../../../../create/next";
import { meat_figure } from "./meat_figure";
import { i_was_hurt } from "./i_was_hurt";

export const where_should_this_lead = [
  'Wo soll das mit uns nur hinführen?',

  createChoices(
    Hooks.WHERE_SHOULD_THIS_LEAD,
    [
      'Ich muss dich kennenlernen!',
      'Ich glaube, wir sind zu unterschiedlich'
    ]
  ),

  ...next('', meat_figure),

  ...next(Hooks.WHERE_SHOULD_THIS_LEAD, i_was_hurt)
];
