import { TextPosition } from "../Types";

export const standard: TextPosition = {
  width: 100,
  height: 90,
  top: 5,
  left: 0,
  bottom: 'auto',
  right: 'auto'
};

export const chat_answer: TextPosition = {
  top: 0,
  left: 0,
  width: 100,
  height: 100,
};

export const chat_bubble: TextPosition = {
  width: 79,
  height: 75,
  top: 10,
  left: 18
};

export const arrow: TextPosition = {
  width: 62,
  height: 49,
  top: 10,
  left: 12
};

export const getTextPosition = (
  textPosition?: TextPosition,
  switchDirection?: boolean
): TextPosition | undefined => {

  if (switchDirection && isPosition(textPosition)) {
    return reverse(textPosition);
  }

    return textPosition
};

const isPosition = (textPosition?: TextPosition): textPosition is TextPosition =>
  (textPosition as TextPosition).left !== undefined;

const reverse = (textPosition: TextPosition) => {
  const {
    left,
    right,
    ...restPosition
  } = textPosition;

  return {
    ...restPosition,
    left: right || 'auto',
    right: left || 'auto'
  }
};

export default {
  chat_answer,
  chat_bubble,
  arrow
}
