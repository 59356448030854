import React from 'react';
import styled from 'styled-components';
import { Shape, ShapeConstructor } from "..";

const Container = styled.div`
  width: 160px;
`;

const customShape = () => {
  return new ShapeConstructor()
    .apostrophe( { fill: 'white' } )
    .add()
    .grow( { x: 15, y: 25, fill: 'black' } )
    .add()
};

const Apostrophe = () => (
  <Container id="Apostrophe">
    <Shape
      form={ customShape() }
      textColor='black'
    />
  </Container>
);

export default Apostrophe
