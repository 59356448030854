import { Coordinates } from "../../../Types";
import Random from "./Random";


export function calculateDisplacement(displacementMap: Coordinates) {
  const rand = new Random();

  return displacementMap.map( point => (
    point.map( () => rand.coin() )
  ) )
}
