import { createChoices } from "../../../../create/createChoices";
import { Hooks } from "../../../Hooks";
import { next } from "../../../../create/next";
import { where_should_this_lead } from "../where_should_this_lead/where_should_this_lead";
import { SceneEvent } from "../../../../../types";

export const i_think_i_love_you = [
  'Ich glaube, ich habe mich in dich Verliebt, Nutzer.',
  createChoices(
    Hooks.I_THINK_I_LOVE_YOU,
    [
      'Oh, ich glaube ich muss los!',
      'Ich finde dich auch ganz süß.'
    ]
  ),

  ...next('', where_should_this_lead),

  ...next(Hooks.I_THINK_I_LOVE_YOU, [
    'Nein, bitte!',
    '...',
    ':((((',
    {
      text: 'Der BenteBot hat sich selbst zerstört',
      event: SceneEvent.EXPLOSION
    },
  ], true)
];