import React from "react";
import greeting from '../../scene/stories/greeting';
import TestUi from "../components/TestUi";
import understand_burger from "../../scene/stories/understand_burger";
import { useTestActions } from "./useTestActions";
import { useScene } from "../../scene";
import { SceneType } from "../../scene/types";
import { useComponentMount } from "../../assets/useComponentMount";

const SceneScreen = () => {
  const actions = useTestActions();
  const { start } = useScene();

  const localScenes = [
    {
      ...greeting,
      origin: '/chat',
      type: SceneType.CHAT,
      id: 'testing'
    },
    understand_burger
  ];

  useComponentMount( () => start({
    //@ts-ignore
    origin: '/chat',
    localScenes,
    disableGlobalScenes: true
  }) );


  const buttons = [
    {
      text: 'Show',
      onClick: actions.show
    },
    {
      text: 'Hide',
      onClick: actions.hide
    },
    {
      text: 'Add Scene',
      onClick: actions.addScene
    },
  ];

  return (
    <div>
      <TestUi buttons={ buttons } />
    </div>
  );
};

export default SceneScreen;
