import { CoordsAndDisplace, WiggleOptions } from "../../../Types";
import { randomize } from "./randomize";

export function wigglePoint(elem: CoordsAndDisplace, wiggleOptions: WiggleOptions): number[][] {
  // extend is not yet used. Should test if needed.
  const { coordinates, displacementMap } = elem;
  return coordinates.map( (point, i) => {
    const x = displacementMap[i][0] * wiggleOptions.x;
    const y = displacementMap[i][1] * wiggleOptions.y;
    return [
      randomize( point[0], x ),
      randomize( point[1], y )
    ]
  } )
}
