import React, { useState } from "react";
import { DialogChoice, DialogChoiceContainer, DialogChoiceText } from "../Styles";
import Template from "../../../shapes";
import { ChoiceProps } from "../../types";
import { useScene } from "../../index";

interface PropsChoice {
  choice: ChoiceProps;
}

export const Choice: React.FC<PropsChoice> = ({ choice }) => {
  const scene = useScene();
  const [selected, setSelected] = useState(false);


  const onProgress = () => {
    scene.onProgress( choice.target );
    setSelected(true)
  };

  return (
    <DialogChoice onClick={ onProgress } hide={!choice.text}>
      <DialogChoiceContainer selected={selected}>

        <DialogChoiceText>
          { choice.text }
        </DialogChoiceText>
        <Template type="arrow" containerWidth='100%' onMouseOver={'reverse'} />
      </DialogChoiceContainer>
    </DialogChoice>
  );
};
