import React from "react";
import styled from 'styled-components';
import { Shape, Template, ShapeConstructor, SpeechBubble } from "../../shapes";

const Body = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  background-color: red;
`;

const Container = styled.div`
  width: 300px;
  height: 40px;
  margin: 40px;
`;

const ContainerBig = styled.div`
  width: 600px;
  height: 200px;
  margin: 40px;
`;

const customShape = () => {
  return new ShapeConstructor()
    .rectangle({fill: 'white'})
    .randomize( { x: 20, y: 2, extend: true } )
    .add()
    .grow( { fill: 'black', x: 3, y: 10 } )
    .randomize( { extend: false, x: 20 } )
    .add();
};

const DemoShape = () => (
  <Body>
    <Container>
      <Template
        type="chat_answer"
        onMouseOver={ [ 'wiggle', 'reverse' ] }
      >
        chat_answer
      </Template>
    </Container>
    <Container>
      <Template
        type="chat_bubble"
        onMouseOver='wiggle'
      >
        chat_bubble
      </Template>
    </Container>
    <Container>
      <Template type="arrow">
        arrow
      </Template>
    </Container>
    <Container>
      <Template type="arrow" switchDirection>
        arrow
      </Template>
    </Container>
    <Container>
      <Template
        type="rectangle"
        onMouseOver='reverse'
      >
        rectangle
      </Template>
    </Container>
    <Container style={{marginLeft: '160px'}}>
      <Shape
        form={ customShape() }
        onMouseOver={[ 'wiggle', 'reverse' ]}
        textColor='black'
        wiggleOptions={{x: 40, y: 10}}
      >
        custom shape
      </Shape>
    </Container>
    <ContainerBig>
      <SpeechBubble name="Horst">
        Text here
      </SpeechBubble>
    </ContainerBig>
  </Body>
);

export default DemoShape
