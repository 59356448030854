import { TextPosition } from "../../Types";

export const getPosition = (key: keyof TextPosition, position?: TextPosition): string => {
  if ( !position ) {
    return '0';
  }
  if ( typeof position[key] !== 'undefined' ) {
    if ( typeof position[key] === 'number' ) {
      return position[key] + "%";
    }
    return String( position[key] )
  }
  return 'auto'
};
