import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Shape, ShapeConstructor } from "../../shapes";


type Props = {
  index: number;
  length: number;
}

const Container = styled.div`
  position: absolute;
  left: 50%;
  bottom: -30px;
  width: 70px;
  height: 30px;
  transform: translateX(-50%);
  color: white;
`;

const customShape = () => {
  return new ShapeConstructor()
    .rectangle()
    .randomize( { x: 20, y: 10 } )
};

const Indexing: React.FC<Props> = (
  {
    index,
    length
  }
  ) => {
  const shape = useMemo(customShape, []);

  return (
    <Container>
      <Shape
        form={ shape }
        textColor='white'
        wiggleOptions={{x: 40, y: 10}}
      >
        {index + 1} / {length}
      </Shape>
    </Container>
  );
}

export default Indexing
