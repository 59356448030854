import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";
import { UNFINISHED_something_to_eat } from "./UNFINISHED_something_to_eat";
import { hobbies } from "./hobbies";

const { next, choices, placeholder } = create;

export const UNFINISHED_wife: (FrameProps | string)[] = [
  'Was willst du von meinem Eheweib?',

  choices(Hooks.WIFE, [
    'Was zum Essen.',
    'Nur smalltalken.'
  ]),

  'Darin bin ich der Meister!',

  choices(Hooks.HOBBIES, [
    'Was sind denn Bentes Hobbies?',
    'Woher kommt Bente denn?'
  ]),

  placeholder('Die wurde am 10.11.88 in Bad Kreuznach geboren. Weiter.'),

  ...next(Hooks.HOBBIES, hobbies),

  ...next(Hooks.WIFE, UNFINISHED_something_to_eat)
];
