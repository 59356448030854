import { createFrame } from "./createFrame";
import { id } from "../../utilz/id";
import { CreateScene, Frame, SceneInterface, SceneProps, SceneType } from "../types";
import { DialogImage } from "../../constants/dialogImages";

const sceneDefault = {
  delay: 1,
  order: 1,
  image: DialogImage.BENTE,
  name: 'Bente',
  type: SceneType.DIALOG,
  origin: 'LOCAL',
  repeat: false,
  endless: false,
};

export const createScene = (props: CreateScene): SceneInterface => {
  if (isScene(props)) {
    return create(props)
  }
  return createFromPlaneText(props)
};

export const create = (props: SceneProps): SceneInterface => {
  const frameProps = props.frames || [];
  const merged:Omit<SceneInterface, 'frames'> = {
    id: id(),
    ...sceneDefault,
    ...props,
  };
  return {
    ...merged,
    frames: createFrame(frameProps as Frame[], merged)
  };
};

const createFromPlaneText = (props: CreateScene) => {
  const frames = createFrame([{text: props}] as Frame[], sceneDefault);
  return create({
    frames,
    order: 100,
  })
};

const isScene = (props: SceneProps | string): props is SceneProps =>
  (props as SceneProps).frames !== undefined;
