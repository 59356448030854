import { useCallback, useMemo, useState } from "react";
import {createContainer} from "unstated-next";
import { Pages } from "../constants/Pages";
import { SceneInterface, SceneList, SceneState, SequenceState, StartSceneProps } from "../scene/types";
import { globalScenes } from "../scene/constructor/globalScenes";

export const initialState: SceneState = {
  scenes: [],
  played: [],
  current: '',
  show: false,
  frameIndex: 0,
  is_animated_in: false,
  is_animated_out: true,
  delay: 0,
  disableGlobal: false,
  ready: false,
  end: false,
  page: Pages.HOME,
  status: SequenceState.LOADING,
  pageConfig: null,
  finish: false,
  event: '',
};

const useSceneBase = () => {
  const [scenes, setScenes] = useState<SceneList>([]);
  const [played, setPlayed] = useState<string[]>([]);
  const [current, setCurrent] = useState('');
  const [show, setShow] = useState(false);
  const [frameIndex, setFrameIndex] = useState(0);
  const [is_animated_in, set_is_animated_in] = useState(false);
  const [is_animated_out, set_is_animated_out] = useState(true);
  const [delay, setDelay] = useState(0);
  const [disableGlobal, setDisableGlobal] = useState(false);
  // const [ready, setReady] = useState(false);
  // Todo what the hell does this do
  const [end] = useState(false);
  const [page, setPage] = useState<Pages>(Pages.HOME);
  const [status, setStatus] = useState<SequenceState>(SequenceState.LOADING);
  const [pageConfig, setPageConfig] = useState<StartSceneProps | null>(null);
  const [finish, setFinish] = useState(false);
  const [event, setEvent] = useState('');

  const initialize = () => {
    setScenes(globalScenes());
    setStatus(SequenceState.INITIALIZED);
  };

  const onSetPage = (page: Pages) => {
    setPage(page);
    setStatus(SequenceState.AWAIT_CONFIG);
  };

  const onSetPageConfig = (config: StartSceneProps | null) => {
    setPageConfig(config);
    setStatus(SequenceState.AWAIT_SCENES);
  };

  const setupSequence = (scenes: SceneList, disableGlobal = false) => {
    setScenes(scenes);
    setDisableGlobal(disableGlobal);
    setStatus(SequenceState.AWAIT_START);
    setPageConfig(null);
  };

  const awaitReady = () => setStatus(SequenceState.AWAIT_READY);
  const setStart = () => setStatus(SequenceState.START);

  const addScene = (scenes: SceneList, newCurrent: string) => {
    setScenes(scenes);
    setCurrent(newCurrent);
    setShow(true);
  };

  const onSetCurrent = (current: string, show = !!current.length) => {
    setCurrent(current);
    setShow(show);
    setFrameIndex(0);
  }

  const onShow = () => setShow(true);
  const onHide = () => setShow(false);
  const incrementFrame = () => setFrameIndex(frameIndex + 1);
  const onSetFrameIndex = (index: number) => setFrameIndex(index);
  const onSetEvent = (event: string) => setEvent(event);
  const resetEvent = () => setEvent('');
  const setAnimatedIn = useCallback((state: boolean) => {
    if (state !== is_animated_in) {
      set_is_animated_in(state)
    }
  }, [is_animated_in]);
  const setAnimatedOut = useCallback((state: boolean) => {
    if (state !== is_animated_out) {
      set_is_animated_out(state)
    }
  }, [is_animated_out]);

  const nextScene = (current: string, delay: number, played: string[]) => {
    setCurrent(current);
    setDelay(delay);
    setFrameIndex(0);
    setPlayed(played);
    setFinish(false);
  }
  const finishScene = () => {
    setFinish(true);
    setShow(false);
  }

  const currentScene = useMemo( (): SceneInterface =>
      scenes.filter( scene => scene.id === current )[0] || {}
    , [ current, scenes ] );


  return {
    initialize,
    setPage: onSetPage,
    setPageConfig: onSetPageConfig,
    setupSequence,
    awaitReady,
    setStart,
    addScene,
    setCurrent: onSetCurrent,
    show,
    // isShown: show,
    onShow,
    hide: onHide,
    incrementFrame,
    setFrameIndex: onSetFrameIndex,
    setEvent: onSetEvent,
    resetEvent,
    setAnimatedIn,
    setAnimatedOut,
    nextScene,
    finishScene,
    ready: status === SequenceState.INITIALIZED,
    currentScene,
    played,
    current,
    scenes,
    frameIndex,
    is_animated_in,
    is_animated_out,
    delay,
    disableGlobal,
    end,
    page,
    status,
    pageConfig,
    finish,
    event,
  };
}

const useSceneContainer = createContainer(useSceneBase);
export const useSceneContext = useSceneContainer.useContainer;
export const SceneProvider = useSceneContainer.Provider;
