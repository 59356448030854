import create from "scene/stories/create";
import { FrameProps } from "scene/types";

const { end, choicesIrrelevant } = create;

export const angry: (FrameProps | string)[] = [
  'WAS?! Jetzt kriegt die aber Ärger!',

  choicesIrrelevant('', [
    'Nein warte ich hab gelogen!',
    'OH NEIN ES TUT MIR LEID!!'
  ]),

  'MARLENE!',
  end('Sinan hat den Chat verlassen, man hört Geschrei im Hintergrund')
];
