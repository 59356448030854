import create from "scene/stories/create";
import { FrameProps } from "scene/types";
import { ChatImage } from "../../../../../../../constants/chatImages";
import { everything_under_control } from "./everything_under_control";
import { Hooks } from "../../../../Hooks";

const { next, choices, defaultImage, end } = create;

const sinan_takes_over2_scene: (FrameProps | string)[] = [
  'Sinan hat den Chat betreten',
  "Was'n Jetzt los?",

  choices(Hooks.SINAN_TAKES_OVER2, [
    'Stimmt das mit dem Heizungstyp?',
    'Äh, keine Ahnung...'
  ]),

  ...everything_under_control,

  ...next(Hooks.SINAN_TAKES_OVER2, [
    'Der hat angefangen! Ich bin UNSCHULDIG!! WER SIND SIE?! BLEIBEN SIE MIR VOM LEIB!!',
    end('Sinan hat den Chat verlassen')
  ])
];

export const sinan_takes_over2 = defaultImage(
  ChatImage.SINAN,
  sinan_takes_over2_scene
);
