import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";
import { hope_you_like_it } from "../hope_you_like_it";

const { next, choices, end } = create;

export const book_sinan: (FrameProps | string)[] = [
  'Ja man! Außerdem hat sie ein eigenes Buch, das heißt „Herzschlag ins Gesicht“.',

  choices(Hooks.BOOK_SINAN, [
    'Das hab ich sogar schon!',
    'Wo kann man das denn kaufen?'
  ]),

  'Überall, wo es Bücher gibt :)',
  "Guck's dir doch mal an! Also, mach’s gut!",
  end('Sinan hat den Chat verlassen.'),

  ...next(Hooks.BOOK_SINAN, hope_you_like_it)
];
