import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";
import { little_birdy } from "./little_birdy";

const { next, choices, end } = create;

export const keep_secret: (FrameProps | string)[] = [
  'Das will ich doch hoffen! Sonst werd ich richtig sauer.',
  choices(Hooks.KEEP_SECRET, [
    'Okay, du bist wirklich frech.',
    'Versprochen.'
  ]),

  'Danke! Du bist voll nett! Ich verstecke mich lieber wieder.',
  'Marlene ist jetzt im Inkognito- Modus',
  ...little_birdy,

  ...next(Hooks.KEEP_SECRET, [
    'Mann, ich wollte einfach nur nach Hörspielen suchen',
    'Ich muss weiter, Stör mich nicht!',
    end('Marlene hat den Chat stumm geschaltet.')
  ])
];
