import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";

const { choicesIrrelevant } = create;

export const UNFINISHED_something_to_eat: (FrameProps | string)[] = [
  'Vergiss es. Die ist viel zu faul zum kochen.',

  choicesIrrelevant(Hooks.PLACEHOLDER, [
    'Was macht sie denn lieber?',
    'Ich bestell mir Pizza.'
  ])
];
