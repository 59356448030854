import { Coordinates } from "../../../Types";

export const growShape = (
  x: number,
  y: number,
  coordinates: Coordinates,
  displacementMap: Coordinates
) => {
  return coordinates.map( (point, i) => {
    const newX = point[0] + x * displacementMap[i][0];
    const newY = point[1] + y * displacementMap[i][1];
    return [ newX, newY ]
  } )
};
