export const coordinates = [
  [ 177, 18 ],
  [ 1040, 0 ],
  [ 1017, 350 ],
  [ 153, 324 ],
  [ 162, 212 ],
  [ 102, 233 ],
  [ 78, 175 ],
  [ 8, 183 ],
  [ 96, 130 ],
  [ 127, 183 ],
  [ 165, 168 ],
];

export const displacementMap = [
  [ -1, -1.5 ],
  [ 3, -1 ],
  [ 1, 1 ],
  [ -1.5, 1.5 ],
  [ -1, 1 ],
  [ 0, 1 ],
  [ 0, 1 ],
  [ -1, .3 ],
  [ 0, -1 ],
  [ 0, -1 ],
  [ -1, -1 ],
];

export const viewBox = "0 0 1040 350";
