export type TextColor = 'black' | 'white';

export const getTextColor = (
  textColor: TextColor,
  reverse: boolean
): TextColor => {
  return !reverse
    ? textColor
    : textColor === 'white'
      ? 'black'
      : 'white'
};
