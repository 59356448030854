import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";
import { tunnel } from "./tunnel";

const { choices } = create;

export const what: (FrameProps | string)[] = [
  'Was?',

  choices(Hooks.WHAT, [
    'Hast du was gesagt?',
    'Was?'
  ], true),

  ...tunnel
];
