import { SceneList } from "../../../../types";
import { State } from "../../types";
import { State as SessionState } from "../../../../../session/store/types";
import { SceneValidation } from "./SceneValidation/SceneValidation";

export const filterValid = (
  state: State,
  scenes: SceneList,
  session: SessionState
) => {
  const validate = new SceneValidation( state, scenes, session );

  return validate
    .global()
    .page()
    .session()
    .scenes
};
