import chat_bente_bg from "../assets/img/chat/bg-bente.png";
import chat_bente_face from "../assets/img/chat/bente.png";
import chat_bentor_face from "../assets/img/chat/bentor.png";
import chat_marlene_face from "../assets/img/chat/marlene.png";
import chat_marlene_bg from "../assets/img/chat/bg-marlene.png";
import chat_user_face from "../assets/img/chat/dude.png";
import chat_user_bg from "../assets/img/chat/bg-dude.png";
import chat_sinan_face from "../assets/img/chat/Unbenanntes_Projekt 3.png";
import chat_sinan_bg from "../assets/img/chat/Unbenanntes_Projekt 4.png";

type ImageObject = {
  face: string;
  background: string
}

export enum ChatImage {
  BENTE = 'bente',
  BENTOR = 'bentor',
  MARLENE = 'marlene',
  USER = 'user',
  SINAN = 'sinan',
  BENTE_COOKIE = 'bente_cookie',
}

export interface ChatImages {
  bente: ImageObject;
  bentor: ImageObject;
  marlene: ImageObject;
  user: ImageObject;
  sinan: ImageObject;
  bente_cookie: ImageObject;
}

export const chatImages: ChatImages = {
  bente: {
    face: chat_bente_face,
    background: chat_bente_bg,
  },
  bentor: {
    face: chat_bentor_face,
    background: chat_bente_bg,
  },
  marlene: {
    face: chat_marlene_face,
    background: chat_marlene_bg,
  },
  user: {
    face: chat_user_face,
    background: chat_user_bg,
  },
  sinan: {
    face: chat_sinan_face,
    background: chat_sinan_bg,
  },
  bente_cookie: {
    face: chat_bente_face,
    background: chat_bente_bg,
  },
};
