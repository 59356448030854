import {createContainer} from "unstated-next";
import {useState} from "react";
import { Categories } from "../portfolio/types";

const usePortfolioBase = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [categories, setCategories] = useState<Categories>([]);
  const [openCategory, setOpenCategory] = useState('');
  const [openImage, setOpenImage] = useState(-1);


  const fetchImages = () => setIsLoading(true);
  const storeImages = (categories: Categories) => {
    setIsLoading(false);
    setCategories(categories);
  };
  const onSetOpenCategory = (open: string) => setOpenCategory(open);
  const onResetOpenCategory = () => setOpenCategory('');
  const onSetOpenImage = (open: number) => setOpenImage(open);
  const openSlider = () => setIsSliderOpen(true);
  const closeSlider = () => setIsSliderOpen(false);
  // const enable = () => setIsDisabled(false);

  return {
    isLoading,
    isSliderOpen,
    categories,
    openCategory,
    openImage,
    fetchImages,
    storeImages,
    setOpenCategory: onSetOpenCategory,
    resetOpenCategory: onResetOpenCategory,
    setOpenImage: onSetOpenImage,
    openSlider,
    closeSlider,
  };
}

const usePortfolioContainer = createContainer(usePortfolioBase);
export const usePortfolio = usePortfolioContainer.useContainer;
export const PortfolioProvider = usePortfolioContainer.Provider;
