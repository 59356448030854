import { Hooks } from "../about/Hooks";
import { FrameProps, SceneEvent } from "../../types";
import { ChatImage } from "../../../constants/chatImages";
import {sinan} from "./createCharacter";

export const placeholder: (FrameProps | string)[] = [
  {
    image: ChatImage.SINAN,
    text: 'Whoops, hier hat Bente noch was vergessen.',
    id: Hooks.PLACEHOLDER,
  },
  sinan('Die hat nämlich den Chat geschrieben.'),
  sinan('Und ich die Seite drum rum.'),
  sinan('Grüße aus dem IT Keller'),
  {
    image: ChatImage.SINAN,
    text: 'Blitzdings und von vorne',
    target: Hooks.START,
    event: SceneEvent.LIGHTNING
  }
];



