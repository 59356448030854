import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: flex-end;
`;

export const Stage = styled.div`
  position: relative;
  width: 500px;
  // Depending on Screen size
  height: 100%;
  min-height: 500px;
  //bottom: -80px;
  bottom: 0;
  left: calc(50% - 250px);
`;

export const Image = styled.img`
  position: absolute;
  //bottom: -160px;
  width: 800px;
  height: auto;
  left: calc(50% - 400px);
  object-fit: contain;
`;

export const Title = styled.div`
  position: absolute;
  top: 40px;
  font-family: 'Lato', sans-serif;
  font-style: italic;
  font-weight: bold;
  font-size: 80px;
  text-transform: uppercase;
  color: white;
  transform: rotate(-7deg);
`;

export const Name = styled.div`
  position: absolute;
  width: 100%;
  top: 10px;
  font-family: 'Lato', sans-serif;
  font-weight: lighter;
  font-size: 20px;
  text-transform: uppercase;
`;

export const LogoEichborn = styled.img`
  position: absolute;
  width: 100px;
  bottom: 120px;
  left: 30px;
`;

export const Confetti = styled.img`
  position: absolute;
  display: none;
  width: 600px;
  height: 400px;
  left: -330px;
  top: 80px;
`;
