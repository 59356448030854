import React from "react";
import Book from "./Book";
import { Pages } from "../../constants/Pages";
import { useSceneStart } from "../../scene/store/useSceneStart";

const config = {
  origin: Pages.BOOK,
};

const BookPage = () => {
  useSceneStart(config);

  return <Book />
};

export default BookPage;
