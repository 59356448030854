import { ChatImage } from "../../../constants/chatImages";
import { DialogImage } from "../../../constants/dialogImages";
import { FrameProps } from "../../types";
import { next } from "./next";

export const createDefaultImage = (
  image: ChatImage | DialogImage,
  file: (FrameProps | string)[],
) => {
  const scene = next(image, file);

  return scene.map(el => ({
    ...el,
    image: el.image || image
  }))
};
