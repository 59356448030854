import React from 'react';
import { FiniteState } from "../../assets/finiteFetch";
import { InputContainer, Form as FormContainer } from "../ContactStyle";
import InputMail from "./InputMail";
import InputText from "./InputText";
import SubmitForm from "../SubmitForm";

export interface Props {
  mail: string;
  text: string;
  state: FiniteState;
  inputMail(e: React.FormEvent<HTMLInputElement>): void;
  inputText(e: React.FormEvent<HTMLTextAreaElement>): void;
  onSubmit(e: React.FormEvent<HTMLButtonElement>): void;
}

const Form: React.FC<Props> = (
  {
    mail,
    text,
    inputMail,
    inputText,
    onSubmit,
    state,
  }
) => (
  <FormContainer>
    <InputContainer>
      <InputMail mail={ mail } onChange={ inputMail } />
      <InputText text={ text } onChange={ inputText } />
    </InputContainer>
    <SubmitForm onSubmit={ onSubmit } state={ state } />
  </FormContainer>
);

export default Form;
