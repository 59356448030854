import React from "react";
import About from "./About";
import { Pages } from "../../constants/Pages";
import { useSceneStart } from "../../scene/store/useSceneStart";
import { about as about_chat } from "../../scene/stories/about";

const config = {
  origin: Pages.ABOUT,
  localScenes: [ about_chat ],
  disableGlobalScenes: true
};

const AboutPage = () => {
  useSceneStart( config );

  return <About />
};

export default AboutPage;
