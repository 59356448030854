import { State } from "../../../types";
import { SceneInterface, SceneList } from "../../../../../types";
import { State as SessionState } from "../../../../../../session/store/types";
import { Pages } from "../../../../../../constants/Pages";


export class SceneValidation {

  constructor(
    private state: State,
    public scenes: SceneList,
    private sessionState: SessionState
  ) {
  }
    global() {
      if ( this.state.disableGlobal ) {
        this.scenes = this.scenes.filter( removeGlobals )
      }

      return this;
    }

    page() {
      if ( this.state.page ) {
        this.scenes = this.scenes.filter( removeNonMatchingPath(this.state.page) )
      }

      return this;
    }

    session() {
      this.scenes = removeFiltered(this.scenes, this.sessionState);

      return this;
    }
}

const removeFiltered = (
  scenes: SceneList,
  {filtered_scenes}: SessionState
) => scenes
  .filter(scene =>
    filtered_scenes.indexOf(scene.id) === -1
  );




const removeGlobals = (scene: SceneInterface) => scene.origin !== 'GLOBAL';

const removeNonMatchingPath = (page: Pages) =>
  (elem: SceneInterface) => elem.origin === page || elem.origin === 'GLOBAL';
