import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";

const { choicesIrrelevant } = create;

export const UNFINISHED_who_is_sinan: (FrameProps | string)[] = [
  'Ich bin Sinan: Ehemann, Vater, Visionär.',

  choicesIrrelevant(Hooks.PLACEHOLDER, [
    'Klingt fast spannend.',
    'Ähm, kann ich wieder mit Bente sprechen?'
  ]),

];
