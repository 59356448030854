import create from "scene/stories/create";
import { Hooks } from "../../../Hooks";

const { next, multipleChoices, restartLightning } = create;


export const so_what_now = [
  'Hä? Was denn nun?',

  multipleChoices(
    [Hooks.SO_WHAT_NOW, 'Ich glaube, wir sollten das ganze vergessen.'],
    [Hooks.PLACEHOLDER, 'Du bist echt gruselig']
  ),

  ...next(Hooks.SO_WHAT_NOW, [
    'Du hast recht.',
    '...',
  ]),

  restartLightning('Los gehts: <blitzdings=initiate>')
];