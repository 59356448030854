import React from 'react';
import Cover from "./Cover";
import Quotes from "./Quotes";

const Home = () => {

  return (
    <>
      <Cover />
      <Quotes />
    </>
  );
};

export default Home
