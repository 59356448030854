import { default as SpeechBubbleTemplate } from "./components/SpeechBubble";
import React from "react";
import Apostrophe from "./components/AnimatedApostrophe";

interface Props {
  name?: string;
}

const SpeechBubble: React.FC<Props> = (
  {
    name = 'Bente',
    children
  }
) => (
  <div style={{position: "relative"}}>
    <SpeechBubbleTemplate name={name}>
      { children }
    </SpeechBubbleTemplate>
    <Apostrophe />
  </div>
);

export default SpeechBubble;
