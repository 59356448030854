export const config = {
  default_dev_mode: false,
  skip_mobile_in: false,
  skip_mobile_out: false,
  send_test_mail: false, // false > send real mail | 'OK' > show success | 'anything but OK' > show error
  skip_mail_validation: false,
};

export const settings = {
  chat_distance_bottom: 200
};
