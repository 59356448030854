import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";

const { next, choices, end } = create;

export const etsy: (FrameProps | string)[] = [
  'Außerdem hat sie einen Shop auf Etsy, wo du viele ihrer Bilder kaufen kannst.',

  choices(Hooks.SINAN_ETSY, [
    'Ok, cool. Danke!',
    'Pf. Kunst sollte umsonst sein. Das gehört allen.'
  ]),

  'Deine Mutter gehört allen. Geh woanders spielen.',
  end('Sinan hat dich geblockt.'),

  ...next(Hooks.SINAN_ETSY, [
    'Ja, kein Problem. Ich hau mal wieder ab. Benimm dich!',
    end('Sinan hat den Chat verlassen.')
  ])
];
