import React, { useMemo, useRef } from 'react';
import { Quote } from "../../../constants/quotes";
import DesignedQuote from "./DesignedQuote";
import { SetDomNodes } from "../../../assets/useDomNodes";
import { useComponentMount } from "../../../assets/useComponentMount";
import {Side} from "./styles";

type Props = {
  quotes: Quote[];
  setDomNodes: SetDomNodes;
  left?: boolean;
}

const QuoteBlock: React.FC<Props> = (
  {
    quotes,
    setDomNodes,
    left
  }
) => {
  const ref = useRef(null);

  useComponentMount(() => {
    setDomNodes({ref})
  });

  const quoteMap = useMemo( () =>
      quotes.map( (quote, i) =>
        <DesignedQuote
          { ...quote }
          key={ i }
          left={left}
        />
      )
    , [left, quotes] );

  return (
    <Side ref={ref}>
      { quoteMap }
    </Side>
  )
};

export default QuoteBlock
