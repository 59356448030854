import { CoordsAndDisplace } from "../../../Types";
import { displacer } from "./displacer";
import { randomizeCoordinates } from "./randomizeCoordinates";
import { PositionOptions } from "../../../Types";

export const randomize = (options: PositionOptions, that: CoordsAndDisplace) => {
  const displacementMap = displacer( that.displacementMap, options.extend );

  return randomizeCoordinates(
    that.coordinates,
    displacementMap,
    options.x,
    options.y
  )
};
