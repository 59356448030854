import React, { useState } from "react";

type FormType = HTMLInputElement | HTMLTextAreaElement;

type ReturnControlledInput = [
  string,
  (e: React.FormEvent<FormType>) => void
];

export const useControlledInput = (): ReturnControlledInput => {
  const [ value, setValue ] = useState( '' );

  const inputValue = (e: React.FormEvent<FormType>) => {
    setValue( e.currentTarget.value )
  };

  return [value, inputValue]
};
