import TimelineMax from 'assets/utilz/timeline';
import CustomEase from '../../assets/gsap/CustomEase';
import { TimelineProps } from "react-gsap/dist/Timeline";
// Here I should import the TimelineProps from useAnimation
// But too tired right now


type Ref = HTMLElement;

interface MailAnimationRefs {
  cardRef: Ref;
  cardContentRef: Ref;
  scooterRef: Ref;
  errorRef: Ref;
}

export const animate = (refs: any) => ({
  in: (props: TimelineProps) => animateSend( refs, props ).play(),
  out: () => animateError( refs ).play(),
});

export const animateSend = (refs: MailAnimationRefs, props: TimelineProps) =>
  new TimelineMax(props)
  .add(sendLetter(refs.cardRef))
  .add(animateScooter(refs.scooterRef));

export const animateError = (props: MailAnimationRefs) => new TimelineMax()
  .add(animateErrorIn(props.cardRef, props.cardContentRef, props.errorRef))

const sendLetter = (cardRef: Ref) => new TimelineMax()
    .fromTo(cardRef, {
      x: 0,
      scale: 1,
    }, {
      delay: .2,
      duration: 0.8,
      x: 1400,
      scaleY: .8,
      scaleX: 1.2,
      ease: CustomEase.create("custom", "M0,0,C0.272,0,0.393,-0.098,0.61,-0.098,0.938,-0.098,0.996,0.6,1,1")
    });

const animateScooter = (scooterRef: Ref) => new TimelineMax()
  .set(scooterRef, {
    display: 'block'
  })
  .fromTo(scooterRef, {
    x: -400
  }, {
    duration: 2,
    ease: "none",
    x: 1400
  });

const animateErrorIn = (
  cardRef: Ref,
  cardContentRef: Ref,
  errorRef: Ref,
) => new TimelineMax()
  .fromTo(cardRef, {
    rotateY: 0
  }, {
    duration: .25,
    ease: "power2.in",
    rotateY: 90
  })
  .set(cardContentRef, {
    display: 'none'
  })
  .set(errorRef, {
    display: 'flex'
  })
  .to(cardRef, {
    duration: .25,
    ease: "power2.out",
    rotateY: 180
  });
