import { answerIn } from "./animateAnswerIn";
import { answerOut } from "./animateAnswerOut";

type Ref = HTMLElement;

export interface AnswerAnimationProps {
  answerRef: Ref;
  flashRef: Ref;
  userRef: Ref;
  backgroundRef: Ref;
  backdropRef: Ref;
  containerRef: Ref;
}

export const animateAnswer = (props: AnswerAnimationProps) => ({
  in: (onComplete: () => void) => answerIn( props, onComplete ).play(),
  out: (onComplete: () => void) => answerOut( props, onComplete ).play().delay(.3),
});
