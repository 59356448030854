import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";
import { use_mobile } from "./use_mobile";

const { next, choices } = create;

export const bibi: (FrameProps | string)[] = [
  'Ich suche eigentlich nur nach Bibi Blocksberg Hörspielen.',

  choices(Hooks.BIBI, [
    'Achso, die sind da vorne links.',
    'Darfst du denn einfach ans Handy?'
  ]),

  ...use_mobile,

  ...next(Hooks.BIBI, [
    'Okay, danke! Bitte nichts verraten!',
    {
      text: 'Marlene hat den Chat verlassen',
      target: Hooks.SINAN_TAKES_OVER_MARLENE
    }
  ])
];
