import TimelineMax from 'assets/utilz/timeline';

export const animation = (cover: any): any => {
  return {
    play: () => animateIn(cover).play().delay(.3),
  }
};

const animateIn = (cover: any) => new TimelineMax()
  .add(animateImage(cover.imageRef), 0)
  .add(animateTitle(cover.titleRef), '+.3')
  .set(cover.confettiRef, {display: 'block'}, '+.8')
  .add(animateName(cover.nameRef), '+.4')
  .add(animateEichbornLogo(cover.eichbornLogoRef), '+.5')
;

const animateImage = (image: HTMLImageElement) =>
  new TimelineMax()
  .fromTo(image, {
    x: 1400,
    rotate: 15,
    immediateRender: true,
  }, {
    duration: 1,
    x: 0,
    rotate: -15,
    ease: "back.out(1.7)"
  });

const animateTitle = (title: HTMLDivElement) =>
  new TimelineMax()
  .fromTo(title, {
    x: 1400,
    y: -100,
    immediateRender: true,
  }, {
    duration: .3,
    x: 0,
    y: 0,
    ease: "power2.out"
  });

const animateName = (name: HTMLDivElement) =>
  new TimelineMax()
    .fromTo(name, {
      x: 1400,
      immediateRender: true,
    }, {
      duration: .3,
      x: 0,
      ease: "power2.out"
    });

const animateEichbornLogo = (logo: HTMLDivElement) =>
  new TimelineMax()
    .fromTo(logo, {
      x: -1400,
      immediateRender: true,
    }, {
      duration: .3,
      x: 0,
      ease: "power2.out"
    });
