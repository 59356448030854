export const coordinates = [
  [0,236],
  [245,0],
  [347,108],
  [32,238]
];

export const displacementMap = [
  [-1, 0],
  [-1, -1],
  [2, 0],
  [1, 1],
];

export const viewBox="0 0 347 238";
