import { createSingleChoice } from "./createSingleChoice";
import { createChoices } from "./createChoices";
import { createMultipleChoices } from "./createMultipleChoices";
import { next } from "./next";
import { restart, restartLightning } from "./restart";
import { sinan, benton, norbert, bente, marlene } from "./createCharacter";
import { createExplosiveEnd } from "./createExplosiveEnd";
import { createDefaultImage } from "./createDefaultImage";
import { createEnd } from "./createEnd";
import { FrameProps } from "../../types";
import { createNorbertEnd } from "./createNorbertEnd";
import { createPlaceholder } from "./createPlaceholder";
import { createChoicesIrrelevant } from "./createChoicesIrrelevant";
import { backToBente } from "./backToBente";

const all = {
  singleChoice: createSingleChoice,
  choices: createChoices,
  multipleChoices: createMultipleChoices,
  next,
  restart,
  restartLightning,
  follow: (
    file: (FrameProps | string)[],
    break_scene?: boolean
  ) => next( '', file, break_scene ),
  bente,
  marlene,
  sinan,
  benton,
  norbert,
  defaultImage: createDefaultImage,
  end: createEnd,
  explosiveEnd: createExplosiveEnd,
  norbertEnd: createNorbertEnd,
  placeholder: createPlaceholder,
  choicesIrrelevant: createChoicesIrrelevant,
  backToBente,
};

export default all;

