import React, {Fragment} from 'react';
import styled from 'styled-components';
import { useTimeout } from "../../assets/useTimeout";
import explosion_img from '../../assets/img/events/explosion.gif';

interface Props {
  endEvent(): void;
}

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Explosion: React.FC<Props> = ({endEvent}) => {
  useTimeout(endEvent, .7);
  return (
    <Fragment>
      <Image src={ explosion_img } />
    </Fragment>
  );
};

export default Explosion
