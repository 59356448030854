import React from 'react';
import Button from "../Button";
import { useSession } from "../../session";
import { useOnce } from "../../assets/useOnce";
import { Container, Content, Understood } from "./styles";

const Warning = () => {
  const { isPortrait } = useSession();
  const [ show, hide ] = useOnce();

  if ( !isPortrait() || !show ) {
    return null
  }

  return (
    <Container>
      <Content>
        Die Seite ist noch nicht für Smartphones und Tablets optimiert.
        <br /><br />
        Wenn du es trotzdem versuchen willst dann dreh das Ding lieber.
      </Content>
      <Understood>
        <Button value="Egal, ich versuchs so" onClick={ hide } />
      </Understood>
    </Container>
  );
};

export default Warning
