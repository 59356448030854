import { createChoices } from "../../../../create/createChoices";
import { Hooks } from "../../../Hooks";
import { next } from "../../../../create/next";
import { wrong_number } from "./wrong_number";

export const i_was_hurt = [
  'Warte, das geht mir zu schnell. Ich bin noch nicht so weit.',
  'Ich wurde schon mal verletzt...',
  createChoices(
    Hooks.I_WAS_HURT,
    ['Ich habe Kuchen!', 'Du hast mich nur verarscht!']
  ),
  ...next('', wrong_number),
  ...next(Hooks.I_WAS_HURT, [
    'Der Kuchen ist eine Lüge :(',
    'Chat beendet'
  ], true)
];
