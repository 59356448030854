import { ChoiceProps } from "../types";

export const createChoices = (choices: (ChoiceProps | string)[]) => {
  return choices.map(choice => {
    if (typeof choice === 'string') {

      return {
        text: choice
      }
    }

    return choice
  })
};
