export const getText = (
  list: { text?: string }[],
  index: number,
): string => {
  for ( let i = index; i >= 0; i-- ) {
    const { text } = list[i];
    if ( text && text.length ) {
      return text;
    }
  }
  return ''
};