import create from "scene/stories/create";
import { FrameProps } from "scene/types";
import {Hooks} from "scene/stories/about/Hooks";
import { ChatImage } from "../../../../../constants/chatImages";
import { annoying_since_birth } from "./annoying_since_birth";

const { next, choices, defaultImage, restart } = create;

const beton_scene: (FrameProps | string)[] = [
  'BENTON hat den Chat betreten.',
  'ÖY! Was willst du von meiner überaus talentierten Schwester?',
  choices(Hooks.BENTON_WHAT_DO_YOU_WANT, [
    'Die hat genervt',
    'Ich hab das nicht so gemeint!'
  ]),

  'Das hat unsere Mutter auch gesagt, als sie Bente an einem verregneten Tag im November 1988 in Bad Kreuznach auf die Welt brachte.',
  choices(Hooks.BENTON_STALKING, [
    'Und wo wohnt die jetzt?',
    'Kann ich wieder mit bente reden?'
  ]),

  restart('Nagut, aber benimm dich'),

//  todo needs to go on here

  ...next(Hooks.BENTON_WHAT_DO_YOU_WANT, annoying_since_birth)
];

export const benton: (FrameProps | string)[] = [
  'Bente hat den Chat verlassen.',
  ...defaultImage(ChatImage.BENTOR, beton_scene)
];
