import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";

const { multipleChoices } = create;

export const are_you_mad: (FrameProps | string)[] = [
  "Samma, geht's noch? Ich hetz dir gleich meinen großen Bruder BENTON auf den Hals!",

  multipleChoices(
    [Hooks.GAMES_ART_AND_ANIMATION, 'Äh ich meinte, was hast du eigentlich an... der Uni studiert?!'],
    [Hooks.BENTON_TAKES_OVER, 'Ich hab keine Angst vor dem!'],
  )
];
