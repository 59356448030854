import React from "react";
import Test from "./Test";
import { useSceneStart } from "../../scene/store/useSceneStart";
import { Pages } from "../../constants/Pages";

const config = {
  origin: 'test' as Pages,
  disableGlobalScenes: false,
};

const TestPage = () => {
  useSceneStart( config );

  return <Test/>
};

export default TestPage;