import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";

const { next, choices, restart } = create;

export const what_next: (FrameProps | string)[] = [
  'Ja, sonst noch was?',

  choices(Hooks.BENTON_WHAT_NEXT, [
    'Ne, ist gut jetzt.',
    'Kann ich noch mal von vorne?'
  ]),
  restart('Ausnahmsweise.'),

  ...next(Hooks.BENTON_WHAT_NEXT, ['Okay, zieh Leine!']
    , true)
];
