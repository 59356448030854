import { Hooks } from "../../Hooks";
import create from "../../../create";
import { feedback_please } from "./feedback_please/feedback_please";

const { next, choices, restart, follow } = create;

export const sinan = [
  'Bentes überaus talentiertes, kompetenteteter und unglaublich attraktiver, bescheidener Ehemann Sinan.',
  choices(Hooks.SINAN, [
    'Beeindruckend.',
    'Hat ja nicht so gut geklappt.'
  ]),

  'Bitte wiederholen Sie Ihre Eingabe',

  choices(Hooks.TRY_AGAIN, [
    'Ich bin ein unsensibles Schwein',
    'Meine Mutter hat mich nie geliebt'
  ]),

  'Na, na. Versuchen wir es einfach noch mal in nett.',

  choices(Hooks.TRY_AGAIN_FRIENDLY, [
    'Ja bitte :(',
    'Ne, bin jetzt traurig'
  ]),

  ...follow(['Heulsuse'], true),

  ...next(Hooks.TRY_AGAIN_FRIENDLY, [
    restart('Nagut.')
  ]),

  ...next(Hooks.TRY_AGAIN, ['Das stimmt. Weg mit dir!'], true),

  ...next(Hooks.SINAN, feedback_please)

];