import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";
import { run_fast } from "./run_fast";
import { marlene_takes_over } from "./marlene/marlene_takes_over";

const { next, choices } = create;

export const marlene_knows_taekwondo: (FrameProps | string)[] = [
  'Pass auf! Meine Tochter kann Taekwondo!',

  choices(Hooks.MARLENE_KNOWS_TAEKWONDO, [
    'Okay, das ist ziemlich cool.',
    'Und ICH kann richtig schnell rennen.'
  ]),

  ...run_fast,

  ...next(Hooks.MARLENE_KNOWS_TAEKWONDO, [
    'Sie kann auch mega frech sein - Oh ein Vögelchen!',
    'Sinan hat den Chat verlassen',
    ...marlene_takes_over
  ])
];
