import React, { useEffect, useRef, useState } from 'react';
import {feedback} from "./feedback";
import styled from 'styled-components';
//@ts-ignore
import StarRatings from 'react-star-ratings';
import { Colors } from "../../../constants/colors";
import {animation} from "./animation";

interface Props {
  endEvent(): void;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Box = styled.div`
  width: 400px;
  height: 300px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TextContainer = styled.div`
  height: 80px;
  display: flex;
    justify-content: center;
    align-items: center;
`;

const Rating: React.FC<Props> = ({ endEvent }) => {
  const feedbackRef = useRef<HTMLDivElement>(null);

  const [rating, setRating] = useState(3.01);

  const changeRating = (newRating: number) => {
    setRating(newRating)
  };

  const text = feedback(rating);

  useEffect(() => {
    if (rating === 5 && feedbackRef.current) {
      animation(feedbackRef.current, endEvent).play().delay(.5)
    }
  });


  return (
    <Container ref={feedbackRef}>

      <Box>

        <StarRatings
          rating={rating}
          starRatedColor={Colors.PINK}
          starHoverColor={Colors.YELLOW}
          changeRating={changeRating}
          name='rating'
        />

        <TextContainer>
          {text}
        </TextContainer>

      </Box>
    </Container>
  );
};

export default Rating
