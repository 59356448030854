import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";
import { agree_is_there_more } from "./agree_is_there_more";
import { ChatImage } from "../../../../../constants/chatImages";

const { next, choices, norbert, follow, defaultImage } = create;


export const super_deep_voice_x: (FrameProps | string)[] = [
  'Ja *hust* wieso fragst du? (super tiefe Stimme)',
  choices(Hooks.SUPER_DEEP_VOICE, [
    'Ach ne, verguckt.',
    'Weil du so süß bist.'
  ]),

  'Äääh... iiih! Was?! Ich ruf jetzt die Keks-Polizei!',
  'Bente- ääh BENTON hat den Chat verlassen.',
  norbert('Norbert hat den Chat betreten.'),
  norbert('HÄNDE HOCH ODER ICH SCHLIESSE!'),
  ...follow([norbert('Zu spät. Norbert hat den Chat geschlossen.')]
  , true),

  ...next(Hooks.SUPER_DEEP_VOICE, agree_is_there_more)
];

export const super_deep_voice = defaultImage(ChatImage.BENTOR, super_deep_voice_x);
