import TimelineMax from 'assets/utilz/timeline';
import { CoordsAndDisplace, WiggleOptions } from "../../../Types";
import { wigglePoint } from "./wigglePoint";

export function animateWiggle(
  domNode: any,
  pointMap: CoordsAndDisplace,
  options: WiggleOptions
): TimelineMax {
  let tl = new TimelineMax( {
    // defaults: { duration: .1 },
    paused: true,
    repeat: -1
  } );
  const wiggle1 = wigglePoint( pointMap, options );
  const wiggle2 = wigglePoint( pointMap, options );
  const wiggle3 = wigglePoint( pointMap, options );
  tl
    .to( domNode.ref, .1, {
      attr: {
        points: wiggle1
      }
    } )
    .to( domNode.ref, .1, {
      attr: {
        points: wiggle2
      }
    } )
    .to( domNode.ref, .1, {
      attr: {
        points: wiggle3
      }
    } )
    .to( domNode.ref, .1, {
      attr: {
        points: pointMap.coordinates
      }
    } );

  return tl
}
