import { useHistory, useLocation } from "react-router";
import { useSession } from "../session";
import { useCallback } from "react";
import { useMenu, useSceneContext, useChatContext } from "../context";
import { Pages } from "../constants/Pages";

export const useNavigation = () => {
  const {close} = useMenu();
  const history = useHistory();
  const { pathname } = useLocation();
  const session = useSession();
  const {setPage} = useSceneContext();
  const {reset: resetChat} = useChatContext();

  const handleNavigation = useCallback((to: string) => {
    close();
    history.push( to );
    setPage(to as Pages);
    resetChat()
  }, [close, history, setPage, resetChat]);

  return (to: string) => {
    if ( to.startsWith( 'http' ) ) {
      window.open( to, '_blank' );
    } else {
      if ( pathname !== to ) {
        handleNavigation(to)
      }
    }
    session.addVisitedPage( to as Pages )
  };
};
