import { useHistory, useLocation } from "react-router";
import { usePrevious } from "./usePrevious";
import { useCallback, useEffect } from "react";
import { useMenu, useChatContext, useSceneContext } from "../context";
import { Pages } from "../constants/Pages";

export const useBrowserNavigationHandler = () => {
  const location = useLocation().pathname;
  const history = useHistory();
  const prevLocation = usePrevious(location);
  const {close} = useMenu();
  const {reset} = useChatContext();
  const {setPage} = useSceneContext();

  const handleNavigation = useCallback((to: string) => {
    close();
    setPage(to as Pages);
    reset();
  }, [close, setPage, reset]);

  useEffect(() => {
    if (location !== prevLocation && history.action === 'POP') {
      handleNavigation(location);
    }
  })
};
