import { useScene } from "../scene";
import AppText from "../constants/Dialog_Text";
import { config } from "../config";

const isEmailInvalid = (mail: string) => {
  const inValid = mail
    .trim()
    .match( /^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/ ) == null;
  return !inValid;
};


export const useValidation = () => {
  const scene = useScene();
  return (mail: string, text: string) => {
    if (config.skip_mail_validation) {
      return true
    }

    const mailValid = isEmailInvalid( mail );
    // todo increment that shows different feedback
    if ( !mailValid ) {
      scene.addScene( AppText.CONTACT_ERROR_NO_MAIL );
    }
    if ( !text.length ) {
      scene.addScene( AppText.CONTACT_ERROR_NO_TEXT );
    }
    return mailValid && text.length
  };
};
