import { SceneProps } from "../types";
import { DialogImage } from "../../constants/dialogImages";

export const understand_burger: SceneProps = {
  "id": "understand burger",
  delay: 3,
  order: 20,
  frames: [
    {
      "text": "Das mit dem Burger hast du kapiert, oder?"
    },
    {
      "image": DialogImage.BENTE_SMIRK,
      "text": "Mehr passiert hier nicht mehr"
    }
  ]
};

export default understand_burger;
