import { OnMouseOver, OnMousePossibilities } from "../Types";

export const effects = (onMouseOver: OnMouseOver) => {
  const hover = effectsOnMouseOver( onMouseOver );
  return {
    wiggle: () => hover.has('wiggle'),
    reverse: () => hover.has('reverse'),
    show: () => hover.has('show'),
  }
};

const effectsOnMouseOver = (onMouseOver: OnMouseOver) => {
  const arr: OnMousePossibilities[] = [];
  const list = arr.concat(onMouseOver);

  return new Set(list)
};
