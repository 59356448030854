import TimelineMax from 'assets/utilz/timeline';

export const animatePortfolioOpen = (refs: any) => ({
  in: () => animateIn( refs ).play(),
  out: () => animateIn( refs ).reverse(0).duration(.2),
});

const animateIn = (refs: any) => new TimelineMax({paused: true})
  .add(appsOut(refs.appsRef), 0)
  .add(imagesIn(refs.previewRef), 0);

const appsOut = (apps: HTMLElement) => {
  return new TimelineMax()
    .fromTo(apps, {
      duration: .3,
      scale: 1,
      // Could be used to animate from the app buton as origin. Mimics iPad way.
      // x: distance.x,
      // y: distance.y,
      opacity: 1
    }, {
      scale: 1.2,
      opacity: 0

    })
};

const imagesIn = (images: HTMLElement) => {
  return new TimelineMax()
    .fromTo(images, {
      duration: .3,
      scale: .25,
      // x: distance.x,
      // y: distance.y,
      opacity: 0
    }, {
      scale: 1,
      opacity: 1
    })
};