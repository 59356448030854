import styled from "styled-components";

export const MessageContainer = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
`;
