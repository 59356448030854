import React from 'react';
import { useGlobalScenes } from "./hooks/useGlobalScenes";
import Scene from "./Scene";

const SceneProvider = () => {
  useGlobalScenes();

  return <Scene />
};

export default SceneProvider
