import React, { useRef } from 'react';
import styled from 'styled-components';
import { Confetti, Image, LogoEichborn, Name, Stage, Title } from "./style";
import cover_image from "../../../assets/img/pages/home/herzschlag_ins_gesicht.png";
import eichborn_logo from "../../../assets/img/pages/home/eichborn-logo.png";
import confetti from "../../../assets/img/pages/home/particles.gif";
import { SetDomNodes } from "../../../assets/useDomNodes";
import { useComponentMount } from "../../../assets/useComponentMount";
import Dots from "../../../components/Dots/Dots";

interface Props {
  ready: boolean;
  onLoad(): void;
  setRefs: SetDomNodes
}

const Container = styled.div`
  height: 100%;
`;

const CoverDom: React.FC<Props> = (
  {
    ready,
    onLoad,
    setRefs
  }
) => {
  const nameRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const eichbornLogoRef = useRef<HTMLImageElement>(null);
  const confettiRef = useRef<HTMLImageElement>(null);

  const refs = {
    nameRef,
    titleRef,
    imageRef,
    eichbornLogoRef,
    confettiRef
  };

  useComponentMount(() => setRefs(refs));

  const style =!ready ? {display: 'none'} : {};

  return (
    <Container id='HOME_PAGE'>
      <Dots color='#fefffe' show={!ready} />
      <Stage style={style}>
        <Name ref={nameRef}>Bente Theuvsen</Name>
        <Title ref={titleRef}>Herzschlag ins Gesicht</Title>
        <Image
          ref={imageRef}
          src={ cover_image }
          onLoad={onLoad}
        />
        <LogoEichborn
          ref={eichbornLogoRef}
          src={eichborn_logo}
        />
        <Confetti
          ref={confettiRef}
          src={confetti}
        />
      </Stage>
    </Container>
  );
};

export default CoverDom
