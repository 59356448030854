import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";

const { next, choices, end } = create;

export const ouch: (FrameProps | string)[] = [
  'Oh nein! Entschuldigung!',

  choices(Hooks.OUCH, [
    'Schon okay.',
    'Das erzähl ich deinen Eltern!'
  ]),

  'Ich verpetz dich zuerst weil du ein Bödian bist!!!',
  end('Marlene hat den Chat verlassen und geht jetzt Petzen.'),

  ...next(Hooks.OUCH, [
    'Okay...ähm ich gehe besser wieder. Bitte verrats nicht meinen Eltern!',
    end('Marlene hat den Chat verlassen.')
  ])
];
