import { useEffect, useMemo, useState } from 'react';
import { animateAnswer } from "../animation/animateAnswer";
import { usePrevious } from "../../../assets/usePrevious";
import { useScene } from "../../index";
import { ReturnRefs } from "../../../assets/useDomNodes";
import { SceneType } from "../../types";

export const useAnswerVisibility = (
  answer: ReturnRefs,
) => {
  const { getCurrentScene, showAnswer } = useScene();
  const [is_answer_animated_out, setAnswerAnimatedOut] = useState(true);
  const [is_answer_animated_in, setAnswerAnimatedIn] = useState(true);
  const [ ready, setReady ] = useState( false );

  const show = useMemo( () => {
    const is_answer = showAnswer();
    const is_dialog = getCurrentScene().type === SceneType.DIALOG;
    return is_answer && is_dialog
  }, [getCurrentScene, showAnswer] );

  // @ts-ignore
  const animation = useMemo(() => animateAnswer( answer.domNodes ),
    [answer.domNodes]);

  useEffect( () => {
    const dom_ready = answer.is_ready && !ready;
    if ( dom_ready ) {
      setReady( true )
    }
  }, [ answer.is_ready, ready ] );

  const prevShow = usePrevious( show );

  const animate = useMemo(
    (() => ({
      in: () => {
        setAnswerAnimatedOut(false);
        const setIn = () => setAnswerAnimatedIn(true)
        animation.in(setIn)
      },
      out: () => {
        setAnswerAnimatedIn(false);
        const setOut = () => setAnswerAnimatedOut(true)
        animation.out(setOut)
      },
    }))
    ,[animation]
  );

  useEffect( () => {
    if ( ready ) {
      if ( show && is_answer_animated_out ) {
        animate.in()
      }
      if ( !show && prevShow && is_answer_animated_in ) {
        animate.out()
      }
    }
  }, [ animate, prevShow, ready, show, is_answer_animated_in, is_answer_animated_out ] );

};
