import React, { useMemo } from 'react';
import Dots from "../components/Dots";
import { ButtonContainer, SubmitButton } from "./ContactStyle";
import { FiniteState } from "../assets/finiteFetch";

interface Props {
  state: FiniteState;
  onSubmit(e: React.FormEvent<HTMLButtonElement>): void;
}

const SubmitForm: React.FC<Props> = ({onSubmit, state}) => {
  const Button = useMemo(() => {
    const Btn = {
      idle: (
        <SubmitButton onClick={ onSubmit } />
      ),
      loading: (
        <SubmitButton onClick={ onSubmit } empty >
          <Dots color='white' />
        </SubmitButton>
      ),
      success: (
        <SubmitButton onClick={ onSubmit } />
      ),
      error: (
        <SubmitButton onClick={ onSubmit } />
      ),
    };

    return Btn[state];
  }, [onSubmit, state]);

  return (
    <ButtonContainer>
      {Button}
    </ButtonContainer>
  );
};

export default SubmitForm
