import React, { useRef } from 'react';
import { DialogContainer as Container, Character, Image, SpeechBubbleContainer } from "./Styles";
import SpeechBubble from "../../shapes/SpeechBubble";
import { SetDomNodes } from "../../assets/useDomNodes";
import { useComponentMount } from "../../assets/useComponentMount";


interface Props {
  name?: string;
  text: string;
  onClick: () => void;
  setDomNodes: SetDomNodes;
  image: string;
}

const DialogDom: React.FC<Props> = (
  {
    onClick,
    text,
    name,
    setDomNodes,
    image
  }
) => {
  const characterRef = useRef( null );
  const speechBubbleRef = useRef( null );
  const setDom = () => setDomNodes({characterRef, speechBubbleRef})
  useComponentMount(setDom);

  return (
    <Container
      id="Scene"
      onClick={ onClick }
    >
      <Character ref={ characterRef } id="Character">
        <Image src={ image } alt="dialog image" />
      </Character>
      <SpeechBubbleContainer ref={ speechBubbleRef } id="Speech Bubble">
        <SpeechBubble name={ name }>
          { text }
        </SpeechBubble>
      </SpeechBubbleContainer>
    </Container>
  );
};

export default DialogDom
