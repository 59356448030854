import Email from "./Email";
import { config } from "../config";

const timeout = (ms: number) =>
  new Promise(resolve => setTimeout(resolve, ms));

export const submit = async (body: string) => {
  return config.send_test_mail
    //@ts-ignore
    ? await submitMock(config.send_test_mail)
    : await submitMail( body )
};

export const submitMock = async (response = 'OK', duration = 1000) => {
  await timeout( duration );
  return response;
};

export const createBody = (mail: string, text: string) => {
  return `
  Von: ${ mail }
  ${ text }
  `
};

const submitMail = async (body: string) =>
  Email.send( {
    SecureToken: process.env.REACT_APP_MAIL_KEY,
    To: process.env.REACT_APP_MAIL_TO,
    From: process.env.REACT_APP_MAIL_FROM,
    Subject: "Von deiner Website",
    Body: body
  } );
