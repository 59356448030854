import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Config, LetterType } from "./types";

interface Props extends LetterType {
  config: Config
}

type Style = {
  displace: boolean;
  rotation: number
}

const Container = styled.div<Style>`
  position: relative;
  display: flex;
  bottom: ${ props => props.displace ? '2px' : 0 };
  transform: rotate(${ props => props.rotation }deg);
  padding: 0 0.8px 0 0;
`;

const Letter: React.FC<Props> = (
  {
    letter,
    rotate,
    displace,
    config
  }
) => {
  const rotation = useMemo( () => {
    const random_prefix = Math.random() > .5 ? 1 : -1;
    return rotate ? 14 * random_prefix : 0
  }, [ rotate ] );

  return (
    <Container
      displace={ config.displace ? displace : false }
      rotation={ config.rotate ? rotation : 0 }
    >
      { letter }
    </Container>
  );
};

export default Letter
