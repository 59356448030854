import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";
import { ChatImage } from "../../../../../../../constants/chatImages";

const { end, defaultImage } = create;

export const silence_scene: (FrameProps | string)[] = [
  {
    id: Hooks.SILENCE,
    text: 'Endlich weg, die Nervensäge.'
  },
  'Hach, diese Ruhe.',
  'Hallo?',
  'Verdammt.',
  end('Sinan hat traurig den Chat verlassen.')
];

export const silence = defaultImage(
  ChatImage.SINAN,
  silence_scene
);
