import { Hooks } from "../../Hooks";
import create from "../../../create";
import { FrameProps } from "../../../../types";

const { next, choices, follow, singleChoice, restart } = create;

export const agree_is_there_more: (FrameProps | string)[] = [
  'Ja. Glaube auch. Sonst noch was?',

  choices(Hooks.AGREE_IS_THERE_MORE, [
    'Ich glaube das lief nicht so gut.',
    'Ne, ist mir zu blöd hier.'
  ]),

  ...follow([
    'DU bist mir zu blöd hier!!!',
    'Chat beendet. Du wurdest gemeldet wegen Hate Speech.'
  ], true),

  ...next(Hooks.AGREE_IS_THERE_MORE, ['Komm, wir versuchen einen Neuanfang <3']),

  singleChoice('Ja okeh voll gerne ^_^'),

  restart('Ok')
];