import { Hooks } from "../../../Hooks";
import create from "../../../../create";
import { order_please } from "../order_please/order_please";
import { i_thought_this_is_special } from "../i_feel_hate/i_thought_this_is_special";

const { next, choices } = create;

export const do_you_have_colds = [
  'Sie fragen: Hast du Gekühltes?',
  'Wir verbinden Sie mit dem Eiscafé in ihrer Nähe.',
  choices(Hooks.DO_YOU_HAVE_COLDS, [
    'Halt, nein! Ich hasse ChatBots.',
    'Warte! Oder... vielleicht doch?'
  ]),

  ...order_please,

  ...next(Hooks.DO_YOU_HAVE_COLDS, i_thought_this_is_special)
];