import React, { useRef } from 'react';
import {
  Card,
  CardContent,
  CardBackground,
  Container,
  Headline,
  Scooter,
  Stage,
  Error,
  ErrorContent
} from "./ContactStyle";
import { SetDomNodes } from "../assets/useDomNodes";
import scooter_img from '../assets/img/contact/animated-bente-roller.gif';
import Form, {Props as FormProps} from "./Form/Form";
import { useComponentMount } from "../assets/useComponentMount";
import background_img from "../assets/img/contact/contact-background.png";
import Dots from "../components/Dots/Dots";


export interface Props extends FormProps{
  setDomNodes: SetDomNodes;
  setBackgroundReady(): void;
  backgroundReady: boolean;
}

const ContactDom: React.FC<Props> = (props) => {
  const cardRef = useRef( null );
  const cardContentRef = useRef( null );
  const errorRef = useRef( null );
  const scooterRef = useRef( null );

  const { setDomNodes, ...formProps } = props;
  const setDom = () => setDomNodes( {
    cardRef,
    cardContentRef,
    scooterRef,
    errorRef,
  } );

  const {setBackgroundReady, backgroundReady} = props;

  useComponentMount(setDom);

  const hide_onload = !backgroundReady ? {display: 'none'} : {};

  return (
    <Container>
      <Dots color='#fefffe' show={!backgroundReady} />
      <Card ref={ cardRef } style={hide_onload}>

        <CardContent ref={ cardContentRef }>
          <Headline />
          <Form { ...formProps } />
        </CardContent>

        <Error ref={ errorRef }>
          <ErrorContent>
            DA IST WAS SCHIEF GELAUFEN
          </ErrorContent>
        </Error>
        <CardBackground
          src={background_img}
          onLoad={setBackgroundReady}
        />
      </Card>

      <Stage>
        <Scooter ref={ scooterRef }>
          <img src={ scooter_img } alt="Bente_Scooter" />
        </Scooter>
      </Stage>

    </Container>
  );
};

export default ContactDom
