import { Hooks } from "scene/stories/it_cellar/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";
import { technics } from "./technics";
import { design } from "./design";

const { choices, next } = create;

export const what_do_you_want_to_know: (FrameProps | string)[] = [
  'Was willst du wissen',

  choices(Hooks.DESIGN, [
    'Wer hat sich das geile Design ausgedacht?',
    'Mich interessiert die Technik'
  ]),

  ...technics,

  ...next(Hooks.DESIGN, design)
];
