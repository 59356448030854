import TimelineMax from 'assets/utilz/timeline';


export const apostropheAnimation = (ref: HTMLElement) =>
  new TimelineMax({paused: true, repeat: -1})
    .add(animateIdle(ref))
    .add(animateIdle(ref))
    .add(animateShift(ref));

const animateIdle = (ref: HTMLElement) =>
  new TimelineMax(  )
    .fromTo( ref, {
      rotate: 0,
      scale: 1,
      x: 0,
      y: 0,
    }, {
      rotate: 0,
      scale: .8,
      duration: .5,
      x: -30,
      y: 20,
    } );

const animateShift = (ref: HTMLElement) => {
  const rotate = -8;
  return new TimelineMax()
    .fromTo( ref, {
      rotate,
      scale: 1,
      x: -10,
      y: -10,
    }, {
      rotate,
      scale: .8,
      duration: .5,
      x: -40,
      y: 10,
    } );
};