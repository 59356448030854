import { createMultipleChoices } from "../../../../../create/createMultipleChoices";
import { Hooks } from "../../../../Hooks";
import { restart } from "../../../../../create/restart";
import { next } from "../../../../../create/next";

export const contact_me = [
  'Bente nimmt auch Aufträge an, wenn ihr Kalender es zulässt.',
  'Schreiben Sie einfach eine Nachricht über unser praktisches Kontaktformular ;o)',
  createMultipleChoices(
    ['', 'Danke! Jetzt bitte zurück zum Anfang.'],
    [Hooks.CONTACT_ME_GO, 'Gut, ich muss los.']
  ),
  restart('Sehr gern. Es hat mich gefreut ihnen von Hilfe gewesen zu sein zu können zu haben.'),
  ...next(Hooks.CONTACT_ME_GO, [
    'Auf Wiedersehen, Nutzer.'
  ],
    true
  )
];