import { SceneProps, SceneType } from "../../types";
import { Hooks } from "./Hooks";
import { benton } from "./bente/benton/benton";
import { placeholder } from "../create/placeholder";
import { games_art_and_animation } from "./bente/iterrogate/games_art_and_animation";
import { sinan_takes_over_marlene } from "./bente/something_beautiful/mega/sinan/marlene/sinan_takes_over_marlene/sinan_takes_over_marlene";
import { silence } from "./bente/something_beautiful/mega/sinan/silence";
import { what } from "./bente/something_beautiful/mega/what";
import { about_myself } from "./bente/about_myself";

import create from "../create";
import { chat_bot } from "./chat_bot/chat_bot";

const { next, choices, follow } = create;


export const about: SceneProps = {
  id: Hooks.START,
  order: 10,
  type: SceneType.CHAT,
  repeat: true,
  endless: true,
  frames: [
    {
      id: Hooks.START,
      text: 'tut tut',
    },

    choices(Hooks.BEGINNING, [
      'ChatBot activate?',
      'Hallo?'
    ]),

    ...follow(about_myself),

    ...next( Hooks.BEGINNING, chat_bot ),

    ...next( Hooks.PLACEHOLDER, placeholder ),
    ...next( Hooks.GAMES_ART_AND_ANIMATION, games_art_and_animation ),
    ...next( Hooks.BENTON_TAKES_OVER, benton ),
    ...next( Hooks.SINAN_TAKES_OVER_MARLENE, sinan_takes_over_marlene ),
    ...next( Hooks.SILENCE, silence ),
    ...next( Hooks.WHAT, what ),
  ]
};
