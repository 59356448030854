import styled from "styled-components";

export const FullScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0,0,0,0.7);
  z-index: 100;
`;

export const Container = styled.div`
  position: relative;
  height: calc(100vh - 40px);
  width: calc(100vh - 40px);
  padding: 30px;
  margin: 0 auto;
  display: flex;
`;

export const Image = styled.img`
  height: 80%;
  width: 80%;
  position: absolute;
  top: 10%;
  left: 10%;
  object-fit: contain;
`;

export const Indexing = styled.div`
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateY(-50%);
  color: white;
`;
