import React from 'react';
import { useSession } from "./session";
import Loading from "./components/Loading/Loading";
import { useSessionStart } from "./session/useSessionStart";
import { useCookieConsent } from "./scene/Event/AgreeDsgvo/useCookieConsent";
import { useApi } from "./portfolio/useApi";
import AppProvider from "./AppProvider";

function AppLoader() {
  useSessionStart();
  // const dsgvo_agreed = useSelector( (state: Store) => state.session.is_read && state.session.dsgvo_agreed );
  // Todo: That's a hack and shouldn't be
  const dsgvo_agreed = false;
  useCookieConsent(dsgvo_agreed);
  useApi();

  const { isReady } = useSession();
  const is_ready = isReady();

  return is_ready
    ? <AppProvider />
    : <Loading show />
}

export default AppLoader;
