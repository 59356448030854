import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";
import { book } from "./book";
import { sinan_takes_over } from "./sinan/sinan_takes_over";

const { next, choices } = create;

export const family: (FrameProps | string)[] = [
  'Oh. Ich wohne in Berlin mit meinem Mann und unserer Tochter.',

  choices(Hooks.FAMILY, [
    'Die kenne ich aus den Comics!',
    'Dein Mann ist doof.'
  ]),

  'Na toll, das hat er gehört. Au.. NICHT SCHUBSEN! Aargh-',
  'Bente hat den Chat verlassen.',
  ...sinan_takes_over,

  ...next(Hooks.FAMILY, book)
];
