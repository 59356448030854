import dialog_bente_neutral from "../assets/img/dialog/bente/neutral.png";
import dialog_bente_smirk from "../assets/img/dialog/bente/smirk.png";
import dialog_bente_nice from "../assets/img/dialog/bente/nice.png";
import dialog_bente_happy from "../assets/img/dialog/bente/happy.png";
import dialog_bente_cookie from "../assets/img/dialog/bente/cookie.png";
import dialog_user_neutral from "../assets/img/dialog/user.png";
import dialog_sinan from '../assets/img/dialog/sinan.png'

export enum DialogImage {
  BENTE = 'bente',
  BENTE_SMIRK = 'bente_smirk',
  BENTE_NICE = 'bente_nice',
  BENTE_HAPPY = 'bente_happy',
  BENTE_COOKIE = 'bente_cookie',
  USER_NEUTRAL = 'user_neutral',
  SINAN = 'sinan',
}

export interface DialogImages {
  bente: string;
  bente_smirk: string;
  bente_nice: string;
  bente_happy: string;
  bente_cookie: string;
  user_neutral: string;
  sinan: string;
}

export const dialogImages: DialogImages = {
  bente: dialog_bente_neutral,
  bente_smirk: dialog_bente_smirk,
  bente_nice: dialog_bente_nice,
  bente_happy: dialog_bente_happy,
  bente_cookie: dialog_bente_cookie,
  user_neutral: dialog_user_neutral,
  sinan: dialog_sinan,
};

export type Keys = keyof typeof dialogImages;
