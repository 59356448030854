import React, { useMemo } from 'react';
import styled from 'styled-components';
import * as textPosition from "../../../utilz/textPosition";
import Shape from "../../../Shape";
import { ShapeConstructor } from "../../..";
import PersonaText from "../../../../components/PersonaText";

interface Props {
  name: string
}

const Container = styled.div`
  position: absolute;
  top: 30px;
  left: 20px;
  width: 200px;
  transform: rotate(-15deg);
`;

const nameArrow = () => {
  return new ShapeConstructor()
    .arrow( { fill: 'white' } )
    .add()
    .grow( { x: 20, y: 20, fill: 'black', fillOpacity: .85 } )
    .randomize()
    .add()
};

const Name: React.FC<Props> = ({name}) => {
  const arrow = useMemo(nameArrow, []);

  return (
    <Container>
      <Shape
        form={ arrow }
        textPosition={ textPosition.arrow }
        wiggle
        switchDirection
        textColor='black'
      >
        <PersonaText text={name} inverted />
      </Shape>
    </Container>
  );
};

export default Name
