import React, { Fragment } from 'react';
import styled from 'styled-components';
import Dots from "../Dots/Dots";
import { Colors } from "../../constants/colors";
import { useOnce } from "../../assets/useOnce";

export const useLoading = useOnce;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

interface Props {
  show: boolean;
  color?: Colors;
}

const Loading: React.FC<Props> = (
  {
    show,
    color = Colors.WHITE,
    children
  }
) => {
  const Loader = !show ?
    null
    : (
      <Container>
        <Dots color={ color } />
      </Container>
    );
  const hide_on_loading = show ? { display: 'none' } : {};

  return (
    <Fragment>
      { Loader }
      <div style={ hide_on_loading }>
        { children }
      </div>
    </Fragment>
  )

};

export default Loading
