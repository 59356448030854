import React from 'react';
import { useToggle } from "./useToggle";
import { BaseButtons, Container, Toggle } from "./Styles";
import SceneRig from "./Scene";
import NavigationRig from "./Navigation";
import SessionRig from './Session';
import styled from "styled-components";

const GlobalToggle = styled.button`
  position: absolute;
  right: 10px;
  bottom: 10px;
  pointer-events: initial;
  font-size: 20px;
  border: none;
  width: 30px;
  height: 30px;
`;

const TestRig = () => {
  const [visible, toggle] = useToggle(true);
  const active = true;
  // const active = useSelector((state: Store) => state.test.show);

  if (!active) return null;

  return (
    <Container>
      <Toggle visible={visible}>
        <BaseButtons>

          <SceneRig/>
          <NavigationRig/>
          <SessionRig />

        </BaseButtons>
      </Toggle>

      <GlobalToggle onClick={toggle}>
        {visible ? 'x' : '-'}
      </GlobalToggle>

    </Container>
  );
};

export default TestRig
