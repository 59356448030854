import React from "react";
import Portfolio from "../../portfolio";
import { useSceneStart } from "../../scene/store/useSceneStart";
import { Pages } from "../../constants/Pages";

const config = {
  disableGlobalScenes: true,
  origin: Pages.PORTFOLIO
};

const PortfolioPage = () => {
  useSceneStart( config );

  return <Portfolio />
};

export default PortfolioPage;
