import { Hooks } from "../about/Hooks";
import { ChoiceProps } from "../../types";

type Return = {
  type: 'ANSWER',
  choices: ChoiceProps[]
}

export const createChoicesIrrelevant = (
  target: Hooks | '',
  choices: string[]
): Return => {
  const mappedChoices = choices.map(text => ({
    text,
    target
  }));

  return {
    "type": "ANSWER",
    "choices": mappedChoices
  }
};
