import TimelineMax from 'assets/utilz/timeline';
import * as displace from '../styles/displace';
import { AnimationReturn } from "../../assets/useAnimation";

export const animation = (menu: any): AnimationReturn => ({
  // @ts-ignore
  in: ({onComplete, onStart}) => animateIn(menu, onStart, onComplete).play(),
  // @ts-ignore
  out: ({onComplete, onStart}) => animateOut(menu, onStart, onComplete).play(),
});

const animateOut = (
  menu: any,
  onStart: () => void,
  onComplete: () => void,
) => new TimelineMax({paused: true, onStart, onComplete})
  .to(menu.refContainer.children, {
    duration: .5,
    x: displace.menuItemX,
    ease: "back.in(3)",
    stagger: .1
  });

const animateIn = (
  menu: any,
  onStart: () => void,
  onComplete: () => void,
) => new TimelineMax({paused: true, onStart, onComplete})
  .to(menu.refContainer.children, {
    x: 0,
    ease: "back.out(1.7)",
    stagger: .2
  });
