import React, { useEffect, useRef } from "react";
import { CoordsAndDisplace, PolygonDom } from "../Types";
import animateWiggle from "../constructor/methods/wiggle";
import { usePrevious } from "../../assets/usePrevious";
import { useTimeline } from "../../assets/useTimeline";
import { defaultWiggleOptions } from "../defaultValues";
import { useComponentMount } from "../../assets/useComponentMount";

export const Polygon: React.FC<PolygonDom> = (
  {
    fill,
    strokeLinejoin,
    fillOpacity,
    stroke,
    strokeWidth,
    points,
    wiggle = false,
    wiggleOptions,
    ...coordsAndDisplacement
  }
) => {
  const ref = useRef( null );
  const prevWiggle = usePrevious( wiggle );

  const options = {
    ...defaultWiggleOptions,
    ...wiggleOptions
  };

  const { setDomNodes, timeline } = useTimeline(
    animateWiggle,
    [coordsAndDisplacement as CoordsAndDisplace, options],
  );
  useComponentMount(() => setDomNodes({ref}));

  useEffect( () => {
    if ( wiggle ) {
      timeline.play();
    }
    if ( !wiggle && prevWiggle ) {
      timeline.pause();
    }
  }, [ wiggle, prevWiggle, timeline ] );


  return (
    <polygon
      ref={ ref }
      fill={ fill }
      strokeLinejoin={ strokeLinejoin }
      fillOpacity={ fillOpacity }
      stroke={ stroke }
      strokeWidth={ strokeWidth }
      points={ points }
    >
    </polygon>
  );
};
