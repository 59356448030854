import {norbert} from "./createCharacter";
import { FrameProps } from "../../types";
import { ChatImage } from "../../../constants/chatImages";

export const createNorbertEnd = (): FrameProps[] => ([
  norbert('Norbert hat den Chat betreten.'),
  norbert('HÄNDE HOCH ODER ICH SCHLIESSE!'),
  {
    image: ChatImage.BENTE_COOKIE,
    text: 'Zu spät. Norbert hat den Chat geschlossen.',
    target: 'break'
  }
]);
