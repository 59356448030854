import React from "react";
import Home from "./Home";
import { useSceneStart } from "../../scene/store/useSceneStart";
import { useHomeConfig } from "./useHomeConfig";

const HomePage = () => {
  const config = useHomeConfig();
  useSceneStart(config);

  return <Home />
};

export default HomePage;
