import { useScene } from "../../scene/store";

const demoScene = {
  frames: [{text: 'Hallo test'}]
};

export const useTestActions = () => {
  const scene = useScene();

  return {
    show: () => scene.show(),
    hide: () => scene.hide(),
    addScene: () => scene.addScene(demoScene),
  };
};
