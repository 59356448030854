import { ChoiceProps } from "../../types";

type Return = {
  type: 'ANSWER',
  choices: ChoiceProps[]
}

export const createMultipleChoices = (...choices: [string, string][]): Return => {
  const mappedChoices = choices.map(([target, text]) => ({
    target,
    text
  }));

  return {
    "type": "ANSWER",
    "choices": mappedChoices
  }
};
