import React from 'react';
import DialogDom from "./DialogDom";
import Answer from "./Answer";
import { useVisibilityAnimation } from "../hooks/useVisibilityAnimation";
import { animateDialog } from "./animation/animateDialog";
import { useScene } from "../index";
import { SceneType } from "../types";
import { Props } from "../Scene";

const Dialog: React.FC<Props> = (
  {
    dom,
    setDomNodes
  }
) => {
  const scene = useScene();
  useVisibilityAnimation( dom, animateDialog, SceneType.DIALOG );
  const frame = scene.getCurrentFrame();
  const image = scene.getCurrentImage();

  const progress = !scene.showAnswer()
    ? () => scene.onProgress( frame.target )
    : () => {
    };

  return (
    <React.Fragment>
      <DialogDom
        text={ frame.text || '' }
        onClick={ progress }
        setDomNodes={ setDomNodes }
        image={ image.dialog }
        name={frame.name}
      />
      <Answer />
    </React.Fragment>
  );
};

export default Dialog
