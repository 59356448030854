import { createMultipleChoices } from "../../../../../create/createMultipleChoices";
import { restart } from "../../../../../create/restart";

export const stop_it_damn = [
  'Kündige Mietvertrag der Wohnung“ wird eingeleitet.',
  createMultipleChoices(
    ['', 'STOPP!'],
    ['', 'STTOOOPPP VERDAMMT!!'],
  ),
  '„ERLAUBE ZUGRIFF AUF KREDITKARTE“',
  'UNWIDERRUFLICH EINGELEITET!!!',
  'Du bist ohnmächtig geworden',
  restart('Der Chat startet von vorne')
];
