import { useScene } from "../store";
import { useComponentMount } from "../../assets/useComponentMount";

export const useGlobalScenes = (
) => {
  const scene = useScene();
  useComponentMount(scene.initialize);

  return scene.getCurrentScene();
};
