import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";

const { next, choices, end } = create;

export const use_mobile: (FrameProps | string)[] = [
  'Ja, wenn Papa nicht guckt.',

  choices(Hooks.USE_MOBILE, [
    'Oh, ich habe ich gerade gehört!',
    'Das macht gar keinen Sinn.'
  ]),

  'DU machst gar keinen Sinn!',
  'Marlene hat den Chat verlassen.',
  'Marlene hat den Chat betreten.',
  'Blödian!',
  end('Marlene hat den Chat verlassen.'),

  ...next(Hooks.USE_MOBILE, [
    'Oh nein, schnell weg!!',
    {
      target: Hooks.SINAN_TAKES_OVER_MARLENE,
      text: 'Marlene hat den Chat verlassen.'
    }
  ])
];
