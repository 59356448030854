import React from 'react';
import styled from 'styled-components';
import { Quote } from "../../../constants/quotes";

interface Props extends Quote{
  left?: boolean
}

const Container = styled.div<{left?: boolean}>`
  color: white;
  font-family: 'Lato', sans-serif;
  width: 100%;
  transform: translate(${props => props.left ? '-130%' : '130%'}, 0px);
`;

const StyledQuote = styled.div`
  font-style: italic;
  padding-bottom: 6px;
`;

const Author = styled.div`
  text-align: center;
`;

const DesignedQuote: React.FC<Props> = (
  {
    quote,
    author,
    left
  }
) => {
  return (
    <Container left={left}>
      <StyledQuote>
        "{quote}"
      </StyledQuote>
      <Author>
        - {author} -
      </Author>
    </Container>
  );
};

export default DesignedQuote
