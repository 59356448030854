import React, { useMemo } from 'react';
import styled from 'styled-components';
import { AnswerProps } from "./Answer";
import { ShapeConstructor } from "../../../shapes";
import Shape from "../../../shapes/Shape";

const StyledChoice = styled.div`
  position: relative;
  pointer-events: initial;
  width: 200px;
  padding: 0;
`;

const ShapeContainer = styled.div`
  height: 50px;
  margin: 8px 0;
  width: 100%;
`;

const Text = styled.div`
  z-index: 2;
`;

interface Props extends AnswerProps {
  text: string;
  target?: string;
}

const Choice: React.FC<Props> = (
  {
    onClick,
    text,
    target
  }
) => {
  const click = () => onClick( target, text );
  const wiggle = useMemo(() =>
      new ShapeConstructor()
        .rectangle({fill: '#5bd7ff'})
        .randomize( { x: 20, y: 2, extend: true } )
    , []);

  return (
    <StyledChoice
      onClick={ click }
    >
      <ShapeContainer>
        <Shape
          form={wiggle}
          onMouseOver={['wiggle', 'reverse', 'show']}
          wiggleOptions={{x: 20, y: 14}}
          textColor='black'
          useClickableArea
        >
          <Text>{ text }</Text>
        </Shape>
      </ShapeContainer>
    </StyledChoice>
  );
};

export default Choice;
