import { useState } from "react";
// import { Categories, useApi } from "../api/useApi";
// import { AnimationReturn } from "../assets/useAnimation";
import { usePortfolio } from "../context";

export type Categories = CategoryType[];

export interface CategoryType {
  name: string;
  img: string;
  img_animated: string;
  id?: string;
}

// interface AppsReturn {
//   open: number;
//   setOpen(i: number): void;
//   visibleImages: string[];
//   categories: Categories;
// }

export const useApps = (
  // timeline: AnimationReturn
) => {
  const images = usePortfolio();
  const {
    setOpenCategory,
    setOpenImage,
    openSlider,
    closeSlider,
  } = images;
  const [ open, setOpen ] = useState( -1 );
  // const categories = images.categories;

  const getCurrentCategory = () =>
    images.categories.find(({name}) => name === images.openCategory);

  const getCurrentImage = () => {
    const category = getCurrentCategory();
    const index = images.openImage;

    if (!category || index === -1) {

      return ''
    }
    return category.images[index]?.filename
  }


  // useEffect( () => {
  //   const change = open !== prevOpen;
  //   const idFromIndex = (): string => {
  //     const key = Object.values( categories )[open] || {};
  //     // @ts-ignore
  //     return key.id || '';
  //   };
  //   const id = idFromIndex();
  //   // const images = id === '' ? [] : api.images( id );
  //
  //   const animateIn = () => {
  //     if ( initial ) {
  //       setInitial( false );
  //     }
  //     if ( visibleImages !== images ) {
  //       setVisibleImages( images )
  //     }
  //     if ( prevOpen === -1 ) {
  //       timeline.in()
  //     }
  //   };
  //
  //   const animateOut = () => {
  //     setOpen( -1 );
  //     timeline.out()
  //   };
  //
  //   if ( change && open !== -1 ) {
  //     animateIn()
  //   }
  //
  //   if ( change && open === -1 ) {
  //     animateOut()
  //   }
  // }, [ api, categories, initial, open, prevOpen, timeline, visibleImages ] );

  return {
    open,
    categories: images.categories,
    setOpen,
    setOpenCategory,
    setOpenImage,
    getCurrentCategory,
    getCurrentImage,
    openSlider,
    closeSlider,
    isSliderOpen: images.isSliderOpen,
    openCategory: images.openCategory,
    openImage: images.openImage,
  }

};
