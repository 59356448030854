import create from "scene/stories/create";
import { FrameProps } from "scene/types";
import { ChatImage } from "../../../../../../../../../constants/chatImages";
import { Hooks } from "../../../../../../Hooks";
import { angry } from "./angry";

const { next, choices, defaultImage, marlene, end } = create;

const sinan_takes_over_marlene_scene: (FrameProps | string)[] = [
  'Sinan hat den Chat betreten',
  'War was?',

  choices(Hooks.HEAR_BIBI, [
    'Äh. Nö.',
    'Naja... also... deine Tochter war gerade hier.'
  ]),

  ...angry,

  ...next(Hooks.HEAR_BIBI, [
    'Okay, also wo waren wir?',
    'Warte, was ist das für ein Geräusch?',
    '*koommmm zu uns, Bibi Blocksberg*',
    'Marlene? Warst du hier?',
    'MARLENE NA WARTE!',
    marlene('Waaaaahhhh'),
    end('Sinan hat den Chat verlassen.')
  ])
];

export const sinan_takes_over_marlene = defaultImage(
  ChatImage.SINAN,
  sinan_takes_over_marlene_scene
);
