import React from "react";
import ItCellar from "./ItCellar";
import { Pages } from "../../constants/Pages";
import { it_cellar } from "../../scene/stories/it_cellar/it_cellar";
import { useSceneStart } from "../../scene/store/useSceneStart";

const config = {
  origin: Pages.IT_CELLAR,
  localScenes: [ it_cellar ],
  disableGlobalScenes: true
};

export const ItCellarPage = () => {
  useSceneStart( config );

  return <ItCellar />
};

export default ItCellarPage;
