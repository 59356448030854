import { useValidation } from "./useValidation";
import { createBody, submit } from "./submit";
import { useEffect, useState } from "react";
import { FiniteState, nextState, TransitionAction } from "../assets/finiteFetch";

type Return = [
  FiniteState,
  (mail: string, text: string) => void
]

export const useSendMail = (): Return => {
  const validate = useValidation();
  const [ state, setState ] = useState<FiniteState>( 'idle' );
  const [ body, setBody ] = useState( '' );

  const send = async (mail: string, text: string) => {
    const isValid = validate( mail, text );
    if ( isValid ) {
      const body = createBody( mail, text );
      setBody( body );
      const next = nextState( state, TransitionAction.CLICK );
      setState( next )
    }
  };

  useEffect( () => {
    const sendMail = async () => {
      const response = await submit( body );
      return response === 'OK'
        ? TransitionAction.RESOLVE
        : TransitionAction.REJECT
    };

    const send = async () => {
      const response = await sendMail();
      const next = nextState( state, response );
      setState( next );
    };

    if ( state === 'loading' ) {
      send()
    }
  }, [ body, state ] );

  return [ state, send ]
};
