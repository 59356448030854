import React, { useState } from 'react';
import PortfolioDom from "./PortfolioDom";
import { animatePortfolioOpen } from "./animation/animateOpen";
import { useAnimation } from "../assets/useAnimation";
import Slider from "./slider";
import { useApps } from "./useApps";
import { useScene } from "../scene";
import { Pages } from "../constants/Pages";
import Loading, {useLoading} from "../components/Loading/Loading";
import Categories from "./Categories";
// This should be the style for desktop. Mobile should have own styles.
// Should be full screen without the frame. And a special Home Button


const tutorialHome = {
  delay: 3,
  frames: [ { text: 'Wenn du zurück willst klick einfach auf den Home Button des iPads' } ]
};


const Portfolio = () => {
  const { addScene } = useScene();
  const [ showBackTutorial, setShowBackTutorial ] = useState( true );
  const [ currentPreview, setCurrentPreview ] = useState( -1 );
  // const [ openSlider, setOpenSlider ] = useState( false );
  const {
    setDomNodes,
    // timeline
  } = useAnimation( animatePortfolioOpen );
  const [ loading, onLoad ] = useLoading();
  const {
    setOpenCategory,
    setOpenImage,
    getCurrentImage,
    getCurrentCategory,
    categories,
    openSlider,
    closeSlider,
    isSliderOpen
  } = useApps(
    // timeline
  );

  const onClickHome = () => {
    if (!isSliderOpen) {
      setCurrentPreview( -1 );
      setOpenCategory( '' )
    }
  };

  const onClickApp = (name: string) => {
    if (!isSliderOpen) {

      setOpenCategory( name )
      // setOpen(categories.map(({name}) => name).indexOf(name))
      if ( showBackTutorial ) {
        addScene( tutorialHome, Pages.PORTFOLIO );
        setShowBackTutorial( false );
      }
    }
  };

  const setPreview = (index: number) => {
    if ( index !== currentPreview ) {
      setCurrentPreview( index );
      openSlider();
      setOpenImage(index)
    }
  };

  // const onOpenSlider = () => {
  //   openSlider()
  // };

  // const onCloseSlider = () => {
  //   setOpenSlider( false )
  // };

  const category = getCurrentCategory()?.name || '';
  const image = getCurrentImage();

  return (
    <div>
      <Loading show={ !categories.length || loading }>
        <PortfolioDom
          onClickHome={ onClickHome }
          category={ category }
          setDomNodes={ setDomNodes }
          onClickImage={ setPreview }
          onLoad={ onLoad }
        >
          <Categories
            onClickApp={onClickApp}
          />
        </PortfolioDom>
        <Slider
          image={image}
          show={ isSliderOpen }
          onClose={ closeSlider }
          fullScreen
        />
      </Loading>
    </div>
  );
};

export default Portfolio
