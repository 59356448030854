import { PolygonState } from "../../Types";

export const toRender = (
  children: Array<PolygonState>,
  state: PolygonState
): Array<PolygonState> => {
  if ( children.length ) {
    return children
  }
  return [ state ]
};