import { Hooks } from "../../../Hooks";
import create from "../../../../create";

const { next } = create;

export const cold_dog = [
  ...next(Hooks.COLD_DOG, [
    'Alles klar. Vierzehn mal Kalter Hund.',
    'Die Bestellung wird in Kürze bei ihnen eintreffen. Das macht 76,45€',
    '*leite weiter zur Zahlungsseite*',
    '*Seite funktioniert nicht*',
    'Entschuldigung. Die Seite scheint nicht zu funktionieren.',
    'Kaufen Sie doch stattdessen in Bentes Etsyshop! Auf Wiedersehen.',
    'BenteBot hat den Chat verlassen und wartet im Shop auf dich ;o)'
  ],
    true )
];