export const coordinates = [
  [0,153],
  [129,700],
  [912,507],
  [850,250],
  [891,199],
  [946,224],
  [988,174],
  [937,188],
  [877,136],
  [850,180],
  [789,0],
];

export const displacementMap = [
  [-3,-1],
  [-2,1],
  [1,1],
  [1,1],
  [1,1],
  [0,1],
  [1,-.3],
  [0,-1],
  [0,-1],
  [1,-1],
  [1,-1],
];

export const viewBox="0 0 988 700";
