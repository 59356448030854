import { calcInverts } from "./create/calcInverts";
import { calcInvertPositions } from "./create/calcInvertPositions";
import { LetterType } from "./types";
import { useMemo } from "react";

export const usePersonaTextStyle = (text: string): LetterType[][] =>
  useMemo(() => createTemplate(text),
    [text]
  );

const createTemplate = (text: string) => {
  const inverts = calcInverts(text);

  return inverts.map(({word, possible_inverts, certain_inverts}) =>
    calcInvertPositions(word, possible_inverts, certain_inverts))
};
