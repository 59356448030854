import { useEffect } from "react";
import { Message, Status } from "./store/types";
import { useScene } from "../index";
import { useChat } from "./store/useChat";
import { SequenceState } from "../store/actions/types";

export const useChatEvents = () => {

  const {
    getCurrentFrame,
    status: sceneStatus,
    finish,
    is_animated_out,
  } = useScene();
  const {
    addMessage,
    answer,
    messages,
    status,
    reset,
    awaitAnswer,
  } = useChat();
  // const answer = useSelector( (state: Store) => state.chat.answer );
  // const hasMessages = useSelector( (state: Store) => !!state.chat.messages.length );
  // const isReady = useSelector( (state: Store) => state.chat.status === Status.READY );
  // const start = useSelector( (state: Store) => state.sequence.status === SequenceState.START );
  // const finish = useSelector( (state: Store) => state.sequence.finish );
  // const is_animated_out = useSelector( (state: Store) => state.sequence.is_animated_out );
  const hasMessages = !!messages.length;
  const isReady = status === Status.READY;
  const start = sceneStatus === SequenceState.START;
  const isAnswer = messages.length && messages[messages.length - 1].type === 'ANSWER';

  useEffect( () => {
    // Add Initial Message
    const current = getCurrentFrame();
    const isInitial = current.text && !hasMessages && start;

    if ( isInitial ) {
      addMessage( current as Message )
    }
  }, [addMessage, getCurrentFrame, hasMessages, start] );

  useEffect(() => {
    if (isReady && answer === '' && isAnswer ) {
      awaitAnswer();
    }
  }, [answer, awaitAnswer, isAnswer, isReady]);

  useEffect(() => {
    if (finish && is_animated_out) {
      reset();
    }
  })

};
