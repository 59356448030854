import { Hooks } from "scene/stories/it_cellar/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";

const { choices, end } = create;

export const design: (FrameProps | string)[] = [
  'Eigentlich stammt das Design von Persona5.',
  'Bente und ich sind große Fans und ich hatte Lust das fürs Web nachzubauen.',

  choices(Hooks.MUCH_WORK, [
    'War bestimmt ne Menge Arbeit',
    'Ist das nicht geklaut'
  ]),

  'Oh, ich bekomme gerade einen Anruf.',
  end('Hau rein ich muss los.')
];
