import React from 'react';
import { SceneInterface, SceneType } from "./types";
import Dialog from "./Dialog";
import Chat from "./Chat";
import { useScene } from "./store";
import { useLocation } from "react-router";
import { Pages } from "../constants/Pages";
import { useComponentMount } from "../assets/useComponentMount";
import { ReturnRefs, SetDomNodes, useDomNodes } from "../assets/useDomNodes";
import { useSceneEvents } from "./hooks/useSceneEvents";
import { useFrameEvents } from "./hooks/useFrameEvents";
import Event from "./Event";

export interface Props {
  hide: boolean;
  dom: ReturnRefs;
  setDomNodes: SetDomNodes;
}

const getValue = (getCurrentScene: () => SceneInterface, key: keyof SceneInterface) => {
  const scene = getCurrentScene() || {};
  return scene[key];
};

const Scene = () => {
  const {
    getCurrentScene,
    setPage,
    is_animated_in,
  } = useScene();

  const location = useLocation();
  useComponentMount( () => setPage( location.pathname as Pages ) );
  useSceneEvents();
  useFrameEvents();
  const [ domDialog, setDomDialog ] = useDomNodes();
  const [ domChat, setDomChat ] = useDomNodes();
  const currentType = getValue( getCurrentScene, 'type' );

  return (
    <div style={ is_animated_in ? {} : { pointerEvents: 'none' } }>
      <Dialog
        dom={ domDialog }
        setDomNodes={ setDomDialog }
        hide={ currentType !== SceneType.DIALOG }
      />
      <Chat
        dom={ domChat }
        setDomNodes={ setDomChat }
        hide={ currentType !== SceneType.CHAT }
      />
      <Event/>
    </div>
  )
};

export default Scene
