import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
`;

interface Button {
  text: string;

  onClick(): void;
}

interface Props {
  buttons: Button[];
}

const Button: React.FC<Button> = (
  {
    text,
    onClick
  }
) => (
  <button onClick={ onClick }>
    {text}
  </button>
);

const TestUi: React.FC<Props> = ({ buttons }) => (
  <Container>
    { buttons.map( ({ text, onClick }, i) => (
      <Button
        key={ i }
        text={ text }
        onClick={ onClick }
      />
    ) ) }
  </Container>
);

export default TestUi
