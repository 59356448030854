import React, { useMemo } from 'react';
import { MailContainerInput, MailInput } from "../ContactStyle";
import { Shape, ShapeConstructor } from "../../shapes";

export interface Props {
  mail: string;
  onChange(e: React.FormEvent<HTMLInputElement>): void;
}

const inputShape = () => new ShapeConstructor()
  .rectangle( { fill: 'white' } )
  .randomize( { x: 2, y: 2, extend: true } )
  .add()
  .randomize( { fill: 'black', x: 4, y: 7 } )
  .add();

const InputMail: React.FC<Props> = ({mail, onChange}) => {
  const ShapeInput = useMemo( () => (
    <Shape
      form={ inputShape() }
    />
  ), [] );

  return (
    <MailContainerInput>
      {ShapeInput}
      <MailInput
        onChange={ onChange }
        value={ mail }
        placeholder='E-Mail'
      />
    </MailContainerInput>
  );
};

export default InputMail
