import React, { Fragment } from 'react';
import styled from 'styled-components';
import { useSession } from "../../../session";
import TestUi from "./TestUi";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  //background-color: #f3f3f3;
`;

const Box = styled.div`
  display: flex;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 8px 50px #1e18711f;
`;

const Entry = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
`;

const SingleState = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const Key = styled.div`
  min-width: 130px;
  text-align: left;
`;

const Headline = styled.div`
  width: 100%;
  text-align: left;
  font-weight: bold;
  margin-bottom: 10px;
`;

const User = () => {
  const actions = useSession();

  return (
    <Fragment>
      <Container>
        <Box>
          <Entry>
            <Headline>
              State
            </Headline>
          </Entry>
          <Entry>
            <Headline>
              Actions
            </Headline>
            { Object.keys( actions ).map( (key, i) => (
              <SingleState key={ i }>
                <Key>
                  { key }()
                </Key>
              </SingleState>
            ) ) }
          </Entry>
        </Box>
        <TestUi />
      </Container>

    </Fragment>
  );
};

export default User
