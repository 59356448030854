import { CoordsAndDisplace, Position } from "../../../Types";
import { growShape } from "./growShape";

export const grow = (
  options: Position,
  that: CoordsAndDisplace
) =>
  growShape(
    options.x,
    options.y,
    that.coordinates,
    that.displacementMap,
  );

