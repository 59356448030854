import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";
import { say_what } from "./say_what";
import { fight } from "./fight";

const { next, choices } = create;

export const exhibition: (FrameProps | string)[] = [
  'Und das eine Mal, wo ich eine eigene Ausstellung in der Caricatura Kassel hatte!',

  choices(Hooks.EXHIBITION, [
    'Wahnsinn.',
    'Sach bloß!'
  ]),

  ...say_what,

  ...next(Hooks.EXHIBITION, fight)
];
