import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";

const { next, choices, end, restartLightning } = create;

export const totally_mean: (FrameProps | string)[] = [
  'Oh man voll fies!',

  choices(Hooks.TOTALLY_MEAN, [
    'Oh man voll fies!',
    'Man voll fies!'
  ]),

  'HAHA AUCH NOCH FALSCH!',
  'Oh nein, mein Papa hat mich gehört!!!',
  end('Marlene hat das Handy fallen lassen und zerstört.'),

  ...next(Hooks.TOTALLY_MEAN, [
    'BLÖDIAN!',
    restartLightning(
      'Marlene hat dir das Handy an den Kopf geworfen. Du bist ohnmächtig geworden.'
    ),
  ])
];
