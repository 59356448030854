import styled from "styled-components";
import * as displace from './displace';

export const Container = styled.div`
  position:relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-right: 34%;
  height: 100%;
  text-align: right;
  font-family: "Titillium Web", sans-serif;
  font-weight: bold;
  color: white;
  font-size: 2.4rem;
  text-transform: uppercase;
  text-shadow: 6px 6px 0px black, -6px -6px 0px black, -6px 6px 0px black, 6px -6px 0px black, 6px 0px 0px black, -6px 0px 0px black, 0px -6px 0px black, 0px 6px 0px black;
  z-index: 10000;
`;

export const Name = styled.div`
  position: relative;
  z-index: 100;
  color: white;
  line-height: 2.2rem;
  margin-bottom: 40px;
  max-width: 240px;
  pointer-events: none;
  text-decoration: none;
  top: -46px;
`;

export const ButtonContainer = styled.div<{displace: number}>`
  position: relative;
  left: ${props => props.displace}px;
  height: 60px;
  margin-bottom: 15px;
  transform: translateX(${displace.menuItemX});
`;
