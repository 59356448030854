import { ReturnRefs, useDomNodes } from "./useDomNodes";
import { useEffect, useRef } from "react";
import TimelineMax from 'assets/utilz/timeline';
import { usePrevious } from "./usePrevious";

type Animation = (nodes: ReturnRefs["domNodes"], ...props: Props) => TimelineMax;
type Props = any[];

export const useTimeline = (animation: Animation, props: Props = []) => {
  const [ nodes, setDomNodes ] = useDomNodes();
  const was_ready = usePrevious(nodes.is_ready) || false;
  const tl = useRef<TimelineMax>(new TimelineMax());

  useEffect(() => {
    if (nodes.is_ready && !was_ready) {
      tl.current = animation(nodes.domNodes, ...props);
    }
  }, [animation, nodes.domNodes, nodes.is_ready, props, was_ready]);


  return {
    setDomNodes,
    timeline: tl.current
  }
};
