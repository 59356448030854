import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";
import { hihi_true } from "./hihi_true";

const { next, choices, end } = create;

export const start_repeating: (FrameProps | string)[] = [
  'Das ist auch richtig so.',

  choices(Hooks.START_REPEATING, [
    'Machst du mich gerade nach?',
    'Du bist ja witzig.'
  ]),

  ...hihi_true,

  ...next(Hooks.START_REPEATING, [
    'Machst du mich gerade naaaach?',
    'Du hast fluchtartig den Chat verlassen.',
    'Hallo?',
    '...',
    'Haaaallooo?',
    'Oh manno, keiner spielt mit mir : (',
    end('Marlene hat traurig den Chat beendet')
  ])
];
