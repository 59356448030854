import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";

const { end, choices } = create;

export const say_what: (FrameProps | string)[] = [
  'Bloß.',

  choices(Hooks.WHAT, [
    'Was',
    'Der war echt lahm.'
  ]),

  'Scheiße, muss los!',
  end()
];
