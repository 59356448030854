import {createContainer} from "unstated-next";
import {useState} from "react";

const useMenuBase = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [ready, setReady] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  const toggle = () => setIsOpen(!isOpen);
  const onSetReady = () => setReady(true);
  const disable = () => setIsDisabled(true);
  const enable = () => setIsDisabled(false);

  return {
    isOpen,
    ready,
    isDisabled,
    open,
    close,
    toggle,
    setReady: onSetReady,
    disable,
    enable,
  };
}

const useMenuContainer = createContainer(useMenuBase);
export const useMenu = useMenuContainer.useContainer;
export const MenuProvider = useMenuContainer.Provider;
