import React from "react";
import styled from "styled-components";

type Point = {
  x: number;
  y: number;
}

interface Props {
  points: Point[];
}

const Container = styled.div`
  height: 100%;
  transition: all .8s ease-out;
  overflow: hidden;
`;

const createPath = (points: Point[]) => {
  return points.map(({x, y}, i) => {
    const prefix = i === 0 ? 'M' : 'L';
    return `${prefix}${Math.round(x)} ${Math.round(y)}`
  })
    .join(' ')
};

const SVGPath: React.FC<Props> = (
  {
    points,
  }
) => {
  const d = createPath([{x:500, y:-500}, ...points]);

  return (
    <Container style={{maxHeight: points.length >= 2 ? '800px' : 0}}>
      <svg style={{overflow: "visible", width: '100%'}}>
        <path
          strokeWidth="25"
          stroke="black"
          strokeLinejoin="miter"
          fill="none"
          d={d}>
        </path>
      </svg>
    </Container>
  )
};

export default SVGPath;
