import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";
import { met_tic_tac_toe } from "./something_beautiful/mega/met_tic_tac_toe";
import { what_do_you_want_to_know } from "./iterrogate/what_do_you_want_to_know";

const { next, choices } = create;

export const about_myself: (FrameProps | string)[] = [
  "Hey, hier ist Bente. Bente Theuvsen. Was gibt's?",

  choices(Hooks.ABOUT_MYSELF, [
    'Ich wollte dich mal ausfragen.',
    'Erzähl mir was schönes.'
  ]),

  ...met_tic_tac_toe,

  ...next(Hooks.ABOUT_MYSELF, what_do_you_want_to_know)
];
