import { useEffect } from "react";

export const useComponentMount = (
  func: () => void | undefined,
  cleanUp?: () => void | undefined,
) =>
  useEffect(() => {
    func();
    if (cleanUp) {
      return cleanUp()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
