import { Hooks } from "../../../Hooks";
import create from "../../../../create";

const { choices, follow } = create;

export const not_this_tone = [
  'Aber nicht in diesem Ton >:(',

  choices(Hooks.COLD_DOG, [
    'Okay, drei mal Schoko BITTE.',
    'Klappe und los jetzt!'
  ]),

  ...follow([
    '*beepboop* Arschloch detected. Initiate Defense Mode.',
    '<schwiegermutteranrufen=initiate>',
    '*tut tut*',
    '(Mit verstellter stimme) NUTZER! HIER SPRICHT DEINE SCHWIEGERMUTTER.',
    '(Mit verstellter stimme) ICH BIN SEHR ENTTÄUSCHT VON DIR!',
    '(Mit verstellter stimme) DU WARST IMMER SCHON EIN NICHTSNUTZ.',
    'klick tut tut'
  ],
  true)
];