import TimelineMax from 'assets/utilz/timeline';

export const points1 = '170 95 974 0 1040 350 230 281 215 301 144 248 127 269 0 172 96 191 92 146 176 184';
export const d1 = 'm966.7 10.1-786.8 93.3 8.2 98.2-85.5-40.5 5.3 43.2-73.9-17.5 91.2 67.7 17-20.5 70.5 55 13.7-16 798.4 63.6-58.1-326.5zm-746.2 249.7-9.8 13.6-70-49.9-16.8 21.2-64.1-46.1 55.2 13.8-2-36.8 81.8 35.4-5.3-99.7 765.1-84.6 65.8 305.7-799.9-72.6z';
const points2 = '165.7 90.7 974 0 1040 350 230.2 283.8 214.7 304 143.7 244.8 123.2 271 8.7 171.3 104 199 96.3 152 184.7 196.3';
const d2 = 'm966.7 10.1-792 89.2 16 105.7-88.1-43.9 8.1 45.6-73-20.7 84.5 76.2 20.5-25.7 70.8 58 15-16.7 796.3 58.8-58.1-326.5zm-740.2 262.2-14.8 12.2-70.2-55.3-20.7 25.3-62.2-58 59.7 19.8-9-44.7 87.7 42.4-13.3-107 771-80.2 65.8 305.7-794-60.2z';
const points3 = '179,98.2 974,0 1040,350 227.8,276.8 213.5,295.8 144.5,251.8 129,273 5.8,176.4 90,186 88.5,141.5 173.5,180.2';
const d3 = 'm966.7 10.1-781.8 95.9-5.2 83.5-81-34 1 38.5-74.5-9.5 102.5 80.2 16.2-24 67.8 41.5 12.9-17.2 800.1 71.6-58-326.5zm-744.7 245.9-11.2 14.8-67-36.8-18.5 21.2-77-61.8 59.4 8.5 0.1-34.8 77.9 32.8 4.8-88.5 764.2-84.7 65.8 305.7-798.5-76.4z';

const duration = .15;

export const shape = (domNode: any) =>
  new TimelineMax({defaults: {duration}})
    .to(domNode, {
      attr:{
        points: points2
      }
    })
    .to(domNode, {
      attr:{
        points: points3
      }
    })
    .to(domNode, {
      attr:{
        points: points1
      }
    });

export const path = (domNode: any) =>
  new TimelineMax({defaults: {duration}})
    .to(domNode, {
      attr:{
        d: d2
      }
    })
    .to(domNode, {
      attr:{
        d: d3
      }
    })
    .to(domNode, {
      attr:{
        d: d1
      }
    });
