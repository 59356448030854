import React, { useEffect, useRef } from 'react';
import { Container } from "../styles/MenuItemsStyle";
import Item from "./Item";
import { SetDomNodes } from "../../assets/useDomNodes";
import { MenuProps } from "../Menu";

interface Props extends MenuProps {
  setDomNodes: SetDomNodes;
}

const MenuItems: React.FC<Props> = ({setDomNodes, links}) => {

  const refContainer = useRef( null );

  useEffect(() => {
    if (refContainer.current) {
      setDomNodes({refContainer})
    }
  }, [setDomNodes]);

  return (
    <Container ref={ refContainer }>
      {links.map((link, i) => (
        <Item key={i} index={i} {...link} />
      ))}
    </Container>
  );
};

export default MenuItems
