import Shape from "./Shape";
import Template from "./Template";
import SpeechBubble from "./SpeechBubble";
import { ShapeConstructor } from "./constructor/ShapeConstructor";

export {
  Template,
  ShapeConstructor,
  Shape,
  SpeechBubble
};

export default Template;
