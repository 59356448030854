import React from 'react';
import styled from 'styled-components';
import HeadlineText from "./HeadlineText";
import HeadlineBackground from "./HeadlineBackground";

interface Props {
  text: string;
}

const Container = styled.div`
  position:relative;
  display: flex;
  font-family: "Titillium Web", sans-serif;
  font-size: 16pt;
`;

const StyledTextContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 7px;
  color: white;
  top: 0;
  left: 0;
`;


const Headline: React.FC<Props> = ({ text }) => {

  return (
    <Container>
      <HeadlineBackground text={text} />
      <StyledTextContainer>
        <HeadlineText text={text} />
      </StyledTextContainer>
    </Container>
  );
};

export default Headline
