import React from "react";
import { useDomNodes } from "../../../assets/useDomNodes";
import DomAnswer from "./DomAnswer";
import { useAnswerVisibility } from "./useAnswerVisibility";


const Answer = () => {
  const [ domAnswer, setDomAnswer ] = useDomNodes();
  useAnswerVisibility( domAnswer );

  return (
    <React.Fragment>
      <DomAnswer setDomNodes={ setDomAnswer } />
    </React.Fragment>
  )
};

export default Answer;
