import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import SVG from "../SVG";
import TimelineMax from 'assets/utilz/timeline';
import { emtpyTimeline } from "../utilz/emptyTimeline";
import * as animate from '../animation/speechBubbleAnimation';
import Name from "./Name";

interface Props {
  name?: string;
}

const Container = styled.div`
  position: relative;
`;

const Text = styled.div`
  position: absolute;
  top: 37%;
  left: 23%;
  width: 58%;
  height: 30%;
  color: white;
  text-align: left;
  font-size: 18px;
`;

const SpeechBubble: React.FC<Props> = (
  {
    name = 'Bente',
    children
  }
) => {
  const timeline = useRef( emtpyTimeline );
  const polygonRef = useRef( null );
  const pathRef = useRef( null );

  useEffect( () => {
    timeline.current = new TimelineMax( {
      repeat: -1,
      defaults: { duration: .15 },
    } );
  }, [] );

  useEffect( () => {
    timeline.current
      .add( animate.shape( polygonRef.current ), 0 )
      .add( animate.path( pathRef.current ), 0 )
  }, [] );

  return (
    <Container>
      <SVG>
        <polygon ref={ polygonRef } points={ animate.points1 } />
        <path ref={ pathRef } fill='white' d={ animate.d1 } />
      </SVG>
      <Name name={name} />
      <Text>
        { children }
      </Text>
    </Container>
  )
};

export default SpeechBubble;
