import { LetterType } from "../types";
import { randomFromLength } from "./randomFromLength";

export const calcCertainInverts = (
  word: LetterType[],
  certain_inverts: number,
) => {
  const current_inverts = word.filter( ({ invert }) => invert ).length;
  if ( current_inverts >= certain_inverts ) {
    return word
  }

  return createCertainInverts( word, certain_inverts )
};

const createCertainInverts = (
  word: LetterType[],
  certain_inverts: number,
) => {
  if ( certain_inverts > word.length / 2 ) {
    console.log( `Impossible to create ${ certain_inverts } inverts in a ${ word.length } letter word` );
    return word
  }

  const inverts = createIvertingNumbers( word, certain_inverts );

  return mapInverts( word, inverts )
};

const mapInverts = (
  word: LetterType[],
  inverts: number[],
) => {
  const set = new Set( inverts );
  return word.map( (letter, i) => {
    if ( set.has( i ) ) {

      return {
        ...letter,
        invert: true
      }
    }

    return letter
  } );
};

const createIvertingNumbers = (
  word: LetterType[],
  certain_inverts: number,
) => {
  let numbers = word.map( (a, i) => i );
  let to_invert = [];

  for ( let i = 0; i < certain_inverts; i++ ) {
    if ( numbers.length === 1 ) {
      to_invert.push( numbers[0] );
    } else {
      const rand = randomFromLength( numbers );
      const start = rand - 1 >= 0 ? rand - 1 : 0;
      const deleteCount = 3;
      to_invert.push( numbers[rand] );
      numbers.splice( start, deleteCount );
    }
  }

  return to_invert
};
