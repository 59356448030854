import axios from 'axios';
import { Categories, Category } from "./types";
import { useComponentMount } from "../assets/useComponentMount";
import { usePortfolio } from "../context";

export const base = process.env.REACT_APP_BASE_URL;
export const categoriesUrl = base + 'categories/';
export const imagesUrl = base + 'images/';
const wakeUpCall = base + 'warmup/';

// type Category = {
//   icon?: string;
//   iconOmo?: string;
//   images: Image[];
//   name: string;
// }

type Image = {
  filename: string;
  title: string;
  categories?: string[];
  targetSize?: number;
}

type Return = {
  warmup(): void;
  isLoading: boolean;
  categories: Categories;
  getCategories(): void;
  getImages(category?: string): Image[];
}

let once = true;

export const useApi = (): Return => {
  const {
    categories,
    isLoading,
    openCategory,
    fetchImages,
    storeImages: storeImagesMethod,
  } = usePortfolio();
  // } = useSelector( (state: Store) => state.images );

  // Ich schätze weil ich hier den string erst zusammen setze wird er nicht
  // richtig gecached.


  const mapImages = (images: Image[]) =>
    images.map(image => ({
      ...image,
      filename: imagesUrl + image.filename
    }))

  const mapCategories = (categories: Categories) =>
      categories.map(category => ({
        ...category,
        icon: imagesUrl + category.icon,
        iconOmo: imagesUrl + category.iconOmo,
        images: mapImages(category.images)
      }))

  // const mappedCategories: Categories = useMemo(() =>
  //     // @ts-ignore
  //     mapCategories(categories)
  // , [categories, mapCategories]);

  const getCategories = () => {
    if (!categories.length && !isLoading && once) {
      once = false;
      // dispatch({
      //   type: 'FETCH_IMAGES'
      // })
      fetchImages();
      fetchCategories();
    }
  }

  const fetchCategories = () => {
    axios.get( categoriesUrl )
      .then(resp => {
        storeImages(resp.data)
      })
      .catch(() => console.log('Categories could not be fetched'))
  }

  const storeImages = (categories: Categories) => {
    storeImagesMethod(mapCategories(categories));
    // dispatch({
    //   type: 'STORE_IMAGES',
    //   images: mapCategories(categories)
    // })
  }

  const warmup = () => {
    axios.get(wakeUpCall)
  }

  const getImages = (category = openCategory): Image[] => {
    if (!category.length || !categories.length) {
    // @ts-ignore
      return []
    }

    const self = categories.find(({name}) => name === category) as Category;

    return self.images
  }

  useComponentMount(getCategories)


  return {
    getImages,
    warmup,
    isLoading,
    // @ts-ignore
    categories,
    getCategories //: fetchCategories
  }
}