import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";

const { next, choices, end } = create;

export const distracted_me: (FrameProps | string)[] = [
  'Man jetzt hast du mich voll rausgebracht, du Lump.',

  choices(Hooks.DISTRACTED_ME, [
    'Okay, nagut, ich renne.',
    'MUUUAAHAHAHA!!!'
  ]),

  'Hilfe, ein Wahnsinniger!',
  end('Sinan hat dich geblockt.'),

  ...next(Hooks.DISTRACTED_ME, [
    {
      target: Hooks.SILENCE,
      text:'JUHU! Dann los!'
    }
  ])
];
