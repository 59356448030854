import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  pointer-events: none;
  z-index: 10000;
`;

export const BaseButtons = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  pointer-events: initial;
  display: flex;
  flex-direction: column;
`;

export const Toggle = styled.div<{ visible: boolean }>`
  display: ${ props => props.visible ? 'flex' : 'none' };
  margin-left: 10px;
`;

export const ButtonList = styled.div`
  display: flex;
  margin-bottom: 4px;
`;

export const Button = styled.button<{ active?: boolean }>`
  min-width: 90px;
  padding: 4px 14px;
  border: none;
  border-radius: 3px;
  background-color: ${ props => props.active ? 'green' : 'lightgray' };
  margin-right: 2px;
`;
