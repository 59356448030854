import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";
import { keep_secret } from "./secret/keep_secret";
import { start_repeating } from "./repeat/start_repeating";

const { next, choices } = create;

export const not_allowed_to_chat: (FrameProps | string)[] = [
  'Ich äääh... also... ich darf eigentlich gar nicht ins internet.',

  choices(Hooks.NOT_ALLOWED_TO_CHAT,  [
    'Das ist auch richtig so.',
    'Ich verrate nichts!'
  ]),

  ...keep_secret,

  ...next(Hooks.NOT_ALLOWED_TO_CHAT, start_repeating)
];
