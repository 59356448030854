import styled from "styled-components";

export const Container = styled.button`
  position: relative;
  cursor: pointer;
  display: inline-flex;
  height: 40px;
  background-color: black;
  box-shadow: 7px 3px 0 2px black;
  border: none;
  &:active {
    top: 2px;
    box-shadow: 3px 1px 0 2px black;
  }
  z-index: 100;
`;

export const ShapeContainer = styled.div`
  position:absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

export const TextContainer = styled.div`
  position:relative;
  display: flex;
  color: white;
  width: 100%;
  height: 100%;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  font-family: "Titillium Web", sans-serif;
  font-size: 16pt;
  z-index: 2;
`;