import { useComponentMount } from "./useComponentMount";
import { useSession } from "../session";

export const useScreenSizeListener = () => {
  const {setScreenSize} = useSession();

  useComponentMount(() => {
    setScreenSize(window.innerWidth, window.innerHeight)
  });

  useComponentMount(() => {
    window.addEventListener("resize", function() {
      setScreenSize(window.innerWidth, window.innerHeight);
    }, false)
  });
};
