import { CurrentImage, SequenceState } from "./actions/types";
import { Pages } from "../../constants/Pages";
import { CreateScene, StartSceneProps } from "../types";
import { useCallback } from "react";
import { getImage } from "./actions/helper/getImage";
import { addScene as addSceneMethod } from "./actions/addScene";
import { useLocation } from "react-router";
import { useSceneGetter } from "../hooks/useSceneGetter";
import { getNextScene } from "./actions/helper/getNextScene";
import { useOnProgress } from "../hooks/useProgress";
import { ChatImage } from "../../constants/chatImages";
import { DialogImage } from "../../constants/dialogImages";
import { useSceneContext } from "../../context";
import { useSession } from "../../session";

export const useScene = () => {
  const sequence = useSceneContext();
  const {
    setPageConfig,
    addScene: addSceneToHandler,
    show,
    onShow,
    status,
    scenes,
  } = sequence;
  const location = useLocation();
  const session = useSession();
  const isReady = status === SequenceState.INITIALIZED;
  const { currentScene, currentFrame, getNextFrame } = useSceneGetter();
  const onProgress: (target?: string) => void = useOnProgress();

  const start = (config: StartSceneProps): void => {
    if ( isReady ) {
      console.error( 'set Scenes is not handled yet' )
    } else {
      setPageConfig( config );
    }
  };

  const getNewScenes = useCallback( (
    scene: CreateScene | CreateScene[],
    origin: Pages | 'GLOBAL' = location.pathname as Pages
  ) => {
    const newScenes = addSceneMethod( scenes, scene, origin );
    return {
      ...sequence,
      scenes: newScenes
    };
  }, [ location.pathname, scenes, sequence ] );

  const addScene = (
    scene: CreateScene | CreateScene[],
    origin?: Pages | 'GLOBAL'
  ) => {
    const next = getNewScenes( scene, origin );
    const newCurrent = getNextScene( next, session );
    //@ts-ignore
    addSceneToHandler( next.scenes, newCurrent );
  };

  const getCurrentScene = () => currentScene;

  const getCurrentFrame = () => currentFrame;

  const getCurrentImage = (image?: ChatImage | DialogImage): CurrentImage => {
    const img = image || currentFrame.image;
    return getImage( img )
  };

  const showAnswer = (): boolean =>
    currentFrame.type === 'ANSWER' && show;

  return {
    ...sequence,
    show: onShow,
    isShown: show,
    getCurrentScene,
    getCurrentFrame,
    getCurrentImage,
    onProgress,
    showAnswer,
    addScene,
    getNextFrame,
    start,
  };
};
