import React, { useMemo } from 'react';
import { Shape, ShapeConstructor } from "../../shapes";
import { Container, ShapeContainer, TextContainer } from "./styles";
import PersonaText from "../PersonaText";

interface Props {
  value: string;
  disabled?: boolean;
  onClick(): void;
}

const customShape = () => {
  return new ShapeConstructor()
    .rectangle( { fill: 'black' } )
    .randomize( { x: 4, y: 2 } )
    .add()
    .grow( { x: 2, y: 4, fill: 'white' } )
    .randomize( { x: 1, y: 2, extend: false } )
    .add()
};

const Button: React.FC<Props> = (
  {
    value,
    onClick,
    disabled
  }
) => {
  const form = useMemo( customShape, [] );
  const click = !disabled ? onClick : () => {};

  return (
    <Container onClick={ click }>
      <ShapeContainer>
        <Shape form={ form } />
      </ShapeContainer>
      <TextContainer>
        <PersonaText text={value} />
      </TextContainer>
    </Container>
  );
};

export default Button
