import templates from '../templates/raw';
import {
  TemplateTypes,
  Coordinates,
  PolygonOptions,
  PositionOptions,
  RectangleOptions,
  PolygonState
} from "../Types";
import grow from "./methods/grow";
import randomize from "./methods/randomize";

type Child = any;
export type ShapeConstructorType = typeof ShapeConstructor;

export interface Templates {
  coordinates: Coordinates;
  displacementMap: Coordinates;
  viewBox?: string;
}

const initialState: PolygonState = {
  fill: 'black',
  strokeLinejoin: "miter",
  fillOpacity: 1,
  stroke: '',
  strokeWidth: 0,
  coordinates: [],
  displacementMap: [],
};

const defaultChange = {
  x: 10,
  y: 10,
};

export class ShapeConstructor {
  public width: string | number = '100%';
  public height: string | number = '100%';
  private fill: string = 'black';
  public preserveAspectRatio: string = 'none';
  public viewBox: string = '0 0 100 100';
  public children: Child[] = [];
  public state: PolygonState = initialState;

  public constructor(init?: Partial<ShapeConstructor>) {
    Object.assign( this, init );
  }

  rectangle(config: RectangleOptions = {}) {
    this.setState( {
      ...config,
      coordinates: rectangle(config).coordinates,
      displacementMap: rectangle(config).displacementMap,
    } );
    this.viewBox =  rectangle(config).viewBox;

    return this
  }

  arrow(config: PolygonOptions = {}) {
    return this.setType( config, 'arrow' );
  }

  chat_answer(config: PolygonOptions = {}) {
    return this.setType( config, 'chatAnswer' );
  }

  chat_bubble(config: PolygonOptions = {}) {
    return this.setType( config, 'chatBubble' );
  }

  speech_bubble(config: PolygonOptions = {}) {
    return this.setType( config, 'speechBubble' );
  }

  apostrophe(config: PolygonOptions = {}) {
    return this.setType( config, 'apostrophe' );
  }

  grow(options?: PositionOptions & PolygonOptions) {
    const mergedOptions = {...defaultChange, ...options};
    const coordinates = grow(mergedOptions, this.state);
    this.setState({...options, coordinates});

    return this
  }

  randomize(options?: PositionOptions & PolygonOptions) {
    const mergedOptions = {...defaultChange, ...options};
    const coordinates = randomize(mergedOptions, this.state);
    this.setState({...options, coordinates});

    return this;
  }

  add() {
    this.children = [
      this.state,
      ...this.children,
    ];

    return this;
  }

  addToFront() {
    this.children = [
      ...this.children,
      this.state,
    ];

    return this;
  }

  private setType(
    config: PolygonOptions = {},
    type: TemplateTypes
  ) {

    this.setState( {
      ...config,
      coordinates: templates[type].coordinates,
      displacementMap: templates[type].displacementMap,
    } );
    this.viewBox =  templates[type].viewBox;

    return this;
  }

  private setState(update: PolygonOptions) {
    this.state = {
      ...this.state,
      ...update
    }
  }
}

const rectangle = (options: RectangleOptions) => {
  const { width = 100, height = 100 } = options;
  const coordinates = [
    [ 0, 0 ],
    [ width, 0 ],
    [ width, height ],
    [ 0, height ],
  ];

  const displacementMap = [
    [ -1, -1 ],
    [ 1, -1 ],
    [ 1, 1 ],
    [ -1, 1 ],
  ];

  const viewBox = `0 0 ${width} ${height}`

  return {
    coordinates,
    displacementMap,
    viewBox
  }
};
