import { createChoices } from "../../../create/createChoices";
import { Hooks } from "../../Hooks";
import { next } from "../../../create/next";
import { speak_beautiful } from "./speak_beautiful";
import { didnt_get_that } from "./ai_broke/didnt_get_that/didnt_get_that";

export const born_in_bad_kreuznach = [
  'Bente Theuvsen wurde geboren am 10.11.88 in Bad Kreuznach.',
  'Sie hat es studiert die Game Art&Animation an Games Academy Berlin.',
  createChoices(
    Hooks.BORN_IN_BAD_KREUZNACH,
    ['Ist deine KI kaputt?', 'Du sprichst so schön.']
  ),
  ...next('', speak_beautiful),
  ...next(Hooks.BORN_IN_BAD_KREUZNACH, didnt_get_that)
];
