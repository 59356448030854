import React from 'react';
import Scene from "./scene";
import TestRig from "./TestRig/TestRig";
import Warning from "./components/screen_size_warning";
import { useScreenSizeListener } from "./assets/useScreenSizeListener";
import { useBrowserNavigationHandler } from "./assets/useBrowserNavigationHandler";

const AppHandlers = () => {
  useScreenSizeListener();
  useBrowserNavigationHandler();

  return (
    <>
      <Scene />
      <TestRig />
      <Warning />
    </>
  );
};

export default AppHandlers
