import React, { useMemo } from 'react';
import { Button, ButtonList, Toggle } from "../Styles";
import { useToggle } from "../useToggle";
import { useNavigation } from "../../../assets/useNavigation";
import { Pages } from "../../../constants/Pages";
import { links } from "../../../menu/links";

type Link = {
  name: string;
  to: string;
}

const internalLinks = links.filter(({to}) =>
  !to.startsWith('http')
);

const testPages = [
  {name: 'IT Cellar', to: Pages.IT_CELLAR},
  {name: 'Chat', to: '/chat'},
  {name: 'Dialog', to: '/dialog'},
  {name: 'Demo Shapes', to: '/demo_shapes'},
  {name: 'User', to: '/user'},
  {name: 'Test', to: '/test'},
];

const NavigationButton: React.FC<{
  name: string,
  to: string
}> = ({name, to}) => {
  const navigate = useNavigation();

  return (
    <Button onClick={ () => navigate( to ) }>
      {name}
    </Button>
  );
};

const NavigationRig = () => {
  const [ show, toggle ] = useToggle( true );

  const navButtons = (links: Link[]) =>
    links.map(({name, to}) => (
      <NavigationButton
        key={name}
        name={name}
        to={to}
      />
    ));

  const pageNavigation = useMemo(() =>
    navButtons(internalLinks)
  , []);

  const testNavigation = useMemo(() =>
      navButtons(testPages)
    , []);

  return (
    <ButtonList>
      <Button onClick={ toggle } active={ show }>
        Navigation
      </Button>
      <Toggle visible={ show }>
        {pageNavigation} | {testNavigation}
      </Toggle>
    </ButtonList>
  );
};

export default NavigationRig;
