import { State } from "./store/types";
import { Pages } from "../constants/Pages";
import { useSessionContext } from "../context";

export const useSession = () => {
  const sessionState = useSessionContext();
  const {
    is_read,
    times_visited,
    dsgvo_agreed,
    screen_width,
    screen_height,
    update,
    clear,
  } = sessionState;
  const is_portrait = screen_height > screen_width;

  const isReady = () => is_read;

  const agreeToDsgvo = () => {
    const state = agreeToDsgvoState( sessionState );
    update( state, 'AGREE_TO_DSGVO' )
  };

  const addVisitedPage = (page: Pages) => {
    // Initial loading page is missing here.
    // But sucks to add since this is loaded before React-Router
    const state = addPageToState( sessionState, page );
    update( state )
  };

  const addFinishedSceneToFilters = (id: string) => {
    const state = addFinishedSceneToFilterState(sessionState, id);
    update( state )
  };

  const isPortrait = () => is_portrait;

  const timesVisited = () => times_visited;
  const dsgvo = () => dsgvo_agreed;

  return {
    isReady,
    agreeToDsgvo,
    clearSession: clear,
    addVisitedPage,
    timesVisited,
    dsgvo,
    addFinishedSceneToFilters,
    isPortrait,
    ...sessionState,
  }
};

const agreeToDsgvoState = (state: State) => ({
  ...state,
  dsgvo_agreed: true
});

const addPageToState = (state: State, page: string) => {
  const current = new Set( state.visited_pages );
  const visited_pages = current.has( page )
    ? state.visited_pages
    : state.visited_pages.concat( page );

  return {
    ...state,
    visited_pages
  }
};

const addFinishedSceneToFilterState = (state: State, id: string) => {
  const current_filter = state.filtered_scenes;
  const set = new Set(current_filter);
  // random ids start with _ and should not be added to filters
  const noChange = !id || set.has(id) || id.startsWith('_');
  if (noChange) return state;

  return {
    ...state,
    filtered_scenes: current_filter.concat(id)
  }
};