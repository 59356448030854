export const coordinates = [
  [107, 0],
  [779, 17],
  [750, 85],
  [944, 131],
  [700, 184],
  [716, 131],
  [0, 184]
];

export const displacementMap = [
  [-1, -1],
  [1, -1],
  [1, -1],
  [2, 0],
  [-1, 1],
  [-1, 1],
  [-1, 1],
];

export const viewBox="0 0 944 184";
