import {State} from "./types";

export const initialState: State = {
  version: 1,
  dsgvo_agreed: false,
  times_visited: -1,
  visited_pages: [],
  filtered_scenes: [],
  is_read: false,
  screen_width: -1,
  screen_height: -1,
};