import {
  SceneInterface,
  SceneList,
  Frame,
  CreateScene,
  StartSceneProps
} from "../../types";
import { Pages } from "../../../constants/Pages";

export enum SequenceState {
  LOADING = 'LOADING',
  INITIALIZED = 'INITIALIZED',
  START = 'START',
  AWAIT_CONFIG = 'AWAIT_CONFIG',
  AWAIT_SCENES = 'AWAIT_SCENES',
  AWAIT_START = 'AWAIT_START',
  AWAIT_READY = 'AWAIT_READY'
}

export type State = {
  scenes: SceneList;
  current: string;
  played: string[];
  show: boolean;
  frameIndex: number;
  is_animated_in: boolean;
  is_animated_out: boolean;
  delay: number;
  disableGlobal: boolean;
  end: boolean;
  ready: boolean;
  page: Pages;
  status: SequenceState;
  pageConfig: StartSceneProps | null;
  finish: boolean;
  event: string;
}

export type CurrentImage = {
  chat_face: string;
  chat_background: string;
  dialog: string;
}

export type Actions = {
  initialize(): void;
  addScene(scene: CreateScene | CreateScene[], origin?: Pages | 'GLOBAL'): void;
  getCurrentScene(): SceneInterface;
  getCurrentFrame(): Frame;
  getNextFrame(target?: string): Frame;
  getCurrentImage(image?: string): CurrentImage;
  show(): void;
  hide(): void;
  showAnswer(): boolean;
  onProgress(target?: any): void;
  setAnimatedIn(state: boolean): void;
  setAnimatedOut(state: boolean): void;
  start(config: StartSceneProps): void;
  setPage(page: Pages): void;
}
