import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";

const { choices, choicesIrrelevant } = create;

export const human_born_in_bad_kreuznach: (FrameProps | string)[] = [
  'Ich wurde am 10.11.88 in Bad Kreuznach geboren. Do the maths.',

  choices(Hooks.GAMES_ART_AND_ANIMATION, [
    'Also alt.',
    'Und wo hast du studiert?'
  ], true),

  'Wie bitte? Also wenn du ALT sehen wills, dann hetz ich dir jetzt meinen großen Bruder BENTON auf den Hals!',

  choicesIrrelevant(Hooks.BENTON_TAKES_OVER, [
    'Nein, nicht! Hallo?',
    'Mir doch egal!'
  ])
];
