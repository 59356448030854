import styled from "styled-components";
import iPad_background from "../assets/img/portfolio/iPad-background.png";

export const Container = styled.div`
  position: relative;
  height: 100vh;
  //min-height: 600px;
  width: 120vh;
  //min-width: 800px;
  margin: 0 auto;
`;

export const Tablet = styled.img`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 100;
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 7% 9% 6% 5%;
`;

export const HomeButton = styled.button`
  cursor: pointer;
  position: absolute;
  right: 4%;
  top: 46%;
  height: 8%;
  width: 5.5%;
  border-radius: 50%;
  z-index: 101;
  opacity: 0;
`;

export const Frame = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const IPadBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  background-image: url(${ iPad_background });
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: 100% 70%;
`;

export const AppsContainer = styled.div`
  position: relative;
  display: flex;
  padding: 30px 15px;
  flex-wrap: wrap;
  justify-content: space-between;
`;
