import { useCallback } from "react";
import { useLocation } from "react-router";
import { StartSceneProps } from "../types";
import { start as createStart } from "../store/actions/start";
import { finish as finishScene } from "../store/actions/finish";
import { useSceneGetter } from "./useSceneGetter";
import { useScene } from "../store";
import { useSession } from "../../session";
import { Pages } from "../../constants/Pages";

export const useSceneEventsActions = () => {
  const location = useLocation();
  const session = useSession();
  const sequence = useScene();
  const {
    scenes,
    setPage,
    setupSequence: setupSequenceMethod,
    setCurrent,
    show,
    hide,
    awaitReady,
    setStart,
    nextScene: nextSceneMethod,
  } = sequence;
  const { next } = useSceneGetter();

  const initializePage = useCallback( () => {
    setPage( location.pathname as Pages )
  }, [ location.pathname, setPage ] );

  const setupSequence = useCallback( (config: StartSceneProps) => {
      const {scenes: newScenes, disableGlobal} = createStart( scenes, config );
      setupSequenceMethod( newScenes, disableGlobal );
    }, [ scenes, setupSequenceMethod ]
  );

  const updateCurrent = useCallback( () =>
    setCurrent(next), [next, setCurrent] );

  const setToReady = useCallback( () =>
    awaitReady(), [ awaitReady ] );

  const setToStart = useCallback( () =>
    setStart(), [ setStart ] );

  const nextScene = useCallback( () => {
    const seq = {
      ...sequence,
      show: sequence.isShown,
    }
    const next = finishScene( seq, session );
    nextSceneMethod(next.current, next.delay, next.played);
  }, [nextSceneMethod, sequence, session] );

  return {
    initializePage,
    setupSequence,
    show,
    hide,
    updateCurrent,
    nextScene,
    setToReady,
    setToStart,
  }
};
