import styled from "styled-components";

export const Container = styled.button`
  cursor: pointer;
  position: relative;
  flex: 0 1 150px;
  min-height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
`;

export const Image = styled.img`
  width: 100%;
  min-height: 100px;
  object-fit: contain;
`;

export const Name = styled.span`
  width: 100%;
  min-height: 100px;
  object-fit: contain;
  color: white;
`;