import { useScene } from "../../index";
import React, { useEffect, useMemo, useState } from "react";
import { usePrevious } from "../../../assets/usePrevious";
import { ChoiceProps } from "../../types";
import { Choice } from "./Choice";

const x: ChoiceProps = { text: '' };
const mock = [ x, x, x ];
export const Choices = () => {


  const scene = useScene();
  const [ state, setState ] = useState<string[]>( [] );
  const choices = scene.getCurrentFrame().choices || [];
  const prevChoices = usePrevious( choices ) || choices;

  const choiceMap = useMemo( () =>
      mock.map( (choice, i) =>
        choices[i]
          ? choices[i]
          : {...choice, text: state[i] || ''}
      )
    , [choices, state] );

  useEffect( () => {
    if ( !prevChoices.length && choices.length ) {
      const texts = choices.map( choice => choice.text );
      setState( texts )
    }

  }, [ choices, prevChoices.length ] );

  const currentChoices = useMemo(
    () => choiceMap.map( (choice, i) => (
      <Choice
        key={ i }
        choice={ choice }
      />
    ) ),
    [ choiceMap ]
  );

  return (
    <React.Fragment>
      { currentChoices }
    </React.Fragment>
  );
};
export default Choices;