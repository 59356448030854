import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Colors } from "./constants/colors";
import { PageColors, Pages } from "./constants/Pages";
import { usePrevious } from "./assets/usePrevious";
import { useToggle } from "./TestRig/TestRig/useToggle";
import { useScene } from "./scene";

const black = '#000 52%, #000 54%';

const Container = styled.div<{isYellow: boolean, from: string, to: string}>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 150vw;
  background: ${Colors.PINK};
  background: linear-gradient(121deg, ${props => 
    ` ${props.from} 52%, ${black}, ${props.to} 54%`
  });
  background-position-x: ${props => props.isYellow ? '125%' : 0};
  background-size: 200%;
  background-repeat: no-repeat;
  z-index: -1;
  transition: background-position .7s ease .4s;
`;

const AppBackground = () => {
  const {page: scenePage} = useScene();
  // @ts-ignore
  const page = scenePage === '/' ? Pages.HOME : scenePage
  // const page = useSelector( ({sequence}: Store) =>
  //   // @ts-ignore
  //   sequence.page === '/' ? Pages.HOME : sequence.page
  // );
  const prev_page = usePrevious(page) || page;

  const [state, toggleState] = useToggle(false);
  const [colors, setColors] = useState([Colors.YELLOW, Colors.PINK]);

  const backgroundColor = useMemo(() =>
      getPageColor(page)
    , [page]);

  const prevBackgroundColor = useMemo(() =>
      getPageColor(prev_page)
    , [prev_page]);

  const needs_update = useMemo(() =>
    page !== prev_page && backgroundColor !== prevBackgroundColor
    , [backgroundColor, page, prevBackgroundColor, prev_page]);

  const updateColors = useCallback(() => {
    if (state) {
      setColors([backgroundColor, prevBackgroundColor]);
    } else {
      setColors([prevBackgroundColor, backgroundColor]);
    }
  }, [backgroundColor, prevBackgroundColor, state]);

  useEffect(() => {
    if (needs_update) {
      toggleState();
      updateColors()
    }
  }, [needs_update, toggleState, updateColors]);

  return (
    <Container
      id='Background'
      isYellow={state}
      from={colors[0]}
      to={colors[1]}
    />
  );
};

export default AppBackground

const getPageColor = (page: Pages) => {
  const index = getEnumKeyByEnumValue(Pages, page);
  //@ts-ignore
  return PageColors[index] || PageColors.HOME;
};

function getEnumKeyByEnumValue<T extends {[index:string]:string}>(myEnum:T, enumValue:string):keyof T|null {
  let keys = Object.keys(myEnum).filter(x => myEnum[x] === enumValue);
  return keys.length > 0 ? keys[0] : null;
}
