import { Ref } from "../../types/Ref";

type DomNodeList = { [index: string]: HTMLElement | null }
type DomRefList = { [index: string]: Ref }

export const isReady = (refs: DomNodeList | DomRefList) => {
  if ( !Object.keys( refs ).length ) return false;

  if ( isRefList( refs ) ) {
    return isRefListReady( refs );
  }

  return isDomListReady( refs )
};

const isRefList = (refs: DomNodeList | DomRefList): refs is DomRefList => {
  const first = Object.values( refs )[0];
  return first && first.current !== undefined;
};

const isDomListReady = (domNodes: DomNodeList): boolean => {
  for ( let i in domNodes ) {
    if ( !domNodes[i] ) {
      return false;
    }
  }

  return true;
};

// This is fucking stupid. Needs refactoring later and constistent usage.
const isRefListReady = (refs: DomRefList): boolean => {
  for ( let i in refs ) {
    if ( refs.hasOwnProperty( i ) ) {
      if ( !refs[i].current ) {
        return false
      }
    } else {
      return false
    }
  }

  return true
};