import { createMultipleChoices } from "../../../../../create/createMultipleChoices";
import { escape_ending } from "./escape_ending";
import { next } from "../../../../../create/next";
import { Hooks } from "../../../../Hooks";
import { ego_0_bente_win } from "./ego_0_bente_win";
import { SceneEvent } from "../../../../../../types";

export const fetish = [
  'Hier sind alle Fetisch-Läden in deiner Nähe:',
  createMultipleChoices(
    [ Hooks.FETISH_GOOD, 'Oh, achso, wenn du eh schon dabei bist...' ],
    [ Hooks.FETISH_BAD, 'Du bist scheiße!' ]
  ),
  ...next(Hooks.FETISH_GOOD, escape_ending, true),
  {
    id: Hooks.FETISH_BAD,
    text: 'Ok, rufe Deine Mutter Handy an.'
  },
  createMultipleChoices(
    [ Hooks.AAARRGGHH, 'ICH HASSE CHATBOTS!!!' ],
    [ Hooks.HATE_CHATBOTS, 'AAARRGGHH!!!' ]
  ),
  ...next(Hooks.AAARRGGHH, ego_0_bente_win),
  ...next(Hooks.HATE_CHATBOTS, [{
    text:'Der Chat ist explodiert',
    target: 'break',
    event: SceneEvent.EXPLOSION
  }]),
];
