import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";
import { marlene_knows_taekwondo } from "./marlene_knows_taekwondo";

const { next, choices, end } = create;

export const everything_under_control: (FrameProps | string)[] = [
  'Na, du hast ja alles so richtig im Griff.',

  choices(Hooks.EVERYTHING_UNDER_CONTROL, [
    'Sei mal nicht so frech.',
    'Es tut mir leid...'
  ]),

  'Nagut. Ich vergebe dir. Und jetzt störe mich nicht weiter.',
  end('Sinan hat den Chat verlassen'),

  ...next(Hooks.EVERYTHING_UNDER_CONTROL, marlene_knows_taekwondo)
];
