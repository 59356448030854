import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";
import { hiding } from "./hiding";
import { ChatImage } from "../../../../../../../../../../constants/chatImages";

const { next, choices, sinan } = create;

export const little_birdy: (FrameProps | string)[] = [
  sinan('Sinan hat den Chat betreten'),
  sinan('Das war aber ein süßes vögelchen! Hab ich was verpasst?'),

  choices(Hooks.LITTLE_BIRDY, [
    'Ne gar nix!',
    'Also...',
  ]),

  ...hiding,

  ...next(Hooks.LITTLE_BIRDY, [
    sinan('Okay. OH EIN EICHHÖRNCHEN!'),
    {
      image: ChatImage.SINAN,
      text: 'Sinan handbags handy fallen lassen und zerstört.',
      target: 'break'
    },
  ])
];
