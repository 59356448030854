import React from 'react';
import { Button, ButtonList, Toggle } from "../Styles";
import { useToggle } from "../useToggle";
import { useScene } from "../../../scene";
import {id} from "../../../utilz/id";

const SceneRig = () => {
  const [ show, toggle ] = useToggle(true);
  const scene = useScene();

  const addScene = () => {
    scene.addScene(id())
  };

  return (
    <ButtonList>
      <Button onClick={ toggle } active={ show }>
        Scene
      </Button>
      <Toggle visible={ show }>
        <Button onClick={addScene}>
          addScene
        </Button>
        <Button onClick={scene.show}>
          show
        </Button>
        <Button onClick={scene.hide}>
          hide
        </Button>
      </Toggle>
    </ButtonList>
  );
};

export default SceneRig;
