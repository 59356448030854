import React from "react";
import styled from "styled-components";
import { ShapeProps } from "./Types";
import SVG from "./components/SVG";
import { effects } from "./utilz/effects";
import TextContainer from "./components/TextContainer";
import { getTextColor } from "./components/utilz/getTextColor";
import { useHover } from "../assets/useHover";
import PolygonMap from "./components/PolygonMap";

type Ratio = string | number;

interface Props extends ShapeProps {
  useClickableArea?: boolean
}

const Container = styled.div<{ containerWidth: string; }>`
  position: relative;
  width: ${ props => props.containerWidth };
  height: 100%;
`;

const ClickableArea = styled.div<{ width: Ratio; height: Ratio; useClickableArea?: boolean }>`
  position: absolute;
  width: ${ props => props.width };
  height: ${ props => props.height };
  top: 0;
  left: 0;
  background: transparent;
  display: ${ props => props.useClickableArea ? 'inherit' : 'none' };
`;

const Shape: React.FC<Props> = (
  props
) => {
  const {
    form,
    textPosition,
    textColor = 'white',
    wiggle = false,
    onMouseOver = [],
    wiggleOptions,
    children = null,
    switchDirection,
    containerWidth = '100%',
    useClickableArea
  } = props;
  const [ hovered, onMouse ] = useHover();
  const effect = effects( onMouseOver );
  const { width, height } = form;

  const reverseNow = effect.reverse() && hovered;
  const wiggleNow = wiggle || (hovered && effect.wiggle());
  const show = !effect.show() || hovered;
  const color = getTextColor( textColor, reverseNow );
  const { state, ...SVGProps } = form;

  return (
    <Container
      containerWidth={ containerWidth }
    >
      <SVG
        { ...SVGProps }
        show={ show }
        switchDirection={ switchDirection }
      >
        <PolygonMap
          form={ form }
          wiggle={ wiggleNow }
          reverse={ reverseNow }
          wiggleOptions={ wiggleOptions }
        />
      </SVG>
      <TextContainer
        textColor={ color }
        textPosition={ textPosition }
        switchDirection={ switchDirection }
      >
        { children }
      </TextContainer>
      <ClickableArea
        useClickableArea={ useClickableArea }
        height={ height }
        width={ width }
        onMouseOver={ onMouse.enter }
        onMouseLeave={ onMouse.leave }
      />
    </Container>
  )
};

export default Shape;
