import React, { useEffect, useRef } from 'react';
import { Black, Container, Overlay } from "./styles/BackgroundStyle";
import { SetDomNodes } from "../assets/useDomNodes";

interface Props {
  setDomNodes: SetDomNodes;
}

const MenuBg: React.FC<Props> = ({setDomNodes}) => {
  const refBox = useRef<HTMLDivElement>( null );
  const refOverlay = useRef<HTMLDivElement>( null );

  useEffect( () => {
    if ( refBox.current ) {
      setDomNodes( { refBox, refOverlay } )
    }
  }, [setDomNodes] );

  return (
    <React.Fragment>
      <Container id="Menu Box" ref={ refBox }>
        <Black id="Menu Box Black" />
      </Container>
      <Overlay id="Menu Overlay" ref={ refOverlay } />
    </React.Fragment>
  );
};

export default MenuBg;
