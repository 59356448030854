import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import SVGPath from "./SVGPath";

const Container = styled.div`
  position: absolute;
  top: 0;
  left: -210px;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

type Coordinate = {
  x: number;
  y: number;
}

const max_length = 4;

const ChatPath = () => {
  const container = document.getElementById( 'Message_Container' );
  const has_messages = container && !!container.children.length;
  const [ coordinates, setCoordinates ] = useState<Coordinate[]>( [] );
  const [ amount, setAmount ] = useState( 0 );

  const getCurrentCoordinate = useCallback((children) => {
    let domArray: HTMLElement[] = [].slice.call( children );
    if ( domArray.length > max_length ) {
      domArray = domArray.slice( domArray.length - max_length );
    }
    return domArray.map( el => {
      const node = el.childNodes[0].childNodes[0];
      //@ts-ignore
      return node.getBoundingClientRect();
    } );

  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect( () => {
    // add the last 4 (max_length) positions to state
    // and then provide this state to SVGPath to render
    if ( has_messages && is_rendered( container ) ) {
      const { children } = container;
      const pos = getCurrentCoordinate(children);
      if ( amount !== children.length ) {
        setAmount( children.length );
        setCoordinates( pos )
      }
    }
  } );

  return (
    <Container>
      <SVGPath points={ coordinates } />
    </Container>
  );
};

export default ChatPath

const is_rendered = (container: HTMLElement | null): container is HTMLElement =>
  container !== null;