import React, { useCallback, useEffect, useState } from 'react';
import ContactDom from "./ContactDom";
import { useAnimation } from "../assets/useAnimation";
import { animate } from "./animation/animate";
import { useValidation } from "./useValidation";
import { useControlledInput } from "../assets/useControlledInput";
import { useSendMail } from "./useSendMail";
import { useScene } from "../scene";
import {Dialog_Text as CONSTANT_TEXT} from '../constants/Dialog_Text';

const Contact = () => {
  const [ imageReady, setImageReady ] = useState( false );
  const [ play, setPlay ] = useState( true );
  const { addScene } = useScene();
  const [ state, send ] = useSendMail();
  const validate = useValidation();
  const [ mail, inputMail ] = useControlledInput();
  const [ text, inputText ] = useControlledInput();
  //@ts-ignore
  const submitAnimation = useAnimation( animate );

  const setReady = () => setImageReady( true );

  const onComplete = useCallback(() =>
      addScene( CONSTANT_TEXT.CONTACT_SEND )
  , [addScene]);

  useEffect( () => {
    if ( play ) {
      const empty = () => {
      };
      const animation = {
        idle: empty,
        loading: empty,
        success: () => submitAnimation.timeline.in({
          onStart: () => setPlay( false ),
          onComplete
        }),
        error: () => submitAnimation.timeline.out(),
      };
      animation[state]()
    }
  }, [onComplete, play, state, submitAnimation.timeline] );

  const onSubmit = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const isValid = validate( mail, text );
    if ( isValid ) {
      send( mail, text )
    }
  };

  return (
    <ContactDom
      inputMail={ inputMail }
      mail={ mail }
      inputText={ inputText }
      text={ text }
      onSubmit={ onSubmit }
      setDomNodes={ submitAnimation.setDomNodes }
      state={ state }
      setBackgroundReady={ setReady }
      backgroundReady={ imageReady }
    />
  );
};

export default Contact
