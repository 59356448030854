import {createContainer} from "unstated-next";
import {useState} from "react";
import { session } from "../session/store/session";

export type SessionState = {
  version: number;
  dsgvo_agreed: boolean,
  times_visited: number,
  visited_pages: string[],
  filtered_scenes: string[],
  is_read: boolean,
  screen_width: number,
  screen_height: number,
}

export const initialState: SessionState = {
  version: 1,
  dsgvo_agreed: false,
  times_visited: -1,
  visited_pages: [],
  filtered_scenes: [],
  is_read: false,
  screen_width: -1,
  screen_height: -1,
};

const useSessionBase = () => {

  const [state, setState] = useState(initialState);
  const update = (newState: SessionState, message?: string) => {
    setState(newState);
    session.writeState( state );
    // message && console.log(message);
  }

  const clear = () => {
    setState(initialState);
    session.clear();
  }

  const setScreenSize = (width: number, height: number) => {
    setState({
      ...state,
      screen_width: width,
      screen_height: height,
    })
  }

  return {
    ...state,
    update,
    clear,
    setScreenSize,
  };
}

const useSessionContainer = createContainer(useSessionBase);
export const useSessionContext = useSessionContainer.useContainer;
export const SessionProvider = useSessionContainer.Provider;
