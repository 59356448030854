import React, { useEffect, useMemo } from 'react';
import { getQuotes } from "./getQuotes";
import QuoteBlock from "./QuoteBlock";
import { Center, Container } from "./styles";
import { useAnimation } from "../../../assets/useAnimation";
import { animateLeft } from "./animation/animateLeft";
import { animateRight } from "./animation/animateRight";

const Quotes = () => {
  const { left, right } = getQuotes();
  const animatorLeft = useAnimation( animateLeft );
  const animatorRight = useAnimation( animateRight );

  const ready = useMemo( () =>
    animatorLeft.is_ready && animatorRight.is_ready
    , [ animatorLeft.is_ready, animatorRight.is_ready ] );

  useEffect( () => {
    if ( ready ) {
      animatorLeft.timeline.in();
      animatorRight.timeline.in();
    }
  }, [ animatorLeft.timeline, animatorRight.timeline, ready ] );

  return (
    <Container>
      <QuoteBlock
        left
        setDomNodes={ animatorLeft.setDomNodes }
        quotes={ left }
      />
      <Center />
      <QuoteBlock
        setDomNodes={ animatorRight.setDomNodes }
        quotes={ right }
      />
    </Container>
  );
};

export default Quotes
