import { createMultipleChoices } from "../../../../../create/createMultipleChoices";
import { Hooks } from "../../../../Hooks";
import { next } from "../../../../../create/next";
import { mother_in_law } from "./mother_in_law";

export const didnt_get_that = [
  'Wie bitte, ich habe dich nicht verstanden.',
  createMultipleChoices(
    [Hooks.ARE_YOU_BROKEN, 'Ob du einen Schaden hast, will ich wissen?!'],
    [Hooks.SPEAK_STUPID, 'Du sprichst so blöde.'],
  ),
  ...next(Hooks.ARE_YOU_BROKEN, [
    '*beep boop* Deine- *boop* Mutter HAT EINEN-',
    '*beeeeeeeep* BenteBot.exe hat aufgehört zu funktionieren.',
    '<emergencyexit=initiate>',
    'Chat ist zerbrochen.'
  ],
    true),
  ...next(Hooks.SPEAK_STUPID, mother_in_law)
];
