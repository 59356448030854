import { SceneProps } from "../types";
import { Pages } from "../../constants/Pages";
import { DialogImage } from "../../constants/dialogImages";

export const dsgvo: SceneProps = {
  id: 'dsgvo',
  order: 1,
  image: DialogImage.BENTE_COOKIE,
  delay: 7,
  // endless: true,
  // repeat: true,
  name: 'Norbert',
  frames: [
    {
      text: "Hier spricht die Keks-Polizei.",
    },
    {
      text: "HÄNDE HOCH ODER ICH SCHLIEßE!",
    },
    {
      text: "Hier werden Cookies und Analytics verwendet.",
    },
    {
      type: 'ANSWER',
      choices: [
        {
          text: 'Schon klar, ich stimme zu',
        },
        {
          text: "Ich will's genau",
          target: 'imprint',
        },
      ],
    },
    {
      text: "Alles klar",
      target: 'break',
      // event: SceneEvent.COOKIE_CONSENT
    },
    {
      id: 'imprint',
      text: "Du willst es also genau wissen. Vorbildlich!",
      target: Pages.IMPRINT
    },

  ]
};

export default dsgvo;
