import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";
import { what_next } from "./what_next";

const { next, choices, end } = create;

export const fun_and_beautiful: (FrameProps | string)[] = [
  'Total umgänglich, lustig und wunderhübsch.',
  choices(Hooks.BENTON_FUN_AND_BEAUTIFUL, [
    'Glaub ich sofort.',
    'Ähm... wer redet denn so über seine Schwester?'
  ]),

  end('Äääh... MIST!'),

  ...next(Hooks.BENTON_FUN_AND_BEAUTIFUL, what_next),
];
