import TimelineMax from 'assets/utilz/timeline';
import * as displace from '../styles/displace';
import { AnimationReturn } from "../../assets/useAnimation";

export const animation = (menu: any): AnimationReturn => {
  return {
  //@ts-ignore
    in: () => animateIn(menu).play(),
  //@ts-ignore
    out: () => animateOut(menu).play(),
  }
};

const animateIn = (menu: any) => {
  return new TimelineMax({paused: true})
    .add(boxIn(menu.refBox), 0)
    .add(overlayIn(menu.refOverlay), "-=.5");
};

const animateOut = (menu: any) => {
  return new TimelineMax({paused: true})
    .add(boxOut(menu.refBox), 0)
    .add(overlayOut(menu.refOverlay), 0)
};

const boxIn = (node: HTMLElement) =>
  new TimelineMax()
    .to(node, {
      duration: .75,
      ease: "power2.out",
      x: 0
    });

const overlayIn = (node: HTMLElement) =>
  new TimelineMax()
    .to(node, {
      duration: .5,
      ease: "power1.out",
      x: 0
    });

const boxOut = (node: HTMLElement) =>
  new TimelineMax()
    .to(node, {
      duration: .4,
      ease: "power2.in",
      x: displace.boxX
    });

const overlayOut = (node: HTMLElement) =>
  new TimelineMax()
    .to(node, {
      duration: .5,
      ease: "power1.in",
      x: displace.boxX
    });
