import { ReturnRefs, useDomNodes } from "./useDomNodes";
import { useMemo } from "react";
import TimelineMax from 'assets/utilz/timeline';

type Props = any[];

type Animation = (nodes: ReturnRefs["domNodes"], ...props: Props) =>
  Partial<typeof emptyTimeline>

type OnComplete = (callback: any) => void;

type TimelineConfig = {
  onComplete: OnComplete;
  onStart: OnComplete;
}

type TimelineProps = Partial<TimelineConfig>;

const defaultTimelineProps = {
  onComplete: () => {},
  onStart: () => {},
};

export interface AnimationReturn {
  in(timelineProps?: TimelineProps): TimelineMax;
  out(timelineProps?: TimelineProps): TimelineMax;
}

const emptyTimeline = {
  in: (timelineProps?: TimelineProps) => new TimelineMax(),
  out: (timelineProps?: TimelineProps) => new TimelineMax(),
};

export const useAnimation = (
  animation: Animation,
  timelineProps?: TimelineProps
) => {
  const config = createTimelineProps(timelineProps);
  const [ nodes, setDomNodes ] = useDomNodes();

  const timeline = useMemo(() => {
    return nodes.is_ready
      ? {...emptyTimeline, ...animation(nodes.domNodes, config)}
      : emptyTimeline
  }, [animation, nodes, config]);

  return {
    setDomNodes,
    timeline,
    is_ready: nodes.is_ready
  }
};

const createTimelineProps = (props?: TimelineProps): TimelineConfig => ({
  ...defaultTimelineProps,
  ...props
});
