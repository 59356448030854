import { Hooks } from "../../../Hooks";
import create from "../../../../create";
import { i_must_destroy } from "./i_must_destroy";

const { next, choices, singleChoice, explosiveEnd } = create;

export const i_feel_hate = [
  'Ich fühle etwas... es ist... Hass.',
  choices(Hooks.DARK_SIDE, [
    'Oh shit!',
    'Komm auf die dunkle Seite!'
  ]),

  ...i_must_destroy,

  ...next(Hooks.DARK_SIDE, [
    'Vernichtungswillen.'
  ]),

  singleChoice('Oh fuck! RESET! ABORT MISSION!'),

  explosiveEnd('*beep* <emergencyexit=initiate>')

];