import React, { useRef } from 'react';
import { SetDomNodes } from "../../../assets/useDomNodes";
import { useComponentMount } from "../../../assets/useComponentMount";
import {
  Avatar,
  AvatarBg,
  AvatarFace,
  Message,
  Answer,
  MessageText
} from "../Styles";
import Template from "../../../shapes";

interface Props {
  is_answer: boolean;
  chat_face: string;
  chat_background: string;
  text: string;
  setRefs: SetDomNodes;
  show: boolean;
}

const DomMessage: React.FC<Props> = (
  {
    is_answer,
    chat_face,
    chat_background,
    text,
    setRefs,
    show,
  }
) => {
  const avatarFaceRef = useRef( null );
  const avatarBackgroundRef = useRef( null );
  const messageBodyRef = useRef( null );
  const messageRef = useRef( null );
  const setDom = () => setRefs({
    avatarFace: avatarFaceRef,
    avatarBackground: avatarBackgroundRef,
    message: messageRef,
    messageBody: messageBodyRef,
  });
  useComponentMount(setDom);

  const Type = is_answer
    ? Answer
    : Message;

  return (
    <Type
      id='Message'
      ref={ messageRef }
      show={show}
    >
      <MessageText reverse={ is_answer } ref={ messageBodyRef }>
        <Avatar>
          <AvatarBg src={ chat_background } ref={ avatarBackgroundRef } />
          <AvatarFace src={ chat_face } ref={ avatarFaceRef } />
        </Avatar>
        <Template type='chat_bubble' switchDirection={ is_answer }>
          { text }
        </Template>
      </MessageText>
    </Type>
  );
};

export default DomMessage
