import React from 'react';
import { Button } from "./Style";
import Menu from "../index";
import { useMenu } from "../../context";

const MenuButton = () => {
  // const [ { isOpen, isDisabled }, { toggle } ] = useMenu();
  const {isOpen, isDisabled, toggle} = useMenu();

  return (
    <>
      <Button
        open={ isOpen }
        onClick={ toggle }
        disabled={isDisabled}
      />
      <Menu />
    </>
  );
};

export default MenuButton
