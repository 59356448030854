import { FrameProps } from "../../types";

export const next = (
  id: string,
  file: (FrameProps | string)[],
  break_scene?: boolean
): FrameProps[] =>
  file.map( ((e, i) => {
    const firstId = getId(e, i, id);
    const frame = isFrame( e ) ? e : { text: e };
    let target = getTarget(e, i);
    const add_break = break_scene && i === file.length -1;
    target = add_break && !target ? 'break' : target;

    return {
      ...frame,
      id: firstId,
      target
    }
  }) );

const getTarget = (
  elem: FrameProps | string,
  i: number,
) => isFrame(elem) && elem.target
  ? elem.target
  : undefined;

const getId = (
  elem: FrameProps | string,
  i: number,
  id: string
) => {
  if (i === 0) return id;

  return isFrame(elem) ? elem.id : undefined
};

const isFrame = (elem: FrameProps | string): elem is FrameProps =>
  !!(elem as FrameProps).text || !!(elem as FrameProps).choices;
