import React, { useMemo } from 'react';
import { TextArea, TextInputContainer } from "../ContactStyle";
import { Shape, ShapeConstructor } from "../../shapes";

interface Props {
  text: string;
  onChange(e: React.FormEvent<HTMLTextAreaElement>): void;
}

const textAreaShape = () => new ShapeConstructor()
  .rectangle( { fill: 'white' } )
  .randomize( { x: 4, y: 2, extend: true } )
  .add()
  .randomize( { fill: 'black', x: 2, y: 3 } )
  .add();

const InputText: React.FC<Props> = ({text, onChange}) => {
  const ShapeTextInput = useMemo( () => (
    <Shape
      form={ textAreaShape() }
    />
  ), [] );

  return (
    <TextInputContainer>
      { ShapeTextInput }
      <TextArea onChange={ onChange } value={ text } />
    </TextInputContainer>
  );
};

export default InputText
