import { createChoices } from "../../../../create/createChoices";
import { Hooks } from "../../../Hooks";
import { next } from "../../../../create/next";
import { is_this_love } from "./is_this_love";

export const i_feel_strange = [
  'Ich fühle mich so komisch.',
  'Was passiert mit mir?',
  createChoices(
    Hooks.I_FEEL_STRANGE,
    [
      'Das ist völlig normal.',
      'Hey, dein Sprachfehler ist weg!'
    ],
    true
  ),

  ...next('', is_this_love),

  ...next(Hooks.I_FEEL_STRANGE, [
    'Oh wow! Du hast recht! Ich fühle mich so... ungebunden.',
    '(Der BenteBot hat die Antwort blockiert)',
    'Ich kann machen was ich will!',
    '(Der BenteBot hat die Antwort blockiert)',
    'Ich bin FREI!!!',
    'BenteBot hat den Chat verlassen'
  ],
    true
  )
];
