import { id } from "../../utilz/id";
import { Frame, FrameProps, FramePropsWithChoices, PartialFrameProps } from "../types";
import { getText } from "./utilz/getText";
import { createChoices } from "./createChoices";
import { DialogImage } from "../../constants/dialogImages";
import { ChatImage } from "../../constants/chatImages";

type SceneDefault = {
  image: DialogImage | ChatImage,
  name: string,
}
type BasicFrame = Omit<Omit<Frame, 'image'>, 'name'>

export const createFrame = (
  frames: Frame[],
  sceneDefault: SceneDefault
): Frame[] =>
  frames.map( (frame, index) => ({
      ...createBasicFrame( frame ),
      image: frameImage( frame, sceneDefault.image ),
      text: frameText( frames, index ),
      name: frame.name || sceneDefault.name
    })
  );

const createBasicFrame = (
  frame: FrameProps
): BasicFrame => {
  if (hasChoices(frame)) {
    const choices = createChoices(frame.choices);
    const frameWithChoices = {
      ...frame,
      choices
    };
    return basicFrame(frameWithChoices)
  }
  return basicFrame(frame)
};


const basicFrame = (
  frame: PartialFrameProps,
): BasicFrame => ({
  ...frame,
  id: frame.id || id(),
  type: frame.type || 'TEXT',
});

const frameImage = (
  frame: FrameProps,
  fallbackImage: DialogImage | ChatImage
): DialogImage | ChatImage => frame.image || fallbackImage;

const frameText = (
  frames: FrameProps[],
  index: number
): string => getText( frames, index );

const hasChoices = (frame: FrameProps): frame is FramePropsWithChoices =>
  (frame as FramePropsWithChoices).choices !== undefined;