import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";
import { sounded_devious } from "./sounded_devious";
import { sinan_takes_over2 } from "./sinan/sinan_takes_over2";

const { next, choices } = create;

export const fight: (FrameProps | string)[] = [
  'Und ein Mal, da hat mein Mann sich fast im Straßenverkehr geprügelt und eine Stunde später...',
  'stand der Typ als unser Heizungshandwerker bei uns in der Wohnung.',

  choices(Hooks.FIGHT, [
    'Nä, das glaub ich nicht!',
    'Wow, das war ja spannend.'
  ]),

  ...sounded_devious,

  ...next(Hooks.FIGHT, [
    'Frag Sinan!',
    'Bente hat den Chat verlassen',
    ...sinan_takes_over2
  ])
];
