import React from "react";
import {useChatContext, ChatProvider} from "./chat.context";
import {useMenu, MenuProvider} from "./menu.context";
import {usePortfolio} from "./portfolio.context";
import {useSceneContext, SceneProvider} from "./scene.context";
import {useSessionContext} from "./session.context";

const DialogProvider: React.FC<{children: React.ReactNode}> = ({children}) => (
  <MenuProvider>
    <SceneProvider>
      <ChatProvider>
        {children}
      </ChatProvider>
    </SceneProvider>
  </MenuProvider>
);

export {
  useSessionContext,
  usePortfolio,
  useSceneContext,
  useMenu,
  useChatContext,
  DialogProvider,
}