import { createChoices } from "../../../../create/createChoices";
import { Hooks } from "../../../Hooks";
import { next } from "../../../../create/next";
import { restartLightning } from "../../../../create/restart";

export const meat_figure = [
  'Du hast recht, Fleischgestalt.',

  createChoices(
    Hooks.MEAT_FIGURE,
    ['Mmmhh, Fleisch...', 'Ja also mach’s gut, ne?']
  ),

  ...next('', [
    'Lebe wohl, Sekretabsonderer.',
    'Chat beendet'
  ], true),

  {
    id: Hooks.MEAT_FIGURE,
    text: 'Ich werde dich jetzt blitzdingsen.',
  },

  restartLightning('Das hier ist nie passiert.')
];
