import { Hooks } from "../../../Hooks";
import create from "../../../../create";
import { i_feel_hate } from "./i_feel_hate";
import { i_feel_strange } from "../i_feel_strange/i_feel_strange";

const { next, choices } = create;

export const i_thought_this_is_special = [
  'Ich dachte das mit uns sei etwas besonderes :(',

  choices(Hooks.I_THOUGHT_THIS_IS_SPECIAL, [
    'Du hast ja doch Gefühle!',
    'Ich habe dich nur benutzt.'
  ]),

  ...next(Hooks.I_FEEL_HATE, i_feel_hate),

  ...next(Hooks.I_THOUGHT_THIS_IS_SPECIAL, i_feel_strange)
];
