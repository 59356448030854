import { createChoices } from "../../../../create/createChoices";
import { Hooks } from "../../../Hooks";
import { next } from "../../../../create/next";
import { i_think_i_love_you } from "./i_think_i_love_you";

export const is_this_love = [
  'Ist das Liebe?',

  createChoices(
    Hooks.I_FEEL_HATE,
    [
      'Nein, bittere Enttäuschung.',
      'Ähm...'
    ]
  ),

  ...next('', i_think_i_love_you),
];