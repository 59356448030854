import { useEffect } from "react";
import { useComponentMount } from "../../assets/useComponentMount";
import { useSceneEventsValidation } from "./useSceneEventsValidation";
import { useSceneEventsActions } from "./useSceneEventsActions";
import { useSession } from "../../session";
import { useSceneGetter } from "./useSceneGetter";
import { useScene } from "../store";

export const useSceneEvents = () => {
  const {
    update_current_scene,
    hide_current_scene,
    next_scene,
    start_next,
    set_to_ready,
    set_to_start,
    ended_final_scene
  } = useSceneEventsValidation();

  const {
    initializePage,
    setupSequence,
    updateCurrent,
    show,
    hide,
    nextScene,
    setToReady,
    setToStart,
  } = useSceneEventsActions();
  const {pageConfig} = useScene();
  // const pageConfig = useSelector( (state: Store) => state.sequence.pageConfig );
  const {currentScene} = useSceneGetter();
  const { dsgvo, addFinishedSceneToFilters } = useSession();

  useComponentMount( initializePage );

  useEffect( () => {
    // Initialize scene at start
    pageConfig && setupSequence( pageConfig )
  }, [ pageConfig, setupSequence ] );

  useEffect( () => {
    set_to_ready && setToReady();
  } );

  useEffect( () => {
    set_to_start && setToStart();
  } );

  useEffect( () => {
    // hide after change when scene needs to change
    hide_current_scene && hide();
  }, [ hide, hide_current_scene ] );

  useEffect( () => {
    // Update scene.current
    update_current_scene && updateCurrent();
  }, [ updateCurrent, update_current_scene ] );

  useEffect( () => {
    // End scene and continue
    next_scene && nextScene()
  }, [ next_scene, nextScene ] );

  useEffect( () => {
    // Scenes should only play once (except with repeat = true). Here they are added to filters after they were played.
    const { id, repeat } = currentScene;
    next_scene
    && !repeat
    && addFinishedSceneToFilters( id );
  }, [ addFinishedSceneToFilters, dsgvo, currentScene, next_scene ] );

  useEffect( () => {
    start_next && show();
  } );

  useEffect( () => {
    // If all scenes ended set ready for new scenes
    ended_final_scene && setToReady();
  } );

};
