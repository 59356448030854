import { SceneProps } from "../../scene/types";
import { DialogImage } from "../../constants/dialogImages";

export const test_dialog: SceneProps = {
  id: 'test',
  order: 10,
  endless: true,
  repeat: true,
  frames: [
    {
      text: "Hi ich bin nur ein Test",
      image: DialogImage.SINAN,
      name: 'Sinan'
    },
  ],
};