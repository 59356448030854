import React from "react";
import {DialogProvider} from "./context";
import AppRouter from "./AppRouter";
import {BrowserRouter} from "react-router-dom";
import MenuButton from "./menu/MenuButton";
import AppHandlers from "./AppHandlers";
import AppBackground from "./AppBackground";

interface Props {

}

const AppProvider: React.FC<Props> = () => {

  return (
    <BrowserRouter>
      <DialogProvider>
        <AppBackground/>
        <AppRouter/>
        <MenuButton/>
        <AppHandlers/>
      </DialogProvider>
    </BrowserRouter>
  );
}

export default AppProvider;
