import { ReturnRefs, useDomNodes } from "./useDomNodes";
import TimelineMax from 'assets/utilz/timeline';
import { useEffect, useState } from "react";
import { usePrevious } from "./usePrevious";

type Animation = (nodes: ReturnRefs["domNodes"]) =>
  typeof emptyTimeline

const emptyTimeline = {
  play: () => new TimelineMax(),
};

export const useAnimateOnReady = (animation: Animation, is_ready: boolean = true ) => {
  const [ nodes, setDomNodes ] = useDomNodes();
  const was_ready = usePrevious(nodes.is_ready);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (nodes.is_ready && !was_ready) {
      setReady(true)
    }
  }, [nodes.is_ready, was_ready]);

  useEffect(() => {
    if (ready && is_ready) {
      setReady(false);
      animation(nodes.domNodes).play()
    }
  }, [animation, is_ready, nodes.domNodes, ready]);


  return setDomNodes
};
