import { useEffect, useCallback, useRef } from 'react';

// React hook for delaying calls with time
// returns callback to use for cancelling

export const useTimeout = (
  callback: () => void, // function to call. No args passed.
  // if you create a new callback each render, then previous callback will be cancelled on render.
  timeout: number = 0, // delay, in seconds (default: immediately put into JS Event Queue)
): () => void => {
  const timeoutIdRef = useRef<NodeJS.Timeout>();
  const cancel = useCallback(
    () => {
      const timeoutId = timeoutIdRef.current;
      if (timeoutId) {
        timeoutIdRef.current = undefined;
        clearTimeout(timeoutId);
      }
    },
    [timeoutIdRef],
  );

  useEffect(
    () => {
      // @ts-ignore
      timeoutIdRef.current = setTimeout(callback, timeout * 1000);
      return cancel;
    },
    [callback, timeout, cancel],
  );

  return cancel;
};
