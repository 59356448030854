import { SceneInterface } from "../../../types";

export const addUnique = (
  scenes: SceneInterface[],
  newScenes: SceneInterface[]
): SceneInterface[] => {
  const sceneIds = scenes.map(({id}) => id);
  const filtered = newScenes.filter(({id}) => sceneIds.indexOf(id) === -1);
  return scenes.concat( filtered )
};
