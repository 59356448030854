import styled from "styled-components";
import submit_img from "../assets/img/contact/submit.png";
import submit_bg_img from "../assets/img/contact/submit_bg.png";
import headline_img from "../assets/img/contact/spucks-aus.png";

export const Container = styled.div`
  display: flex;
  overflow: hidden;
  height: 100vh;
  min-height: 583px;
  align-items: center;
  justify-content: center;
  perspective: 1000px;
`;

export const Card = styled.div`
  position: relative;
  height: 100vh;
  width: 158vh;
  padding: 7% 10% 8% 7%;
  @media (min-width: 768px) {
    padding-right: 15%;
  }
  @media (min-width: 992px) {
    width: 800px;
    height: 563px;
    padding: 60px 110px 60px 60px;
  }
`;

export const CardContent = styled.div`

`;

export const CardBackground = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: -1;
`;

export const Headline = styled.div`
  background-image: url(${ headline_img });
  background-size: contain;
  background-repeat: no-repeat;
  height: 140px;
`;

export const Form = styled.form`
  display: flex;
`;

export const InputContainer = styled.div`
  flex: 1 1 auto;
  padding: 0 50px 0 20px;
`;

export const ButtonContainer = styled.div`
  align-self: flex-end;
`;

export const TextInputContainer = styled.div`
  position: relative;
  margin-bottom: 40px;
  height: 200px;
`;

export const MailContainerInput = styled.div`
  position: relative;
  margin-bottom: 40px;
  height: 45px;
`;

export const MailInput = styled.input`
  position: absolute;
  top: 2px;
  left: 0;
  height: 40px;
  width: 100%;
  border: none;
  margin-bottom: 10px;
  font-family: "Titillium Web", sans-serif;
  font-size: 12pt;
  &:focus {
    outline: none;
  }
`;

export const TextArea = styled.textarea`
  position:absolute;
  top: 10px;
  left: 0;
  width: 100%;
  height: 170px;
  border: none;
  margin-bottom: 10px;
  font-family: "Titillium Web", sans-serif;
  font-size: 12pt;
  &:focus {
    outline: none;
  }
`;

export const SubmitButton = styled.button<{empty?: boolean}>`
  position: relative;
  background: url(${props => props.empty ? submit_bg_img : submit_img}) no-repeat center;
  //background: url(${ submit_img }) no-repeat center;
  background-size: contain;
  color: transparent;
  width: 100px;
  height: 60px;
  bottom: 25px;
  border: none;
  &:focus {
    outline: none;
  }
`;

export const Stage = styled.div`
  position: fixed;
  pointer-events: none;
  width: 100vw;
  height: 300px;
  bottom: 0;
  left: 0;
  display: flex;
  z-index: 500;
`;

export const Scooter = styled.div`
  display: none;
  img {
    width: 300px;
    transform-origin: 60% 50%;
    position: relative;
    left: -15%;
  }
`;

export const Error = styled.div`
  display: none;
  transform: rotateY(180deg);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const ErrorContent = styled.div`
  color: black;
  padding-top: 80px;
  align-self: center;
  position: relative;
  font-size: 2rem;
  font-family: "Titillium Web", sans-serif;
  font-weight: bold;
  display: inline-flex;
  text-transform: uppercase;
`;


