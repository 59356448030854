import { useComponentMount } from "../assets/useComponentMount";
import { useSession } from "./index";
import { createStateFromStorage } from "./createStateFromStorage";

export const useSessionStart = () => {
  const { update } = useSession();

  useComponentMount( () => {
    const newState = createStateFromStorage();
    update( newState, 'INITIALIZE' );
  } )
};
