import styled from "styled-components";
import { Colors } from "../../constants/colors";

export const Container = styled.div`
  position:absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: ${ Colors.PINK };
  padding: 40px;
  font-family: "Titillium Web", sans-serif;
  font-size: 24pt;
  z-index: 500;
`;

export const Content = styled.div`
  max-width: 600px;
`;

export const Understood = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
`;
