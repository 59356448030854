import { ShapeConstructor } from "../constructor/ShapeConstructor";

export const chat_answer = () =>
  new ShapeConstructor()
    .chat_answer({ fill: 'white'})
    .add()
    .grow( { fill: 'black', y: 30 } )
    .randomize()
    .add();

export const chat_bubble = () =>
  new ShapeConstructor()
    .chat_bubble({fill: 'white'})
    .add()
    .grow( { fill: 'black' } )
    .randomize()
    .add();

export const arrow = () =>
  new ShapeConstructor()
    .arrow()
    .add()
    .grow( { fill: 'white' } )
    .add()
    .grow( { fill: 'black' } )
    .randomize()
    .add();

export const rectangle = () =>
  new ShapeConstructor()
    .rectangle()
    .randomize({x: 5, y: 8})
    .add()
    .grow( { fill: 'white', x: 1, y: 2 } )
    .randomize()
    .add();
