export enum Hooks {
  START = 'about me new',
  BEGINNING = 'BEGINNING',
  FETISH_GOOD = 'FETISH_GOOD',
  FETISH_BAD = 'FETISH_BAD',
  AAARRGGHH = 'AAARRGGHH',
  HATE_CHATBOTS = 'HATE_CHATBOTS',
  ABORT_DONE = 'ABORT_DONE',
  OH_YES_BEAUTIFUL = 'OH_YES_BEAUTIFUL',
  ARE_YOU_BROKEN = 'ARE_YOU_BROKEN',
  SPEAK_STUPID = 'SPEAK_STUPID',
  IS_THIS_A_JOKE = 'IS_THIS_A_JOKE',
  JOKE_NO = 'JOKE_NO',
  IS_JOKE_ENTRY = 'IS_JOKE_ENTRY',
  STOP_IT_DAMN = 'STOP_IT_DAMN',
  ETSY_SUPER = 'ETSY_SUPER',
  ETSY_SUCKS = 'ETSY_SUCKS',
  CONTACT_ME_GO = 'CONTACT_ME_GO',
  GAMES_AND_VIOLIN = 'GAMES_AND_VIOLIN',
  LIVE_GRAPHIC_NOVEL = 'LIVE_GRAPHIC_NOVEL',
  BORN_IN_BAD_KREUZNACH = 'BORN_IN_BAD_KREUZNACH',
  WRONG_NUMBER = 'WRONG_NUMBER',
  I_WAS_HURT = 'I_WAS_HURT',
  MEAT_FIGURE = 'MEAT_FIGURE',
  WHERE_SHOULD_THIS_LEAD = 'WHERE_SHOULD_THIS_LEAD',
  I_FEEL_STRANGE = 'I_FEEL_STRANGE',
  // IS_THIS_LOVE = 'IS_THIS_LOVE',
  I_THINK_I_LOVE_YOU = 'I_THINK_I_LOVE_YOU',
  I_FEEL_HATE = 'I_FEEL_HATE',
  SO_WHAT_NOW = 'SO_WHAT_NOW',
  I_MUST_DESTROY = 'I_MUST_DESTROY',
  DARK_SIDE = 'DARK_SIDE',
  I_THOUGHT_THIS_IS_SPECIAL = 'I_THOUGHT_THIS_IS_SPECIAL',
  COLD_DOG = 'COLD_DOG',
  FEEDBACK_PLEASE = 'FEEDBACK_PLEASE',
  DO_YOU_HAVE_COLDS = 'DO_YOU_HAVE_COLDS',
  SINAN = 'SINAN',
  TRY_AGAIN = 'TRY_AGAIN',
  TRY_AGAIN_FRIENDLY = 'TRY_AGAIN_FRIENDLY',
  CHAT_BOT = 'CHAT_BOT',
  AGREE_IS_THERE_MORE = 'AGREE_IS_THERE_MORE',
  SUPER_DEEP_VOICE = 'SUPER_DEEP_VOICE',
  BENTON_WHAT_DO_YOU_WANT = 'BENTON_WHAT_DO_YOU_WANT',
  BENTON_WHAT_NEXT = 'BENTON_WHAT_NEXT',
  BENTON_FUN_AND_BEAUTIFUL = 'BENTON_FUN_AND_BEAUTIFUL',
  BENTON_STALKING = 'BENTON_STALKING',
  ANNOYING_SINCE_BIRTH = 'ANNOYING_SINCE_BIRTH',
  PAINTED_MYSELF = 'PAINTED_MYSELF',
  BENTON_TAKES_OVER = 'BENTON_TAKES_OVER',
  GAMES_ART_AND_ANIMATION = 'GAMES_ART_AND_ANIMATION',
  PLACEHOLDER = 'PLACEHOLDER',
  WHAT_DO_YOU_WANT_TO_KNOW = 'WHAT_DO_YOU_WANT_TO_KNOW',
  BOOK = 'BOOK',
  SINAN_HOPE_YOU_LIKE_IT = 'SINAN_HOPE_YOU_LIKE_IT',
  BOOK_SINAN = 'BOOK_SINAN',
  SINAN_LIVE_GRAPHIC_NOVEL = 'SINAN_LIVE_GRAPHIC_NOVEL',
  SINAN_OVERHEAD = 'SINAN_OVERHEAD',
  SINAN_ETSY = 'SINAN_ETSY',
  SINAN_PETS = 'SINAN_PETS',
  DID_YOU_FIGHT = 'DID_YOU_FIGHT',
  HOBBIES = 'HOBBIES',
  SINAN_KNOW_MORE = 'SINAN_KNOW_MORE',
  WHO_IS_SINAN = 'WHO_IS_SINAN',
  WIFE = 'WIFE',
  FAMILY = 'FAMILY',
  SINAN_TAKES_OVER = 'SINAN_TAKES_OVER',
  SINAN_TAKES_OVER2 = 'SINAN_TAKES_OVER2',
  MARLENE_TAKES_OVER = 'MARLENE_TAKES_OVER',
  TOTALLY_MEAN = 'TOTALLY_MEAN',
  HIHI_TRUE = 'HIHI_TRUE',
  START_REPEATING = 'START_REPEATING',
  HIDING = 'HIDING',
  I_WANT_TO_RUN_TOO = 'I_WANT_TO_RUN_TOO',
  LITTLE_BIRDY = 'LITTLE_BIRDY',
  KEEP_SECRET = 'KEEP_SECRET',
  NOT_ALLOWED_TO_CHAT = 'NOT_ALLOWED_TO_CHAT',
  SINAN_TAKES_OVER_MARLENE = 'SINAN_TAKES_OVER_MARLENE',
  USE_MOBILE = 'USE_MOBILE',
  HEAR_BIBI = 'HEAR_BIBI',
  BIBI = 'BIBI',
  OUCH = 'OUCH',
  TAEKWONDO = 'TAEKWONDO',
  YOU_CAUGHT_ME = 'YOU_CAUGHT_ME',
  SILENCE = 'SILENCE',
  DISTRACTED_ME = 'DISTRACTED_ME',
  MARLENE_KNOWS_TAEKWONDO = 'MARLENE_KNOWS_TAEKWONDO',
  EVERYTHING_UNDER_CONTROL = 'EVERYTHING_UNDER_CONTROL',
  SOUNDED_DEVIOUS = 'SOUNDED_DEVIOUS',
  FIGHT = 'FIGHT',
  TUNNEL = 'TUNNEL',
  WHAT = 'WHAT',
  EXHIBITION = 'EXHIBITION',
  MET_TIC_TAC_TOE = 'MET_TIC_TAC_TOE',
  ABOUT_MYSELF = 'ABOUT_MYSELF',
  // RUN_FAST = 'RUN_FAST',

}
