import ReactGA from 'react-ga';
import { useState } from "react";

export const useCookieConsent = (has_agreed: boolean) => {
  const [initial, setInitial] = useState(true);

  if (has_agreed && initial) {
    setInitial(false);
    ReactGA.initialize( 'UA-155194811-1');
    ReactGA.pageview( window.location.pathname + window.location.search );
  }
};
