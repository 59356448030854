export const write = (id: string, content: any) => {
  // Content needs to be converted to string here
  let converted = content;
  if (typeof content === 'object') {
    converted = JSON.stringify(content)
  }
  localStorage.setItem(id, converted)
};

export const read = (id: string) =>
  JSON.parse(localStorage.getItem(id) || 'null');
