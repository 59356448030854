import { ChoiceProps } from "../../types";

type Return = {
  type: 'ANSWER',
  choices: ChoiceProps[]
}

export const createSingleChoice = (text: string): Return => ({
  "type": "ANSWER",
  "choices": [{
    text
  }]
});
