import { SessionState, ValidatableScene } from "./types";

export const hasBeenPlayed = (
  optional: ValidatableScene,
  session: SessionState
) => {
  const { id = '' } = optional.scene;
  const filtered = new Set( session.filtered_scenes );
  const played = new Set( session.played );

  return filtered.has( id ) || played.has( id )
};