import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";
import { totally_mean } from "./totally_mean";

const { next, choices, end } = create;

export const hihi_true: (FrameProps | string)[] = [
  'Hihi ja das stimmt.',

  choices(Hooks.HIHI_TRUE, [
    'Hihi ja das stimmt.',
    'Hihihi ja das stimmt.'
  ]),

  'HAHA AUCH NOCH FALSCH!',
  'Oh nein, mein Papa hat mich gehört!!!',
  end('Marlene hat das Handy fallen lassen und zerstört.'),

  ...next(Hooks.HIHI_TRUE, totally_mean)
];
