import { Pages } from "../../constants/Pages";
import Dialog_Text from "../../constants/Dialog_Text";
import { SceneProps } from "../../scene/types";
import { DialogImage } from "../../constants/dialogImages";
import { greeting } from "../../scene/stories/greeting";
import { OptionalScenes } from "../../assets/useOptionalScenes/types";
import {validateOptionalScenes} from "../../assets/useOptionalScenes/validateOptionalScenes";
import {useSceneContext} from "../../context";
import {useSession} from "../../session";

const scene2 = {
  id: 'visit 2',
  frames: [ { text: Dialog_Text.VISIT_TIMES_2 } ]
};

const scene5: SceneProps = {
  id: 'visit 5',
  image: DialogImage.BENTE_SMIRK,
  frames: [ { text: Dialog_Text.VISIT_TIMES_5 } ]
};

const optionalScenes: OptionalScenes = [ [
  greeting,
  {
    scene: scene2,
    times_visited: 2
  },
  {
    scene: scene5,
    times_visited: 5
  }
] ];

export const useHomeConfig = () => {
  // const localScenes = useOptionalScenes( optionalScenes );
  const session = useSession();
  const scene = useSceneContext();
  const localScenes = validateOptionalScenes(optionalScenes, {...session, ...scene});
  // console.log('localScenes', localScenes)

  return {
    localScenes,
    origin: Pages.HOME,
  };
};
