import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Shape, ShapeConstructor } from "../../shapes";
import { Config, LetterType } from "./types";

interface Props extends LetterType {
  config: Config
}

type Style = {
  displace: boolean;
  rotation: number
}

const Container = styled.div<Style>`
  position: relative;
  display: flex;
  bottom: ${props => props.displace ? '2px' : 0};
  transform: rotate(${props => props.rotation}deg);
`;

const InvertedLetter: React.FC<Props> = (
  {
    letter,
    rotate,
    displace,
    config
  }
) => {
  const color = {
    fill: config.invert ? 'black' : 'white',
    text: !config.invert ? 'black' : 'white' as 'black' | 'white',
  };

  const form = useMemo( () => new ShapeConstructor({width: 15, height: 20})
      .rectangle({fill: color.fill})
      .randomize( { x: 3, y: 2 } )
    , [color.fill] );

  const rotation = useMemo(() => {
  const random_prefix = Math.random() > .5 ? 1 : -1;
    return rotate ? 14 * random_prefix : 0
  }, [rotate]);

  return (
    <Container
      displace={ config.displace ? displace : false }
      rotation={ config.rotate ? rotation : 0 }
    >
      <Shape form={form} textColor={color.text}>
        {letter}
      </Shape>
    </Container>
  );
};

export default InvertedLetter
