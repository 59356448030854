import React, { useMemo } from 'react';
import styled from 'styled-components';
import Letter from "./Letter";
import InvertedLetter from "./InvertedLetter";
import { Config, LetterType } from "./types";

interface Props {
  word: LetterType[];
  config: Config;
}

const Container = styled.div`
  display: flex;
  padding: 0 4px;
`;

const Word: React.FC<Props> = ({ word, config }) => {
  const letterMap = useMemo( () =>
    word.map( (letter, i) => {
      let LetterType = InvertedLetter;
      if ( !letter.invert ) {
        LetterType = Letter;
      }

      return <LetterType
        { ...letter }
        key={ i }
        config={config}
      />
    } ), [config, word] );

  return (
    <Container>
      { letterMap }
    </Container>
  );
};

export default Word
