import { Colors } from "./colors";


export enum Pages {
  HOME = '/home',
  PORTFOLIO = '/portfolio',
  BOOK = '/herzschlag_ins_gesicht',
  ABOUT = '/about',
  CONTACT = '/contact',
  IMPRINT = '/imprint',
  IT_CELLAR = '/it_cellar'
}

export enum PageColors {
  HOME = Colors.YELLOW,
  PORTFOLIO = Colors.PINK,
  ABOUT = Colors.YELLOW,
  CONTACT = Colors.YELLOW,
  IMPRINT = Colors.WHITE,
  IT_CELLAR = Colors.GREY,
}
