import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";
import { ouch } from "./ouch";

const { next, choices, end } = create;

export const taekwondo: (FrameProps | string)[] = [
    'Ja das stimmt. Guck. KIAB!!!',

  choices(Hooks.TAEKWONDO, [
    'Wow, das war beeindruckend!',
    'Aua!'
  ]),

  ...ouch,

  ...next(Hooks.TAEKWONDO, [
    'Danke. Aber jetzt muss ich schnell los! Tschüüüüss!',
    end('Marlene hat den Chat verlassen.')
  ])
];
