import React from 'react';
import { useChat } from "../store/useChat";
import MessageList from "./MessageList";
import { MessageContainer } from "./styles";

const Messages = () => {
  const { messages } = useChat();

  return (
    <MessageContainer>
      <MessageList messages={ messages } />
    </MessageContainer>
  );
};

export default Messages
