import { useCallback, useMemo } from "react";
import { Message, Status } from './types';
import { useScene } from "../../index";
import { SceneType } from "../../types";
import { useTarget } from "../../hooks/useTarget";
import { id } from "../../../utilz/id";
import { useChatContext } from "../../../context";

export const useChat = () => {
  const {
    messages,
    status,
    target,
    answer,
    reset,
    provideAnswer,
    awaitAnswer,
    addMessage: addMessageMethod,
    setReady: setReadyMethod,
  } = useChatContext();
  const { getNextFrame, onProgress, getCurrentScene } = useScene();
  const getTarget = useTarget();

  const next_frame = useMemo(() => {
    const {id} = getTarget(target);
    const next_already_exists = messages.filter(el => el.id === id)[0];
    const next = getNextFrame( id );

    return next_already_exists || next
  }, [getNextFrame, getTarget, messages, target]);

  const is_chat = useMemo(() =>
    getCurrentScene().type === SceneType.CHAT
  , [getCurrentScene]);

  const is_end = useMemo(() =>
    getTarget().break
  , [getTarget]);

  const add_message = useMemo( () =>
    is_chat && !is_end
  , [is_chat, is_end] );

  const onAnswer = (target: string, answer: string) => {
    provideAnswer(answer, target);
  };

  const addMessage = useCallback( (message: Message) => {
    const messageSet = new Set(messages);

    const newMessage = !messageSet.has(message)
      ? message
      : {
        ...message,
        id: message.id + id()
      };

    if ( add_message ) {
      addMessageMethod(newMessage);
    }
  }, [addMessageMethod, add_message, messages] );

  // const setReady = useCallback( () => {
  //   setReadyMethod();
  // }, [ setReadyMethod ] );

  const onProgressChat = () => {
    if ( status !== Status.AWAITING_ANSWER ) {
      addMessage( next_frame as Message );
      onProgress( target );
      setReadyMethod();
    }
  };

  return {
    onProgress: onProgressChat,
    onAnswer,
    messages,
    addMessage,
    answer,
    status,
    reset,
    awaitAnswer,
  }
};
