import { createMultipleChoices } from "../../../../../create/createMultipleChoices";
import { Hooks } from "../../../../Hooks";
import { next } from "../../../../../create/next";
import { restart } from "../../../../../create/restart";

export const is_this_a_joke = [
  'Ich bin ein Chatbot. Ich verstehen keinen Spaß.',
  'Ich will lieber noch mal von vorne.',
  {
    id: Hooks.IS_THIS_A_JOKE,
    text: 'Ist das ein Spaß?'
  },
  createMultipleChoices(
    [Hooks.JOKE_NO, 'Nein.'],
    [Hooks.IS_THIS_A_JOKE, 'Ja.'],
  ),
  ...next(Hooks.JOKE_NO, [
    'Sehr wohl.',
    restart('<restartchat=initiate>')
  ]),
];
