import styled from "styled-components";
import { size } from "../../assets/breakpoints/sizes";
import { Colors } from "../../constants/colors";
import {settings} from "../../config";

export const displaceChatY = '100%';

export const ChatPage = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 500;
`;

export const Container = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  transform: translate(0, ${ displaceChatY });
  @media ${ size.lg } {
    height: 107vh;
    width: 720px;
    left: 200px;
  }
`;
export const MobileContainer = styled.div`
  display: none;
  pointer-events: none;
  position: relative;
  top: -20px;
  width: 100%;
  height: 100%;
  @media ${ size.lg } { 
    display: block;
  }
`;
export const ChatWrapper = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  @media ${ size.lg } { 
    left: 10%;
    width: 79%;
    height: 93%;
    top: auto;
    bottom: 0;
  }
`;

export const ChatScreen = styled.div`
  position: relative;
  padding: 0 30px ${settings.chat_distance_bottom}px 30px;
  left: 20%;
  width: 100%;
  height: 100%;
  max-width: 400px;
  transform: translateX(-50%);
  @media ${ size.lg } {
    left: auto;
    transform: none;
    max-width: none;
  }
`;

export const ChatBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 150%;
  background-color: ${ Colors.PINK };
  z-index: -1;
`;

export const ChatResponse = styled.div<{show: boolean}>`
  position: relative;
  display: flex;
  width: 400px;
  max-height: 0;
  visibility: hidden;
  margin-bottom: 30px;
  transition: max-height 0.3s ease-out;
`;

export const Message = styled(ChatResponse)`
  left: 0;
  right: auto;
  :nth-child(even) {
    left: 40px;
  }
`;

export const Answer = styled(ChatResponse)`
  left: auto;
  right: -100px;
  height: auto;
  // crappy solution
  opacity: ${props => props.show ? 1 : 0};
`;

export const Avatar = styled.div`
  position: relative;
  width: 100px;
  height: auto;
  flex: 0 0 100px;
`;
export const AvatarFace = styled.img`
  position: relative;
  width: 100px;
  height: auto;
  flex: 0 0 100px;
  top: 0;
  left: 0;
  max-width: 100%;
  transform-origin: 50% 80%;
`;
// the same as Face?
export const AvatarBg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  transform-origin: 50% 80%;
  max-width: 100%;
  height: auto;
`;
export const MessageText = styled.div<{ reverse?: boolean }>`
  flex: 1 1 auto;
  display: flex;
  flex-direction: ${ props => props.reverse ? 'row-reverse' : 'row' };
  justify-content: center;
  align-items: center;
`;

export const Loading = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
`;

export const ChatPathContainer = styled.div`
  position: absolute;
  top: 0;
  left: 45px;
  width: 100%;
`;
