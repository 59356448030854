import styled from "styled-components";
import * as displace from './displace';


export const Container = styled.div`
  position: absolute;
  top: 0;
  right: -10%;
  height: 100vh;
  width: 50%;
  background-color: white;
  transform: skewX(-6deg) translateX(${displace.boxX});
  z-index: 2;
`;

export const Black = styled.div`
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: -1;
  position: relative;
  transform: skewX(-2deg);
  left: 3%;
`;

export const Overlay = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #f123c9;
  transform: translateX(${displace.boxX});
  opacity: 0.9;
  z-index: 1;
`;
