import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";
import { taekwondo } from "./taekwondo";
import { bibi } from "./bibi";

const { next, choices } = create;

export const you_caught_me: (FrameProps | string)[] = [
  'Oh nein du hast mich entdeckt!',

  choices(Hooks.YOU_CAUGHT_ME, [
    'Ne, DU hast MICH entdeckt.',
    'Ich hab gehört du kannst Taekwondo'
  ]),

  ...taekwondo,

  ...next(Hooks.YOU_CAUGHT_ME, bibi)
];
