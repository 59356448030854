import { createMultipleChoices } from "../../../../../create/createMultipleChoices";
import { Hooks } from "../../../../Hooks";
import { next } from "../../../../../create/next";
import { art_is_no_work } from "../art_is_no_work/art_is_no_work";

export const etsy_shop = [
  'Auch reich wird sie, wenn du ihre Artikel in ihrem Etsy Shop kaufst ;o)',
  createMultipleChoices(
    [Hooks.ETSY_SUPER, 'Oh, das klingt super!'],
    [Hooks.ETSY_SUCKS, 'Ne du, lass mal. Kunst ist keine Arbeit.'],
  ),
  ...next(Hooks.ETSY_SUPER, [
    'Ja, du findest ihren Shop im Hauptmenü dieser Website.',
    '*beep boop* Es freut mich dass ich helfen konnte.',
    'Der Chat wird nun beendet um Ihnen die Zeit zum Geldausgeben zu ermöglichen.',
    'Der BenteBot hat den Chat verlassen.'
  ],
    true),
  ...next(Hooks.ETSY_SUCKS, art_is_no_work)
];