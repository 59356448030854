import { Ref } from "../types/Ref";
import { useCallback, useState } from "react";
import { isReady } from "./utilz/isReady";

export type Refs = { [index: string]: Ref }

export type SetDomNodes = (refs: Refs) => void;

export interface ReturnRefs {
  is_ready: boolean;
  domNodes: Refs;
}

type ReturnValue = [ ReturnRefs, SetDomNodes ];

let storedRefs: Refs = {};

export const useDomNodes = (): ReturnValue => {

  const [ state, setState ] = useState<Refs>( {} );

  const getReturnRefs = () => {
    let result = {};
    for ( let i in state ) {
      result = {
        ...result,
        [i]: state[i].current
      }
    }
    return result
  };

  const setRefs = useCallback( (refs: Refs) => {
    const newRefs = {
      ...storedRefs,
      ...refs,
    };

    setState( newRefs )
  }, [] );

  const refs = {
    is_ready: isReady( state ),
    domNodes: getReturnRefs()
  };

  return [
    refs,
    setRefs
  ]
};
