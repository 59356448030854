import { createContainer } from "unstated-next";
import { useState } from "react";
import { Message, Status } from "../scene/Chat/store/types";

const useChatBase = () => {
  const [ messages, setMessages ] = useState<Message[]>( [] );
  const [ status, setStatus ] = useState<Status>( Status.READY );
  const [ answer, setAnswer ] = useState( '' );
  const [ target, setTarget ] = useState( '' );

  const addMessage = (message: Message) => setMessages(messages.concat(message));
  const awaitAnswer = () => {
    setStatus(Status.AWAITING_ANSWER);
    setAnswer('');
  };
  const provideAnswer = (answer: string, target = '') => {
    setStatus(Status.ANSWER_GIVEN);
    setAnswer(answer);
    setTarget(target);
  }
  const answerGiven = () => setStatus(Status.ANSWER_GIVEN);
  const setReady = () => {
    setStatus(Status.READY);
    setAnswer('');
    setTarget('');
  }

  const reset = () => {
    setMessages([]);
    setStatus(Status.READY);
    setAnswer('');
    setTarget('');
  }

  return {
    messages,
    status,
    answer,
    target,
    addMessage,
    awaitAnswer,
    provideAnswer,
    answerGiven,
    setReady,
    reset,
  };
}

const useChatContainer = createContainer( useChatBase );
export const useChatContext = useChatContainer.useContainer;
export const ChatProvider = useChatContainer.Provider;
