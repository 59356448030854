import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";
import { stalking } from "./stalking";
import { fun_and_beautiful } from "./fun_and_beautiful";
import { super_deep_voice } from "./super_deep_voice";

const { next, choices } = create;

export const annoying_since_birth: (FrameProps | string)[] = [
  'Kann ich verstehen. Die nervt mich auch seit sie am 10.11.88 in Bad Kreuznach geboren wurde.',

  choices(Hooks.ANNOYING_SINCE_BIRTH, [
    'Du hast aber einen hübschen Schnurbart!',
    'Und wo wohnt die jetzt?'
  ]),

  ...stalking,

  ...next(Hooks.ANNOYING_SINCE_BIRTH, ['Danke, hab ich selbst gemalt.']),

  choices(Hooks.PAINTED_MYSELF, [
    'Bist du wirklich Bentes Bruder?',
    'Wie ist Bente so privat?'
  ]),

  ...fun_and_beautiful,

  ...next(Hooks.PAINTED_MYSELF, super_deep_voice)
];
