import { createChoices } from "../../../../../create/createChoices";
import { Hooks } from "../../../../Hooks";
import { next } from "../../../../../create/next";
import { contact_me } from "./contact_me";
import {SceneEvent} from "../../../../../../types";

export const games_and_violin = [
  'Spielen Videospiele und Geige sowie zeichnen.',
  createChoices(
    Hooks.GAMES_AND_VIOLIN,
    ['Okay, danke.', 'Macht Bente auch Auftragsarbeiten?'],
    true
  ),
  ...next('', [
    'Ich freue mich, dass ich Ihnen von Hilfe sein konnte.',
    'Bitte bewerten Sie im Anschluss Ihre Erfahrung mit dem BenteBot.',
    {
      text: 'Chat beendet.',
      event: SceneEvent.RATING
    }
  ], true),
  ...next(Hooks.GAMES_AND_VIOLIN, contact_me),
];