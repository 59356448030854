import create from "scene/stories/create";
import { FrameProps } from "scene/types";
import { ChatImage } from "../../../../../../../../constants/chatImages";
import { not_allowed_to_chat } from "./not_allowed_to_chat/not_allowed_to_chat";
import { you_caught_me } from "./you_caught_me";
import { Hooks } from "../../../../../Hooks";

const { next, choices, defaultImage } = create;

const marlene_takes_over_scene: (FrameProps | string)[] = [
  'Marlene hat den Chat betreten',
  'Huuuch... hihihi',

  choices(Hooks.MARLENE_TAKES_OVER, [
    'Was machst du denn hier?',
    'Hallo?'
  ]),

  ...you_caught_me,

  ...next(Hooks.MARLENE_TAKES_OVER, not_allowed_to_chat)
];

export const marlene_takes_over = defaultImage(ChatImage.MARLENE, marlene_takes_over_scene);
