import React, { Fragment } from 'react';
import Frame from "./Frame";
import Arrow from "./Arrow";
import { Container, FullScreen, Image } from "./SliderStyle";
import Close from "./Close";
import Indexing from "./Indexing";
import { useApi } from "../useApi";
import { usePortfolio } from "../../context";

interface Props {
  fullScreen?: boolean;
  show?: boolean;
  onClose?(): void;
  image: string;
}

const Slider: React.FC<Props> = (
  {
    fullScreen,
    show,
    onClose,
    image
  }
  ) => {
  const { openImage, setOpenImage } = usePortfolio();
  const {getImages} = useApi();
  const images = getImages();
  const getPrev = () => openImage > 0 ? openImage - 1 : images.length - 1;
  const getNext = () => openImage + 1 < images.length ? openImage + 1 : 0;
  const setImageIndex = (image: number) => setOpenImage(image);
  const onPrev = () => setImageIndex(getPrev());
  const onNext = () => setImageIndex(getNext());

  const Wrapper = fullScreen ? FullScreen : Fragment;

  if (show === false) return null;

  return (
    <Wrapper id='Slider'>
      <Container>
        <Frame index={openImage}>
          <Image src={ image } alt="" />
          <Indexing index={openImage} length={images.length} />
        </Frame>
        <Arrow onClick={onPrev} left />
        <Arrow onClick={onNext} />
        { !!onClose &&
          <Close onClick={ onClose } />
        }
      </Container>
    </Wrapper>
  );
};

export default Slider
