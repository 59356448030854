import { chunk_length } from "./constants";

export const calcInverts = (text: string) => {
  const list = text.split(' ');

  return list.map((word, i) => {
    const max_available = Math.floor(word.length / chunk_length);
    const certain_inverts = !i ? 1 : 0;

    const possible_inverts = !!i
      ? max_available
      : !!max_available
        ? max_available
        : 1;

    return {
      word,
      possible_inverts,
      certain_inverts
    }
  })
};
