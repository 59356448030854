export enum TransitionAction {
  CLICK = 'CLICK',
  RESOLVE = 'RESOLVE',
  REJECT = 'REJECT'
}

export type FiniteState = "loading" | "success" | "error" | "idle";

const machine = {
  idle: {
    CLICK: 'loading'
  },
  loading: {
    RESOLVE: 'success',
    REJECT: 'error',
  },
  success: {
    CLICK: 'loading'
  },
  error: {
    CLICK: 'loading'
  },
};

export const initialState = 'idle';

export const nextState = (state: keyof typeof machine, action: TransitionAction): FiniteState => {
  //@ts-ignore
  return machine[state][action];
};
