import React from "react";
import styled, { keyframes } from "styled-components";

interface Props {
  circleRef: any;
  color?: string;
  delay?: number;
}

const fadeIn = keyframes`
  0%, 40%, 100% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
`;

const Container = styled.div<{delay: number}>`
  position: relative;
  width: 10px;
  height: 10px;
  margin: 0 4px;
  animation: 1.9s ${fadeIn} infinite ${props => props.delay}s;
`;

export const Circle: React.FC<Props> = (
  {
    circleRef,
    color = "#f124c9",
    delay = 0
  }
  ) => (
  <Container ref={circleRef} delay={delay}>
    <svg
      width="100%"
      height="100%"
    >
      <circle
        cx="50%"
        cy="50%"
        r="50%"
        preserveAspectRatio="xMinYMin meet"
        fill={color}
      />
    </svg>
  </Container>
);

export default Circle;
