import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";

const { next, choices, end } = create;

export const hope_you_like_it: (FrameProps | string)[] = [
  'Wow, danke! Ich hoffe, es gefällt dir.',
  choices(Hooks.SINAN_HOPE_YOU_LIKE_IT, [
    'Ja, richtig geil!',
    'Unglaublich fantastisch!!!'
  ]),

  'Wow du bist ein richtiger Fan! Danke! Ich richte es Bente aus :)',
  'Also, mach’s gut!',
  end('Sinan hat den Chat verlassen.'),

  ...next(Hooks.SINAN_HOPE_YOU_LIKE_IT, [
    'Yeah! Das freut mich. Also, danke und mach’s gut!',
    end('Sinan hat den Chat verlassen.')
  ])
];
