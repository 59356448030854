import { createChoices } from "../../../../create/createChoices";
import { Hooks } from "../../../Hooks";
import { next } from "../../../../create/next";
import { createMultipleChoices } from "../../../../create/createMultipleChoices";
import { restart } from "../../../../create/restart";

export const wrong_number = [
  'Ich... äh... *beepboop* es tut mir leid, Sie sind falsch verbunden.',
  createChoices(
    Hooks.WRONG_NUMBER,
    ['Komm zurück!', 'Achso! Wie peinlich'],

  ),
  ...next('', [
    'Ja voll.',
  ]),
  createMultipleChoices(['', 'Kann ich noch mal von vorn?']),
  restart('Besser ist es.'),
  ...next(Hooks.WRONG_NUMBER, ['*beep* <emergencyexit=initiate>', 'Chat beendet'], true),
];
