import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
`;

export const Side = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  flex: 0 0 270px;
  flex-direction: column;
  padding: 20px;
`;

export const Center = styled.div`
  height: 100%;
  flex: 1 1 auto;
`;
