import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import Explosion from "./Explosion";
import { SceneEvent } from "../types";
import Lightning from "./Lightning";
import Rating from "./Rating";
import AgreeDsgvo from "./AgreeDsgvo";
import { useScene } from "../store";

const Container = styled.div<{show: boolean}>`
  position: relative;
  display: ${props => props.show ? 'flex' : 'none'};
  width: 100vw;
  height: 100vh;
  pointer-events: initial;
  z-index: 1100;
`;

const Event = () => {
  const {event, resetEvent} = useScene();
  // const event = useSelector( (state: Store) => state.sequence.event );

  const [show, setShow] = useState(!!event.length);

  const endEvent = useCallback(() => {
    resetEvent();
    // dispatch( { type: 'RESET_EVENT' } )
  }, [resetEvent]);

  useEffect(() => {
    if (event && !show) {
      setShow(true);
    }
  }, [event, show, setShow]);

  useEffect(() => {
    if (!event.length && show) {
      setShow(false)
    }
  }, [event.length, show]);

  const Type = useMemo(() => {
    switch(event) {
      case (SceneEvent.EXPLOSION):
        return Explosion;
      case (SceneEvent.LIGHTNING):
        return Lightning;
      case (SceneEvent.RATING):
        return Rating;
      case (SceneEvent.COOKIE_CONSENT):
        return AgreeDsgvo;
      default :
        return Explosion
    }
  }, [event]);

  return (
    <Container id="Event" show={show}>
      {show  && <Type endEvent={endEvent} />}
    </Container>
  );
};

export default Event
