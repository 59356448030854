import { calcCertainInverts } from "./calcCertainInverts";
import { LetterType } from "../types";

const chances = {
  rotation: .2,
  displacement: .2,
  invert: .1
};

export const calcInvertPositions = (
  word: string,
  possible_inverts: number,
  certain_inverts: number,
): LetterType[] => {
  const possible = calcPossiblePositions( word, possible_inverts );

  if ( !certain_inverts ) {
    return possible
  }

  return calcCertainInverts( possible, certain_inverts )
};


export const calcPossiblePositions = (
  word: string,
  possible_inverts: number,
): LetterType[] => {
  let remaining_inverts = possible_inverts;
  let inverted_last_letter = false;


  return word.split( '' ).map( letter => {
    let invert: boolean = false,
      rotate: boolean = Math.random() <= chances.rotation,
      displace: boolean = Math.random() <= chances.displacement;

    let result = {
      letter,
      invert,
      rotate,
      displace
    };

    if ( remaining_inverts ) {
      invert = Math.random() <= chances.invert;
      if ( invert && !inverted_last_letter ) {
        inverted_last_letter = true;
        remaining_inverts--;
        result = {
          ...result,
          invert
        }
      } else {
        inverted_last_letter = false
      }
      return result
    }

    return result
  } );
};
