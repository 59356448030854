export const feedback = (rating: number) => {
  switch(rating) {
    case(1):
      return 'Falsch!';
    case(2):
      return 'Hey, was soll das?';
    case(3):
      return 'Versuchs nochmal!';
    case(4):
      return 'Warm aber noch nicht heiß';
    case(5):
      return 'Danke!';
    default :
      return ''
  }
};
