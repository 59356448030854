import React, { useMemo } from 'react';
import { usePersonaTextStyle } from "./usePersonaTextStyle";
import Word from "./Word";

interface Props {
  text: string;
  inverted?: boolean;
  rotate?: boolean;
  displace?: boolean;
}

const PersonaText: React.FC<Props> = (
  {
    text,
    inverted = false,
    rotate = false,
    displace = false,
  }
) => {
  const config = {invert: inverted, rotate, displace};
  const template = usePersonaTextStyle( text );

  const textMap = useMemo( () =>
      template.map( (word, i) => (
        <Word
          key={ i }
          word={ word }
          config={config}
        />
      ) )
    , [config, template] );

  return <> {textMap} </>
};

export default PersonaText
