import { Hooks } from "scene/stories/it_cellar/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";

const { choices, backToBente } = create;

export const technics: (FrameProps | string)[] = [
  'Ok?!',
  'Bist du Entwickler oder so?',
  'Also die Seite ist in React umgesetzt. Mit React Router und Redux.',
  'Für Animationen habe ich gsap benutzt.',
  'Alles Andere war Handarbeit.',

  choices(Hooks.MUCH_WORK, [
    'Wow, das war bestimmt ne Menge Arbeit',
    'BEEINDRUCKEND!'
  ]),

  'Danke.',
  backToBente('Ich hol jetzt mal wieder Bente')
];
