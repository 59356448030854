import { createMultipleChoices } from "../../../../../create/createMultipleChoices";
import { next } from "../../../../../create/next";
import { stop_it_damn } from "./stop_it_damn";
import { is_this_a_joke } from "./is_this_a_joke";
import { Hooks } from "../../../../Hooks";

export const art_is_no_work = [
  '„BenteBot, rufe Schwiegermama an“ Befehl wird ausgeführt.',
  createMultipleChoices(
    [Hooks.STOP_IT_DAMN, 'HALT NEIN!'],
    [Hooks.IS_JOKE_ENTRY, 'Das war nur ein Spaß.'],
  ),
  ...next(Hooks.STOP_IT_DAMN, stop_it_damn),
  ...next(Hooks.IS_JOKE_ENTRY, is_this_a_joke),
];