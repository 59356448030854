import TimelineMax from 'assets/utilz/timeline';
import {Colors} from "../../../constants/colors";
import {config} from "../../../config";
import {RefProps} from "../types";

const displaceChatY = '100%';

type Ref = HTMLElement;
type OnComplete = () => void;

interface AnimationProps extends RefProps {
  onComplete?: OnComplete;
}

export const animateMobile = (props: RefProps, delay: number) => ({
  in: (onComplete?: OnComplete) => animateIn(props, delay, onComplete),
  out: (onComplete?: OnComplete) => animateOut(props, delay, onComplete)
});

const animateIn = (
  props: RefProps,
  delay: number,
  onComplete?: OnComplete
) => {
  const timeline = timelineIn( {...props, onComplete} );
  if ( config.skip_mobile_in ) {
    timeline.play().duration(.1)
  } else {
    timeline.play().delay(delay)
  }
};

const animateOut = (
  props: RefProps,
  delay: number,
  onComplete?: OnComplete
) => {
  const timeline = timelineOut( {...props, onComplete} );
  if ( config.skip_mobile_out ) {
    timeline.play().duration(.1)
  } else {
    timeline.play()
  }
};

const timelineIn = (
  {
    mobileRef,
    screenRef,
    screenBgRef,
    loadingRef,
    onComplete
  } : AnimationProps
) => {
  const tl = new TimelineMax( { paused: true, onComplete } );
  return tl
    .add( mobileIn( mobileRef ), 0 )
    .add( backgroundIn( screenBgRef ), 1 )
    .add( loadingIn( loadingRef ).delay( .1 ), 1 )
    .add( contentIn( screenRef ).delay( .4 ), 1 )
};

const mobileIn = (ref: Ref) =>
  new TimelineMax()
    .fromTo( ref, {
        y: displaceChatY,
        scaleX: .5
      },
      {
        ease: 'back.out(1.7)',
        duration: .5,
        scaleX: 1,
        y: 0
      }
    );

const loadingIn = (ref: Ref) =>
  new TimelineMax()
    .fromTo( ref, {
        opacity: 1,
        scale: 1,
      },
      {
        duration: .4,
        opacity: 0,
        ease: 'back.in(2.1)',
        scale: .5,

      }
    );

const backgroundIn = (ref: Ref) =>
  new TimelineMax()
    .fromTo( ref, {
        backgroundColor: 'black'
      },
      {
        duration: .6,
        backgroundColor: Colors.PINK
      }
    );

const contentIn = (ref: Ref) =>
  new TimelineMax()
    .fromTo( ref, {
        scale: .8,
        opacity: 0
      }, {
        ease: 'back.out(1.7)',
        duration: .5,
        scale: 1,
        opacity: 1
      }
    );

const timelineOut = (
  {
    mobileRef,
    screenRef,
    screenBgRef,
    onComplete
  } : AnimationProps
) => {
  const tl = new TimelineMax( { paused: true, onComplete } );
  return tl
    .add( backgroundIn( screenBgRef ).reverse( 1 ).duration( .2 ), 0 )
    .add( contentIn( screenRef ).reverse( 1 ).duration( .2 ), 0 )
    .add( mobileIn( mobileRef ).reverse( 1 ), '+ 1' )
};
