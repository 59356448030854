import React from 'react';
import styled from 'styled-components';
import arrow from '../../assets/img/portfolio/arrow.png';

const offset = '-80px';

interface Props {
  onClick(): void;
  left?: boolean;
}

const Button = styled.button<{left?: boolean}>`
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: ${props => !props.left ? 'auto' : offset};
  right: ${props => props.left ? 'auto' : offset};
  background: transparent;
  border: none;
  outline: none;
  z-index: 200;
  img {
    transform: ${ props => props.left ? 'scaleX(-1)' : 'none' };
    height: 40px;
  }
`;

const Arrow: React.FC<Props> = ({onClick, left}) => (
  <Button onClick={onClick} left={left}>
    <img src={arrow} alt="" />
  </Button>
);

export default Arrow
