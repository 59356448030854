import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTimeout } from "../../assets/useTimeout";

interface Props {
  endEvent(): void;
}

const Flashing = styled.div<{ dim: boolean }>`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: white;
  opacity: ${ props => props.dim ? 0 : 1 };
  transition: opacity .9s ease-in;
  z-index: 1000;
`;


const Lightning: React.FC<Props> = ({ endEvent }) => {
  const [ dim, setDim ] = useState( false );
  useTimeout( endEvent, 1 );

  useEffect( () => {
    if ( !dim ) {
      setDim( true )
    }
  }, [dim] );

  return (
    <Flashing dim={ dim } />
  );
};

export default Lightning
