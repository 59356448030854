import { useCallback } from "react";
import { createTarget } from "./utilz/createTarget";
import { useSceneGetter } from "./useSceneGetter";

export const useTarget = () => {
  const {currentFrame, nextFrame} = useSceneGetter();

  return useCallback((target?: string) => createTarget(
      currentFrame,
      nextFrame,
      target
    ),
    [currentFrame, nextFrame]);
};
