import { useState } from "react";

type Return  = [
  boolean,
  () => void
]

export const useToggle = (initiallyVisible?: boolean): Return => {
  const [visible, setVisible] = useState(initiallyVisible || false);
  const toggleVisibility = () => {
    setVisible(!visible)
  };

  return [
    visible,
    toggleVisibility
  ]
};
