import { useState } from "react";

interface ReturnMethods {
  enter(): void;
  leave(): void;
}

type Return = [boolean, ReturnMethods]

export const useHover = (): Return => {
  const [ isHovered, setHovered ] = useState( false );

  const onMouse = {
    enter: () => setHovered( true ),
    leave: () => setHovered( false ),
  };

  return [ isHovered, onMouse ];
};
