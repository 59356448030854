import { createSceneForValidation } from "./createSceneForValidation";
import { hasBeenPlayed } from "./hasBeenPlayed";
import { PossibleSceneType, SessionState } from "./types";

export const validateScene = (
  scene: PossibleSceneType,
  session: SessionState
): Boolean => {
  const readyScene = createSceneForValidation( scene );
  const sufficient_visits = session.times_visited >= readyScene.times_visited;
  const is_validated = readyScene.validate( session );
  const has_been_played = hasBeenPlayed(readyScene, session);

  return sufficient_visits && is_validated && !has_been_played;
};

