import React from 'react';
import { SessionProvider } from "./context/session.context";
import { PortfolioProvider } from "./context/portfolio.context";
import styled from "styled-components";
import AppLoader from "./AppLoader";
import 'setupGsap';

const Body = styled.div`
  text-align: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  * {
    box-sizing: border-box;
  }
`;

function App() {

  return (
      <SessionProvider>
        <PortfolioProvider>
          <Body>
            <AppLoader />
          </Body>
        </PortfolioProvider>
      </SessionProvider>

  );
}

export default App;
