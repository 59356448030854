import { Hooks } from "../about/Hooks";
import { SceneEvent } from "../../types";

export const restart = (text: string) => ({
  target: Hooks.START,
  text
});

export const restartLightning = (text: string) => ({
  target: Hooks.START,
  text,
  event: SceneEvent.LIGHTNING
});
