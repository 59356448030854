import React, { useEffect } from 'react';
import { useScene } from "../../index";
import DomMessage from "./DomMessage";
import DomAnswer from "../DomAnswer";
import { useDomNodes } from "../../../assets/useDomNodes";
import { usePrevious } from "../../../assets/usePrevious";
import { animateMessage } from "../animation/animateMessage";
import { MessageDom, Status } from "../store/types";
import { ChatImage } from "../../../constants/chatImages";
import { useChat } from "../store/useChat";

interface Props {
  text?: string;
  is_answer?: boolean;
  image: string;
}

const Message: React.FC<Props> = (
  {
    image,
    text,
    is_answer = false,
  }
) => {
  const { getCurrentImage } = useScene();
  const { chat_face, chat_background } = getCurrentImage( image as ChatImage );
  const [ refs, setRefs ] = useDomNodes();
  const wasReady = usePrevious( refs.is_ready );
  const {status} = useChat();
  const isStateReady = status === Status.READY;
  // const isStateReady = useSelector( (state: Store) => state.chat.status === Status.READY );

  useEffect( () => {
    if ( refs.is_ready && !wasReady ) {
      // @ts-ignore
      animateMessage(refs.domNodes as MessageDom).in()
    }
  }, [ refs, wasReady ] );

  return is_answer
   ? <DomAnswer setRefs={setRefs} />
   : <DomMessage
        is_answer={ false }
        chat_face={ chat_face }
        chat_background={ chat_background }
        text={ text || '' }
        setRefs={ setRefs }
        show={isStateReady}
      />
};

export default Message
