import React from 'react';
import styled from 'styled-components';
import close from '../../assets/img/portfolio/X.png';


interface Props {
  onClick(): void;
}

const Button = styled.button`
  position: absolute;
  cursor: pointer;
  top: 0;
  right: -80px;
  background: transparent;
  border: none;
  outline: none;
  img {
    height: 40px;
  }
`;

const Close: React.FC<Props> = ({onClick}) => (
  <Button onClick={onClick}>
    <img src={close} alt="" />
  </Button>
);

export default Close
