export type Quote = {
  quote: string,
  author: string,
}

export const quoteList: Quote[] = [
  {
    quote: "Ich lege mich fest: Bente und ihre kleine Marlene sind das absolut lustigste Mutter-Tochtergespann seit den Gilmore Girls",
    author: "Flix"
  },
  {
    quote: "Bentes Cartoons bringen mich zum Lachen und machen mich ehrfürchtig. Sie spielt inhaltlich und zeichnerisch in einer ganz eigene Liga",
    author: "@kriegundfreitag"
  },
  {
    quote: "Nachdem ich endlich damit fertig war, vor Lachen das gesamte Haus zusammenzuschreien, habe ich einen Stapel Bücher bestellt und an Menschen in meinem Umfeld verschenkt, egal, ob Eltern oder nicht. Das sollten Sie unbedingt auch tun!",
    author: "Jasmin Schreiber"
  },
  {
    quote: "Besser als die BUBEL",
    author: "Gronkh"
  }
];
