import React, { useMemo } from 'react';
import { TemplateInterface } from "./Types";
import { getShapeProps } from "./utilz/getShapeProps";
import Shape from "./Shape";

const Template: React.FC<TemplateInterface> = (
  {
    type,
    children,
    ...props
  }
) => {

  const { form, text_position, text_color } = useMemo(() =>
      getShapeProps( type )
    , [type]);

  return (
    <Shape
      { ...props }
      form={ form }
      textPosition={ text_position }
      textColor={ text_color }
    >
      { children }
    </Shape>
  );
};

export default Template
