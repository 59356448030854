import { createMultipleChoices } from "../../../../../create/createMultipleChoices";
import { Hooks } from "../../../../Hooks";
import { next } from "../../../../../create/next";
import { fetish } from "./fetish";

export const mother_in_law = [
  'Rufe Schwiegermutter Handy an. Habe ich das richtig verstanden?',
  createMultipleChoices(
    [Hooks.ABORT_DONE, 'ABBRUCH! FERTIG!'],
    [Hooks.OH_YES_BEAUTIFUL, 'Oh, wie schön! Ja!'],
  ),
  ...next(Hooks.OH_YES_BEAUTIFUL, [
    'tut tut',
    'tut tut',
    'tut tut',
    'tut tut',
    'Es tut mir leid, deine Schwiegermutter hebt nicht ab weil sie dich hasst.',
  ],
    true),
    ...next(Hooks.ABORT_DONE, fetish)
];
