import { validateSceneList } from "./validateSceneList";
import { validateScene } from "./validateScene";
import { OptionalScene, PossibleSceneType, SessionState } from "./types";
import { SceneProps } from "../../scene/types";


export const validateOptionalScenes = (
  scenes: PossibleSceneType[] | PossibleSceneType[][],
  session: SessionState
) => {
  let result: SceneProps[] = [];
  scenes.forEach((optional: PossibleSceneType | PossibleSceneType[]) => {
    const scene = validate(optional, session);
    if (scene) {
      result.push(scene)
    }
  });

  return result
};

const validate = (
  optional: PossibleSceneType | PossibleSceneType[],
  session: SessionState
): SceneProps | null => {
  if(isSceneArray(optional)) {
    const scene = validateSceneList(optional, session);
    if (scene) {
      return scene
    }
  } else {
    const is_valid = validateScene( optional, session );
    if ( is_valid ) {
      return addSingleScene( optional )
    }
  }

  return null;
};

const addSingleScene = (
  optional: PossibleSceneType
): SceneProps => {
  if (isOptionalScene(optional)) {
    return optional.scene
  }
  return optional
};

const isOptionalScene = (optional: PossibleSceneType): optional is OptionalScene =>
  !!(optional as OptionalScene).scene;

const isSceneArray = (optional: PossibleSceneType | PossibleSceneType[]): optional is PossibleSceneType[] =>
  Array.isArray(optional);
