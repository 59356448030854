import { useMemo } from "react";
import { Frame, SceneList } from "../types";
import { getNextScene } from "../store/actions/helper/getNextScene";
import { getFrameIndex } from "../store/actions/helper/getFrameIndex";
import { useSceneContext } from "../../context";
import { useSession } from "../../session";

export const useSceneGetter = () => {
  const sequence = useSceneContext();
  const {
    scenes,
    current,
    frameIndex
  } = sequence

  const session = useSession();

  const currentScene = useMemo( () =>
    scenes.filter( scene => scene.id === current )[0] || {}
    , [ current, scenes ] );

  // Sometimes the whole event is given. Then it should be handled like undefined
  // This is the complex one

  const next = useMemo( () => {
    const { played, current } = sequence;
    const next = {
      ...sequence,
      played: played.concat( current ),
    };
    return getNextScene( next, session );

  }, [ sequence, session ] );

  const nextScene = useMemo( () => {
    return getById( next, scenes )
  }, [ next, scenes ] );

  const currentFrame = useMemo( () => {
      if ( currentScene && currentScene.frames ) {
        return currentScene.frames[frameIndex]
      }
      return {} as Frame
    }
    , [ currentScene, frameIndex ] );

  const nextFrame = useMemo( () => {
      if ( currentScene && currentScene.frames && currentScene.frames[frameIndex + 1] ) {
        return currentScene.frames[frameIndex + 1]
      }
      return {} as Frame
    }
    , [ currentScene, frameIndex ] );

  const getNextFrame = (target?: string): Frame => {
    const index = target && getFrameIndex( currentScene, target );
    if ( index && index !== -1 ) {
      return currentScene.frames[index]
    }
    return nextFrame;
  };

  return {
    currentScene,
    nextScene,
    currentFrame,
    nextFrame,
    next,
    getNextFrame
  }
};

const getById = (id: string, scenes: SceneList) => scenes
  .filter( (el) => el.id === id )[0];
