import React, { useMemo, Fragment } from "react";
import { Polygon } from "./Polygon";
import { PolygonDom, ShapeForm, WiggleOptionProps } from "../Types";
import { convertShapes } from "./utilz/convertShapes";

interface Props {
  form: ShapeForm;
  reverse?: boolean;
  wiggle?: boolean;
  wiggleOptions?: WiggleOptionProps;
}

const getColor = (
  shapeList: PolygonDom[],
  index: number
) => {
  const length = shapeList.length - 1;
  const isLast = index >= length;
  const i = !isLast ? index + 1 : 0;
  return shapeList[i]
};

const PolygonMap: React.FC<Props> = (
  {
    form,
    reverse,
    wiggle,
    wiggleOptions
  }
) => {
  const shapeList = convertShapes( form.children, form.state );

  const components = useMemo(() => {

    const fill = (index: number, fill: string) =>
      reverse
        ? getColor( shapeList, index ).fill
        : fill;

    const stroke = (index: number, stroke: string) =>
      reverse
        ? getColor( shapeList, index ).stroke
        : stroke;

    return (
        shapeList.map( (props, i) => (
          <Polygon
            { ...props }
            key={ i }
            fill={ fill( i, props.fill ) }
            stroke={ stroke( i, props.stroke ) }
            wiggle={ wiggle }
            wiggleOptions={wiggleOptions}
          />
        ) )
      )
    }, [reverse, shapeList, wiggle, wiggleOptions]
  );

  return (
    <Fragment>
      {components}
    </Fragment>
  )
};

export default PolygonMap
