import React from 'react';
import styled from 'styled-components';
import Button from "../../components/Button";
import { useNavigation } from "../../assets/useNavigation";
import { Pages } from "../../constants/Pages";

const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
`;

const Back = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
`;

const ItCellar = () => {
  const navigate = useNavigation();
  const back = () => navigate(Pages.ABOUT);

  return (
    <Container>
      <Back>
        <Button value='Zurück zum Chat' onClick={back} />
      </Back>
    </Container>
  );
};

export default ItCellar
