import React, { useMemo, useRef } from 'react';
import styled from 'styled-components';
import Choice from "./Choice";
import { useScene } from "../../index";
import { AnswerProps } from "./Answer";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const DomChoices: React.FC<AnswerProps> = ({ onClick }) => {
  const choicesRef = useRef( null );
  const {getCurrentFrame} = useScene();
  const choices = getCurrentFrame().choices || [];

  const answer = useMemo(() =>
      choices.map( ({ target, text }, i) => (
        <Choice
          key={ i }
          onClick={ onClick }
          target={target}
          text={text}
        >
          <p>{ text }</p>
        </Choice>
      ) )
  , [choices, onClick]);

  return (
    <Container ref={ choicesRef }>
      { answer }
    </Container>
  );
};

export default DomChoices
