import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";

const { next, choices, norbertEnd, placeholder } = create;

export const stalking: (FrameProps | string)[] = [
  'In Berlin, aber wehe du stalkst sie. Dann komm ich bei dir vorbei! D:',

  choices(Hooks.BENTON_STALKING, [
    'Was macht sie denn so in Berlin außer Comics malen?',
    'MACH ICH SCHON LANGE!'
  ]),

  'DU BIST DAS ALSO?! Ich ruf jetzt die Keks-Polizei!!!',
  'HILFEEEE',
  'Bente- ääh BENTON hat den Chat verlassen',
  ...norbertEnd(),

  ...next(Hooks.BENTON_STALKING, [
    placeholder('Zum Beispiel Live Graphic Novels am Berliner Ensemble. Voll nervig.')
  ])
];
