import { State } from "../../types";
import { State as SessionState } from "../../../../../session/store/types";
import { getNextInOrder } from "./getNextInOrder";
import { getUnPlayed } from "./getUnPlayed";
import { filterValid } from "./filterValid";
import { addUnique } from "../addUnique";
import { getRepeatingScenes } from "./getRepeatingScenes";


export const getNextScene = (sequence: State, session: SessionState) => {
  const unplayed = getUnPlayed( sequence );
  const repeating = getRepeatingScenes( sequence.scenes );
  const scenes = addUnique(unplayed, repeating);
  const valid = filterValid( sequence, scenes, session );
  if ( !valid.length ) return '';

  return getNextInOrder( valid )
};

