import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";
import { are_you_mad } from "./are_you_mad";
import { human_born_in_bad_kreuznach } from "./human_born_in_bad_kreuznach";

const { next, choices } = create;

export const what_do_you_want_to_know: (FrameProps | string)[] = [
  'Oh, nagut. Bisschen komisch, aber was willst du wissen?',

  choices(Hooks.WHAT_DO_YOU_WANT_TO_KNOW, [
    'Wie alt bist du eigentlich?',
    'Was hast du gerade an?'
  ]),

  ...are_you_mad,

  ...next(Hooks.WHAT_DO_YOU_WANT_TO_KNOW, human_born_in_bad_kreuznach)
];
