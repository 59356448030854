import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";
import { pets } from "./pets";

const { next, choicesIrrelevant, choices, end } = create;

export const hobbies: (FrameProps | string)[] = [
  'Zocken, zeichnen, Geige spielen. Weiter.',

  choicesIrrelevant( '', [
    'Lieblingsfarbe?',
    'Augenfarbe?'
  ]),

  'Grün! WEITER!',

  choices(Hooks.SINAN_KNOW_MORE, [
    'Haustier?',
    'Unterwäsche?'
  ]),

  'EY! JETZT REICHTS! ICH SCHMEIß DICH RAUS!!',
  end('Sinan hat dich geblockt.'),

  ...next(Hooks.SINAN_KNOW_MORE, pets)
];
