import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";

const { next, choices, end } = create;

export const tunnel: (FrameProps | string)[] = [
  "Ähm. Ne. Ich war kurz in 'nem Tunnel. Der Empfang...",

  choices(Hooks.TUNNEL, [
    'Was für ein Tunnel?',
    'Immer diese spontanen Tunnel.'
  ]),

  'Ja, immer wenn ich gerade ein absolut tolles und NULL unangenehmes Gespräch führen will..',
  'Also... ich muss dann mal los. Du weißt schon, der Herd ist noch an. Also mach’s gut!',
  end(),

  ...next(Hooks.TUNNEL, [
    'Wa...ich hab di.... anden... llo?',
    '*Klick tut tut tut*',
    end()
  ])
];
