import { createChoices } from "../../../../create/createChoices";
import { Hooks } from "../../../Hooks";
import { next } from "../../../../create/next";
import { etsy_shop } from "./etsy_shop/etsy_shop";
import { games_and_violin } from "./games_and_violin/games_and_violin";

export const speak_beautiful = [
  'Du ebenfalls.',
  'Außerdem sie entwickelte 2014 die Live Graphic Novel welche sie aufführt an Theatern wie dem Berliner Ensemble.',
  'Und ihren erstes Buch „Herzschlag ins Gesicht“ erschien 2021.',
  createChoices(
    Hooks.LIVE_GRAPHIC_NOVEL,
    ['Und was sind ihre Hobbys?', 'Ok, reicht jetzt auch.']
  ),
  ...next('', etsy_shop),
  ...next(Hooks.LIVE_GRAPHIC_NOVEL, games_and_violin)
];