import React from 'react';
import styled from "styled-components";
import PersonaText from "../PersonaText";

interface Props {
  text: string
}

const Container = styled.div`
  display: flex;
`;

const HeadlineText: React.FC<Props> = ({ text }) => {

  return (
    <Container>
      <PersonaText
        text={text}
        rotate
        displace
      />
    </Container>
  )
};

export default HeadlineText
