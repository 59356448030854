import React, { useRef } from 'react';
import {
  AppsContainer,
  Container,
  Content,
  Frame,
  HomeButton,
  IPadBackground,
  Tablet
} from "./PortfolioStyles";
import iPad_image from "../assets/img/portfolio/iPad.svg";
import ImagePreview from "./ImagePreview";
import { SetDomNodes } from "../assets/useDomNodes";
import { useComponentMount } from "../assets/useComponentMount";

interface Props {

  onClickHome(): void;

  onClickImage?(index: number): void;

  onLoad(): void;

  setDomNodes: SetDomNodes;
  category: string;
}

const PortfolioDom: React.FC<Props> = (
  {
    category,
    onClickHome,
    onClickImage,
    setDomNodes,
    onLoad,
    children
  }
) => {
  const appsRef = useRef( null );
  const previewRef = useRef( null );
  const setDom = () => setDomNodes( { appsRef, previewRef } );
  useComponentMount( setDom );

  return (
    <Container>
      <Tablet src={ iPad_image } onLoad={ onLoad } />
      <Content>
        <HomeButton onClick={ onClickHome } />
        <Frame>
          <IPadBackground />
          <AppsContainer ref={ appsRef }>
            {children}
          </AppsContainer>
          <ImagePreview
            forwardRef={ previewRef }
            visible={ !!category.length }
            onClick={ onClickImage }
          />
        </Frame>
      </Content>
    </Container>
  );
};

export default PortfolioDom
