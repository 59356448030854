import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";

const { next, placeholder } = create;

export const games_art_and_animation: (FrameProps | string)[] = [
  ...next(Hooks.GAMES_ART_AND_ANIMATION, [
    placeholder('Ich habe Games Art&Animation an der Games Academy in Berlin studiert.')
  ])
];
