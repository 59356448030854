import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Shape, ShapeConstructor } from "../../shapes";

type Props = {
  index: number;
}

const ShapeContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const customShape = () => {
  return new ShapeConstructor()
    .rectangle({fill: 'white'})
    .randomize( { x: 20, y: 10 } )
    .add()
    .grow( { fill: 'black', x: 3, y: 3 } )
    .randomize( { x: 3, y: 3 } )
    .add();
};

const Frame:React.FC<Props> = (
  {
    index,
    children
  }
  ) => {
  const shape = useMemo(customShape, [index]);

  return(
    <ShapeContainer>
      <Shape
        form={ shape }
        textColor='black'
        wiggleOptions={{x: 40, y: 10}}
      >
      </Shape>
      {children}
    </ShapeContainer>
  );
}

export default Frame
