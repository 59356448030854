import { Hooks } from "scene/stories/about/Hooks";
import create from "scene/stories/create";
import { FrameProps } from "scene/types";
import { book_sinan } from "./book_sinan";

const { next, choices, end } = create;

export const overhead: (FrameProps | string)[] = [
  'Da zeichnet Bente live während des Stückes an einem Overhead Projektor das...',
  'Bühnenbild, das in den Raum projiziert wird. Ist ziemlich geil!',

  choices(Hooks.SINAN_OVERHEAD, [
    'Klingt spannend, danke.',
    'Klingt SUPER spannend, danke.'
  ]),

  ...book_sinan,

  ...next(Hooks.SINAN_OVERHEAD, [
    'Ja kein Ding. Das war’s erst mal. Hau rein!',
    end('Sinan hat den Chat verlassen.')
  ])
];
