import { Pages } from "../constants/Pages";

export const links = [
  { name: 'Home', to: Pages.HOME },
  { name: 'Portfolio', to: Pages.PORTFOLIO },
  { name: 'Bentsy.shop', to: 'https://bentsy.shop/' },
  // { name: 'Bentsy.shop', to: 'https://www.etsy.com/de/shop/Bentelicious' },
  { name: 'Über mich', to: Pages.ABOUT },
  { name: 'Kontakt', to: Pages.CONTACT },
  { name: 'Impressum', to: Pages.IMPRINT },
];
