import TimelineMax from 'assets/utilz/timeline';

export const animation = (
  feedback: HTMLDivElement,
  onComplete: () => void
) => new TimelineMax({paused: true, onComplete})
  .to(feedback, {
    duration: 1,
    ease: "back.in(1.7)",
    y: '100%'
  });
