import TimelineMax from 'assets/utilz/timeline';
import { AnswerAnimationProps } from "./animateAnswer";

type Ref = HTMLElement;

export const answerOut = (
  {
    answerRef,
    flashRef,
    userRef,
    backgroundRef,
    backdropRef,
    containerRef,
  }: AnswerAnimationProps,
  onComplete: () => void
) =>  new TimelineMax( { paused: true, onComplete } )
    .add( flashOut( flashRef ), 0 )
    .add( backgroundOut( backgroundRef ), 0 )
    .add( backdropOut( backdropRef ), 0 )
    .add( characterOut( userRef ), 0 )
    .add( choicesOut( answerRef ), 0 )
    .set( containerRef, {display: 'none'}, 1);

const characterOut = (characterRef: Ref,) => {
  return new TimelineMax()
    .to( characterRef, {
      duration: .4,
      x: '100%',
      ease: "back.in(1.7)",
    } )
};
const backdropOut = (characterRef: Ref,) => {
  return new TimelineMax()
    .to( characterRef,  {
      duration: .3,
      ease: 'power4.out',
      x: '100%',
    } )
};

const flashOut = (flashRef: Ref) => {
  return new TimelineMax()
    .to( flashRef, {
      x: '100%',
      duration: .4,
      ease: "back.in(4)"
    } )
};
const backgroundOut = (backgroundRef: Ref) => {

  return new TimelineMax()
    .to( backgroundRef, {
      x: '100%',
      duration: .4,
      ease: "back.in(2)"
    } )
};
const choicesOut = (answerRef: Ref) => {

  return new TimelineMax()
    .to( answerRef.childNodes, {
      duration: .5,
      x: 1400,
      stagger: .3,
      ease: "back.in(1.7)"
    } )
};