import { chat_answer, chat_bubble, arrow, rectangle } from "../templates/shapes";
import textPosition from "./textPosition";
import { CreateShapeProps, ReturnShapeProps, ShapeType } from "../Types";

export const getShapeProps = (type: ShapeType): ReturnShapeProps => {
  const shape = shapes()[type];
  return merge(shape())
};

const shapes = () => ({
  chat_answer: chatAnswerProps,
  chat_bubble: chatBubbleProps,
  arrow: arrowProps,
  rectangle: rectangleProps,
});

const chatAnswerProps = () => ({
  form: chat_answer(),
  text_position: textPosition.chat_answer,
  text_color: 'black',
});

const chatBubbleProps = () => ({
  form: chat_bubble(),
  text_position: textPosition.chat_bubble,
  text_color: 'black',
});

const arrowProps = () => ({
  form: arrow(),
  text_position: textPosition.arrow,
});

const rectangleProps = () => ({
  form: rectangle(),
});

const merge = (props: CreateShapeProps): ReturnShapeProps => ({
  text_position: {},
  text_color: 'white',
  ...props,
});
