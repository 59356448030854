import { SceneProps } from "../types";
import { DialogImage } from "../../constants/dialogImages";

export const greeting: SceneProps = {
  id: 'greeting',
  order: 10,
  frames: [
    {
      "text": "He na, du hier.",
    },
    {
      "image": DialogImage.BENTE_SMIRK,
      "text": "Was suchst du hier?"
    },
    {
      "type": "ANSWER",
      "choices": [
        {
          "target": "good",
          "text": "Die besten Bilder der Welt"
        },
        {
          "target": "bad",
          "text": "Nur mal durchgucken."
        }
      ]
    },
    {
      "id": "good",
      "target": "break",
      "image": DialogImage.BENTE_HAPPY,
      "type": "TEXT",
      "text": "DANN bist du hier genau richtig! Klick mal oben auf das Burger-Menü ;o)"
    },
    {
      "id": "bad",
      "text": "CHATBOT ACTIVATE"
    },
    // {
    //   "Text": "Wenn du zu den Nacktaktiven Fledermäusen willst, sag bitte laut  NACKTAKTIVE FLEDERMÄUSE"
    // },
    // {
    //   "Text": "Wenn du mein Portfolio sehen willst, dann drücke drei mal Shift."
    // },
    // {
    //   "Text": "Wenn du mir eine Nachricht schreiben willst, dann geh zu Googlemail, wie jeder vernünftige Boomer."
    // },
    {
      "type": "ANSWER",
      "choices": [ "Nacktaktive Fledermäuse", "Portfolio", "Kontaktformular A38" ]
    },
    {
      "text": "Ok, rufe \"Mama Festnetz\" an. Ist das richtig?"
    },
    {
      "type": "ANSWER",
      "choices": [ "Ich hasse Chatbots" ]
    },
    {
      "text": "Ich bin kein Chatbot! ICH BIN DER BENTE BOT!"
    },
    {
      "text": "Und jetzt drücke oben auf das Burgermenü und find dich selbst zurecht."
    }
  ]
};

export default greeting;