export type Message = {
  text: string;
  id: string;
  type: 'TEXT' | 'ANSWER' | 'CHOICE';
  image: string;
  target?: string;
  is_answer?: boolean;
}

export enum Status {
  READY = 'READY',
  AWAITING_ANSWER = 'AWAITING_ANSWER',
  ANSWER_GIVEN = 'ANSWER_GIVEN',
}

export interface MessageDom {
  avatarFace: HTMLElement,
  avatarBackground: HTMLElement,
  message: HTMLElement,
  messageBody: HTMLElement
};

export type State = {
  messages: Message[];
  status: Status;
  answer: string;
  target: string;
};
