import create from "../create";
import { SceneType } from "../../types";
import { DialogImage } from "../../../constants/dialogImages";
import { Hooks } from "./Hooks";
import { next } from "../create/next";
import { much_work } from "./much_work";
import { what_do_you_want_to_know } from "./what_do_you_want_to_know";
import { details } from "./details";

const { choices, backToBente } = create;

export const it_cellar = {
  id: 'Sinans IT cellar',
  order: 1,
  type: SceneType.DIALOG,
  repeat: true,
  image: DialogImage.SINAN,
  name: 'Sinan',
  frames: [
    {
      text: 'Hi',
    },
    {
      text: 'Was machst du denn hier?',
    },
    choices(Hooks.DETAILS, [
      'Ich will mehr über die Seite erfahren',
      'Falsch abgebogen'
    ]),

    backToBente('Alles klar, ich helf dir zurück'),
    ...next(Hooks.DETAILS, details),

    ...next(Hooks.WHAT_DO_YOU_WANT_TO_KNOW, what_do_you_want_to_know),
    ...next(Hooks.MUCH_WORK, much_work)
  ]
};
