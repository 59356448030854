import React, { useMemo } from 'react';
import { Shape, ShapeConstructor } from "../../shapes";

interface Props {
  text: string;
}

const HeadlineBackground: React.FC<Props> = ({text}) => {
  const shapeWidth = text.length * 10 + 40;
  const shapeProps = useMemo( () => ({
      width: shapeWidth,
      height: 30
    })
    , [ shapeWidth ] );

  const form = useMemo( () => new ShapeConstructor( shapeProps )
      .rectangle()
      .randomize( { x: 30, y: 10 } )
      .add()
      .grow( { fill: 'white', x: 3, y: 3 } )
      .randomize( { x: 3 } )
      .add()
    , [ shapeProps ] );

  return <Shape form={form} />
};

export default HeadlineBackground;
