import React from "react";

export const MobileDevice = () => (
  <svg
    id="Handy"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="100%"
    height="100%"
    viewBox="0 0 751 913"
    preserveAspectRatio="none"
    overflow="visible"
  >
    <path d="M718,25l-23-12L57,39L32,51L16,71l50,1040l10,21l580-9.5l30.5-28.5L738,66L718,25z M609.6,1034.2l-494.4,4.9l-13.8-881.5
	l561-30L609.6,1034.2z" />
    <path fill="white" d="M696.5,21.5l-631,42l-28,28l39.8,1010.4l20.7,17.6l546-11.2l20.3-60.9l62.2-955L696.5,21.5z M638.7,1053
	l-535.4-1L76.5,148.5l15-12l589-30L638.7,1053z" />
    <path d="M324,113l8,6c0,0,59,1,62-3s-3-13-11-13s-52,0-52,0L324,113z" />
    <path d="M355,98c-3.4-2.3,2-11,7-11s8,1,9,2s-2,10-5,11S358,100,355,98z" />
    <path d="M370,1065c14.1,0,22,12,20,20s-13,18-24,18s-24-12-22-22S357,1065,370,1065z" />
</svg>
);

export default MobileDevice;