import { SceneProps } from "../../scene/types";
import { OptionalScene, PossibleSceneType, ValidatableScene } from "./types";

export const createSceneForValidation = (
  optional: PossibleSceneType
): ValidatableScene => {
  if (isScene(optional)) {
    return createScene({scene: optional})
  }

  return createScene(optional)
};

export const createScene = (
  optional: OptionalScene
): ValidatableScene => ({
  // ...optional,
  scene: optional.scene,
  times_visited: optional.times_visited ? optional.times_visited : 0,
  validate: optional.validate ? optional.validate : () => true
});

const isScene = (scene: SceneProps | PossibleSceneType): scene is SceneProps =>
  !!(scene as SceneProps).frames;
